import React from 'react';
import { connect } from 'react-redux';
import { Select } from 'antd';

//action imports

//variable declaration
const { Option } = Select;

class SelectPost extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            post: this.props.post,
            posts: this.props.posts,
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps !== this.props){
            this.setState({
                post: nextProps.post,
                posts: nextProps.posts,
            })
        }
    }

    render() {

        const { post, posts } = this.state;

        if (posts.length !== 0) {
            return (
                <div>
                    <div>
                        <p style={{ color: "#000", fontFamily: "ramblaBold", textAlign: "left"}}>
                            { this.props.label }
                        </p>
                    </div>
                    <Select
                        labelInValue
                        value={post}
                        showSearch
                        style={{ width: 295 }}
                        placeholder={this.props.placeholder}
                        optionFilterProp="children"
                        filterOption={false}
                        onChange={this.props.onChange}
                    >
                        {
                            posts.map(jobPost => {
                                return <Option key={jobPost.postID} value={JSON.stringify(jobPost)}>{jobPost.postTitle}</Option>
                            })
                        }
                    </Select>
                </div>
            );
        } else {
            return <p style={{ fontFamily: "ramblaBold", margin: "2rem" }}>There are no job posts yet</p>
        }
    }
}

const mapStateToProps = state => {
    const { post, posts } = state.posts;

    return {
        post,
        posts
    }
};


export default connect(mapStateToProps, { })(SelectPost);
