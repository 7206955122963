import React, { useState } from "react";
import { Modal, Typography } from "antd";

const { Text } = Typography;

const TruncatedText = ({ text, maxLength }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleToggleModal = () => {
        setIsModalVisible(!isModalVisible);
    };

    const renderText = () => {
        if (text.length <= maxLength) {
            return <Text style={{ textAlign: "center", fontSize: '0.9rem', textOverflow: 'ellipsis', color: "#000", fontFamily: 'ramblaRegular' }}>{text}</Text>;
        }

        return (
            <Text onClick={handleToggleModal} style={{ cursor: "pointer",  fontSize: '0.9rem', textOverflow: 'ellipsis', textAlign: "center", color: "#0793d9", fontFamily: 'ramblaRegular' }}>
                {text.substring(0, maxLength)}...
            </Text>
        );
    };

    return (
        <div>
            {renderText()}
            <Modal
                title="Industries"
                open={isModalVisible}
                onOk={handleToggleModal}
                onCancel={handleToggleModal}
            >
                <p style={{ textAlign: "center", color: "#000", fontFamily: "ramblaRegular" }}>{text}</p>
            </Modal>
        </div>
    );
};

export default TruncatedText;