import React from 'react';
import { connect } from 'react-redux';
import {message} from "antd";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import 'antd/dist/antd.css';
import _ from "lodash";

//-> action imports
import { credentialsChanged, updateProfile } from '../../../actions/AuthenticationActions';

//-> component imports
import {Input} from '../../common/Input';
import {Button} from '../../common/Button';

//-> file import
import { BarLoader } from 'react-spinners';
import CountryRegionPicker from "../../common/CountryRegionPicker";

class EditProfile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            firstName: this.props.firstName,
            lastName: this.props.lastName,
            phone: this.props.phone,
            companyName: this.props.companyName,
            companyPhone: this.props.companyPhone,
            companyEmail: this.props.companyEmail,
            logo: this.props.logo,
            logoChanged: this.props.logoChanged,
            country: this.props.country,
            region: this.props.region,
            address: this.props.address,
            postalCode: this.props.postalCode,

            signUpError: this.props.signUpError,
            loading: this.props.loading,
            isPasswordVisible: false,

            companyRegDocument: this.props.companyRegDocument,
            adminIDs: this.props.adminIDs
        };

        this.onUpdatePress = this.onUpdatePress.bind(this);
        this.renderSignUpError = this.renderSignUpError.bind(this);
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props) {
            this.setState({
                firstName: nextProps.firstName,
                lastName: nextProps.lastName,
                phone: nextProps.phone,
                companyName: nextProps.companyName,
                companyPhone: nextProps.companyPhone,
                companyEmail: nextProps.companyEmail,
                logo: nextProps.logo,
                logoChanged: nextProps.logoChanged,
                country: nextProps.country,
                region: nextProps.region,
                address: nextProps.address,
                postalCode: nextProps.postalCode,
                signUpError: nextProps.signUpError,
                loading: nextProps.loading,
                companyRegDocument: nextProps.companyRegDocument,
                adminIDs: nextProps.adminIDs
            });
        }


        if (nextProps.loading !== this.props.loading) {
            this.setState({  });
        }
    };

    onUpdatePress() {
        //-> handle login
        const { firstName, lastName, phone, companyName, companyPhone, companyEmail, logo, logoChanged, country, region, address, postalCode, companyRegDocument, adminIDs } = this.state;

        console.log({firstName, lastName, phone, companyName, companyPhone, companyEmail, logo, logoChanged, country, region, address, postalCode, companyRegDocument, adminIDs });

        //-> checking if fields are not empty
        if ( firstName && lastName && phone && companyName && !(_.isEmpty(country)) && !(_.isEmpty(region)) && address && companyPhone && companyEmail) {
            const companyLocation = {
                country: country.countryName,
                countryCode: country.countryShortCode,
                region: region.name,
                regionCode: region.shortCode,
                streetAddress: address,
                postalCode
            }

            //call login action from actions
            this.props.updateProfile({ firstName, lastName, phone, companyName, companyPhone, companyEmail, companyLocation, logo, logoChanged, adminIDs, companyRegDocument });
        } else {
            message.warning('Fill all required fields');
        }
    }

    renderSignUpError() {
        //extract state
        const { signUpError } = this.state;

        if (signUpError) {
            return (
                <div>
                    <p className={"adminAlertError"}><FontAwesomeIcon icon="exclamation-circle" size="1x" color="#EA3224" /> {signUpError}</p>
                </div>
            );
        }
    }

    keyPressed = (e) => {
        //it triggers by pressing the enter key
        if (e.key === 'Enter') {
            this.onUpdatePress();
        }
    };

    renderUpdateButton() {
        const {loading} = this.state;

        if(loading){
            return (
                <div>
                    <BarLoader
                        size={20}
                    />
                </div>
            );
        }else{
            return (
                <div className={"allLeaveRequestsButtonBox"}>
                    <Button
                        text={"Update"}
                        onPress={() => this.onUpdatePress()}
                    />
                </div>
            );
        }
    }

    async handleOrganizationLogo(event) {
        //files is an array, so pick first element
        const file = event.target.files[0];
        const maxSize = 2 * 1024 * 1024; // 2MB maximum size (in bytes)

        if (file && file.size > maxSize) {
            message.info('File size exceeds the maximum limit of 2MB.');
            // Optionally, you can reset the file input value
            event.target.value = null;
        } else {
            // File size is within the limit, you can proceed with the upload
            // ...
            console.log("entered here");
            console.log(file);
            const fileToBase64 = (file) => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = (error) => reject(error);
                });
            };
            const fileData = await fileToBase64(file);
            const { name, type, size } = file;

            this.props.credentialsChanged({ prop: 'logo', value: { name, type, size, data: fileData } });
            this.props.credentialsChanged({ prop: 'logoChanged', value: true });
        }
    }

    async handleCompanyDocument(event) {
        //files is an array, so pick first element
        const file = event.target.files[0];
        const maxSize = 2 * 1024 * 1024; // 2MB maximum size (in bytes)

        if (file && file.size > maxSize) {
            message.info('File size exceeds the maximum limit of 2MB.');
            // Optionally, you can reset the file input value
            event.target.value = null;
        } else {
            // File size is within the limit, you can proceed with the upload
            // ...
            console.log("entered here");
            console.log(file);
            const fileToBase64 = (file) => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = (error) => reject(error);
                });
            };
            const fileData = await fileToBase64(file);
            const { name, type, size } = file;

            this.props.credentialsChanged({ prop: 'companyRegDocument', value: { name, type, size, data: fileData } });
        }
    }

    async handleAdminID(event) {
        //files is an array, so pick first element
        const file = event.target.files[0];
        const maxSize = 2 * 1024 * 1024; // 2MB maximum size (in bytes)

        if (file && file.size > maxSize) {
            message.info('File size exceeds the maximum limit of 2MB.');
            // Optionally, you can reset the file input value
            event.target.value = null;
        } else {
            // File size is within the limit, you can proceed with the upload
            // ...
            console.log("entered here");
            console.log(file);
            const fileToBase64 = (file) => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = (error) => reject(error);
                });
            };
            const fileData = await fileToBase64(file);
            const { name, type, size } = file;

            this.props.credentialsChanged({ prop: 'adminIDs', value: { name, type, size, data: fileData } });
        }
    }
    

    render(){
        return (
            <div className={"editProfileContainer"}>
                <h2 style={{ fontFamily: "ramblaBold" }}>Edit Profile</h2>
                <div style={{ marginBottom: '10px' }}>
                    <input
                        type='text'
                        placeholder='Admin First Name'
                        onChange={e => this.props.credentialsChanged({ prop: 'firstName', value: e.target.value })}
                        value={this.state.firstName}
                        onKeyDown={this.keyPressed}
                        style={{textAlign: 'center', width: '763px', height: '40px', borderRadius: '4px', borderColor: '#cccccc'}}
                    />
                </div>
                <div style={{ marginBottom: '10px' }}>
                    <input
                        type='text'
                        placeholder='Admin Last Name'
                        onChange={e => this.props.credentialsChanged({ prop: 'lastName', value: e.target.value })}
                        value={this.state.lastName}
                        onKeyDown={this.keyPressed}
                        style={{textAlign: 'center', width: '763px', height: '40px', borderRadius: '4px', borderColor: '#cccccc'}}
                    />
                </div>
                <div style={{ marginBottom: '10px' }}>
                    <input
                        type={'text'}
                        placeholder={'Admin Phone'}
                        onChange={e => this.props.credentialsChanged({prop: 'phone', value: e.target.value}) }
                        value={this.state.phone}
                        onKeyDown={this.keyPressed}
                        style={{textAlign: 'center', width: '763px', height: '40px', borderRadius: '4px', borderColor: '#cccccc'}}
                    />
                </div>
                <div style={{ marginBottom: '10px' }}>
                    <input
                        type={'text'}
                        placeholder={'Company Name'}
                        onChange={e => this.props.credentialsChanged({prop: 'companyName', value: e.target.value}) }
                        value={this.state.companyName}
                        onKeyDown={this.keyPressed}
                        style={{textAlign: 'center', width: '763px', height: '40px', borderRadius: '4px', borderColor: '#cccccc'}}
                    />
                </div>
                <div style={{ marginBottom: '10px' }}>
                    <input
                        type={'text'}
                        placeholder={'Company Phone'}
                        onChange={e => this.props.credentialsChanged({prop: 'companyPhone', value: e.target.value}) }
                        value={this.state.companyPhone}
                        onKeyDown={this.keyPressed}
                        style={{textAlign: 'center', width: '763px', height: '40px', borderRadius: '4px', borderColor: '#cccccc'}}
                    />
                </div>
                <div style={{ marginBottom: '10px' }}>
                    <input
                        type={'text'}
                        placeholder={'Company Email'}
                        onChange={e => this.props.credentialsChanged({prop: 'companyEmail', value: e.target.value}) }
                        value={this.state.companyEmail}
                        onKeyDown={this.keyPressed}
                        style={{textAlign: 'center', width: '763px', height: '40px', borderRadius: '4px', borderColor: '#cccccc'}}
                    />
                </div>

                <div>
                    {
                        this.state.country ?
                        <p style={{ fontFamily: "ramblaBold", color: '#000' }}>{this.state.country.countryName},{this.state.region.name}</p>: null
                    }
                    <CountryRegionPicker/>
                </div>

                <div style={{ marginBottom: '10px' }}>
                    <input
                        type={'text'}
                        placeholder={'Company Address'}
                        onChange={e => this.props.credentialsChanged({prop: 'address', value: e.target.value}) }
                        value={this.state.address}
                        onKeyDown={this.keyPressed}
                        style={{textAlign: 'center', width: '763px', height: '40px', borderRadius: '4px', borderColor: '#cccccc'}}
                    />
                </div>
                <div style={{ marginBottom: '10px' }}>
                    <input
                        type={'text'}
                        placeholder={'Postal Code'}
                        onChange={e => this.props.credentialsChanged({prop: 'postalCode', value: e.target.value}) }
                        value={this.state.postalCode}
                        onKeyDown={this.keyPressed}
                        style={{textAlign: 'center', width: '763px', height: '40px', borderRadius: '4px', borderColor: '#cccccc'}}
                    />
                </div>

                <div style={{ marginTop: "1rem", border: "1px solid #ccc", borderRadius: '4px', padding: "10px" }}>
                    <label>Company registration document</label>
                    <input
                        type={'file'}
                        onChange={(e) => this.handleCompanyDocument(e)}
                        accept={"image/png"}
                    />
                    {
                        this.state.companyRegDocument ?
                        <img
                            src={this.state.companyRegDocument}
                            style={{ height: '3rem', width: '3rem', margin: '2rem 0' }}
                            alt="companyRegDocument"
                        /> : null
                    }
                </div>

                <div style={{ marginTop: "1rem", border: "1px solid #ccc", borderRadius: '4px', padding: "10px", display: 'flex', justifyContent: 'flex-end' }}>
                    <label>Admin ID's</label>
                    <input
                        type={'file'}
                        onChange={(e) => this.handleAdminID(e)}
                        accept={"image/png"}
                    />
                    {
                        this.state.adminIDs ?
                        <img
                            src={this.state.adminIDs}
                            style={{ height: '3rem', width: '3rem', margin: '2rem 0' }}
                            alt="adminIDs"
                        /> : null
                    }
                </div>

                <div style={{ marginTop: "1rem", border: "1px solid #ccc", borderRadius: '4px', padding: "10px" }}>
                    <label>Organization Logo</label>
                    <input
                        type={'file'}
                        onChange={(e) => this.handleOrganizationLogo(e)}
                        accept={"image/png"}
                    />
                    {
                        this.state.logo ?
                        <img
                            src={this.state.logo}
                            style={{ height: '3rem', width: '3rem', margin: '2rem 0' }}
                            alt="logo"
                        /> : null
                    }
                </div>

                { this.renderSignUpError() }

                { this.renderUpdateButton() }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const {
        firstName, lastName, phone, companyName, companyPhone, companyEmail, companyRegDocument, adminIDs,
        logo, logoChanged, country, region, address, postalCode, signUpError, loading } = state.authentication;
    return {
        firstName,
        lastName,
        phone,
        companyName,
        companyPhone,
        companyEmail,
        logo,
        logoChanged,
        country,
        region,
        address,
        postalCode,

        signUpError,
        loading,
        companyRegDocument,
        adminIDs,

    }
};

export default connect(mapStateToProps, { credentialsChanged, updateProfile })(EditProfile);