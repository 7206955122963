import React from 'react';
import {connect} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import _ from "lodash";
import { message, Input, Select, Popconfirm } from "antd";
import { HeartOutlined } from '@ant-design/icons';
//import 'antd/dist/antd.css';

//action imports
import { subscriptionFieldUpdated, makePayment, fetchPaymentStatus, makeForeignPayment } from "../../../../actions/SubscriptionActions";

//component imports

//file imports
//import Logo from '../../../../education/logos/logo.png';
import Earth from '../../../../assets/images/worldwide.png';
import Star from '../../../../assets/images/star.png';
import Galaxy from '../../../../assets/images/solar-system.png';
import Universe from '../../../../assets/images/universe.png';
import {Spinner} from "../../../common/Spinner";
import PaymentModal from "./PaymentModal";

//property declaration
const paymentPackages = [
    {
        benefits: [],
        packageID: "adopter",
        packageIndex: 4,
        price: 0,
        foreignPrice: 0,
        title: "Adopter",
        index: 4,
        className: "universe",
        icon: Earth
    },
    {
        benefits: [],
        packageID: "startup",
        packageIndex: 3,
        price: 159000,
        foreignPrice: 59,
        title: "Startup",
        index: 3,
        className: "galaxy",
        icon: Star
    },
    {
        benefits: [],
        packageID: "business",
        packageIndex: 2,
        price: 257000,
        foreignPrice: 99,
        title: "Business",
        index: 2,
        className: "universe",
        icon: Galaxy
    },
    {
        benefits: [],
        packageID: "enterprise",
        packageIndex: 1,
        price: 699000,
        foreignPrice: 269,
        title: "Enterprise",
        index: 1,
        className: "galaxy",
        icon: Universe
    }
];



class ChoosePackageBase extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            profile: this.props.profile,

            paymentPackage: this.props.paymentPackage,
            carrier: this.props.carrier,
            paymentPhone: this.props.paymentPhone,
            paymentMonths: this.props.paymentMonths,

            months: this.props.months,
            paymentPackages: this.props.paymentPackages,
            paymentLoading: this.props.paymentLoading,
            transID: this.props.transID,
            payment: this.props.payment,

            flwUrl: this.props.flwUrl,
            packageTitle: this.props.packageTitle
        };

        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.handlePaymentPackageChange = this.handlePaymentPackageChange.bind(this);
        this.handleSelectCarrier = this.handleSelectCarrier.bind(this);
        this.handleSelectMonth = this.handleSelectMonth.bind(this);
        this.onMakePayment = this.onMakePayment.bind(this);
        this.populatePhone = this.populatePhone.bind(this);
        this.cancel = this.cancel.bind(this);

    }

    componentDidMount() {
        this.populatePhone();
        this.populateTransID();

    }

    populatePhone() {
        const { profile } = this.state;
        if (!(_.isEmpty(profile))) {
            const phone = profile.phone;
            this.props.subscriptionFieldUpdated({ prop: "paymentPhone", value: phone });
        }
    }

    populateTransID() {
        const { transID } = this.state;
        if (transID) {
            this.props.fetchPaymentStatus({ transactionID: transID });
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props) {
            this.setState({
                profile: nextProps.profile,

                paymentPackage: nextProps.paymentPackage,
                carrier: nextProps.carrier,
                paymentPhone: nextProps.paymentPhone,
                paymentMonths: nextProps.paymentMonths,

                months: nextProps.months,
                packageTitle: nextProps.packageTitle,
                paymentPackages: nextProps.paymentPackages,
                paymentLoading: nextProps.paymentLoading,
                transID: nextProps.transID,
                payment: nextProps.payment,
                flwUrl: nextProps.flwUrl,

            });
        }

        if (nextProps.flwUrl !== this.props.flwUrl) {
            console.log({ flwUrl: nextProps.flwUrl });
        }

        if (nextProps.profile !== this.props.profile) {
            this.populatePhone();
        }

        if (nextProps.transID !== this.props.transID) {
            this.populateTransID();
        }
    }

    handlePhoneChange(e) {
        const value = e.target.value;

        if (value.length === 0) {
            this.props.subscriptionFieldUpdated({ prop: "paymentPhone", value });
        } else {
            const validateInput = (input) => {
                const regex = /^\d+$/; // Regex to match only digits
                const maxLength = 10;
                return regex.test(input) && input.length <= maxLength;
            };

            if (validateInput(value)) {
                this.props.subscriptionFieldUpdated({ prop: "paymentPhone", value });
            } else {
                message.warning("Enter a valid phone number");
            }
        }
    };

    handlePaymentPackageChange(value) {
        this.props.subscriptionFieldUpdated({ prop: "paymentPackage", value });
    };

    handleSelectCarrier(value) {
        this.props.subscriptionFieldUpdated({ prop: "carrier", value });
    };

    handleSelectMonth(value) {
        this.props.subscriptionFieldUpdated({ prop: "paymentMonths", value });
    };

    onMakePayment(paymentPackage) {
        const { profile, carrier, paymentMonths, paymentPhone } = this.state;

        try {
            if (paymentPackage.index === 4) {
                return message.info("Already Subscribed");
            } else {
                if (!(_.isEmpty(profile))) {
                    const name = `${profile.firstName} ${profile.lastName}`;
                    const phone = profile.phone;
                    const email = profile.email;

                    let location = null;
                    if ("companyLocation" in profile) {
                        location = profile.companyLocation;
                    }

                    const paymentDate = new Date();

                    if (!(_.isEmpty(paymentPackage))) {
                        if (!(_.isEmpty(carrier))) {
                            if (!(_.isEmpty(paymentMonths))) {
                                let amount = 0;
                                let processingFee = 0;
                                if (!(_.isEmpty(paymentPackage))) {
                                    amount = paymentPackage.price;
                                }
                                if (!(_.isEmpty(paymentMonths))) {
                                    const payMonth = JSON.parse(paymentMonths);
                                    amount = amount * payMonth.value;
                                }

                                if (paymentPhone) {
                                    this.props.makePayment({
                                        name,
                                        email,
                                        phone,
                                        paymentPackage,
                                        paymentMonths: JSON.parse(paymentMonths),
                                        amount,
                                        processingFee,
                                        carrier: JSON.parse(carrier),
                                        paymentPhone,
                                        paymentDate,
                                        location
                                    });
                                } else {
                                    message.warning("Enter Phone #");
                                }
                            } else {
                                message.warning("Pick Months");
                            }
                        } else {
                            message.warning("Choose Wallet");
                        }
                    } else {
                        message.warning("Pick package");
                    }
                } else {
                    message.warning("Loading");
                }
            }
        } catch (e) {
            console.log(e);
        }
    }

    cancel() {
        this.props.subscriptionFieldUpdated({ prop: "packageTitle", value: '' });
        message.warning('Canceled');
    }

    onMakeForeignPayment(pack) {
        const { profile, paymentMonths } = this.state;
        console.log("we are here");
        console.log({ pack, profile });

        try {
            if (pack.index === 4) {
                return message.info("Already Subscribed");
            } else {
                if (!(_.isEmpty(profile))) {
                    if (!(_.isEmpty(paymentMonths))) {
                        const name = `${profile.companyName}`;
                        const phone = profile.phone;
                        const email = profile.email;
                        const location = profile.companyLocation;
                        const paymentDate = new Date();

                        this.props.makeForeignPayment({
                            name,
                            email,
                            phone,
                            paymentPackage: pack,
                            paymentMonths,
                            amount: pack.foreignPrice,
                            processingFee: 0,
                            paymentPhone: phone,
                            paymentDate,
                            location,
                            currency: "NGN"
                        });
                    } else {
                        message.warning("Pick Months");
                    }
                } else {
                    alert("Loading");
                }
            }
        } catch (e) {
            console.error(e);
        }
    }

    handlePackageClick(title) {
        this.props.subscriptionFieldUpdated({ prop: "packageTitle", value: title });
    }

    renderPopUp(pack) {
        const { flwUrl, packageTitle } = this.state; 
        if (flwUrl && packageTitle === pack.title) { 
            try {
                return (
                    <div>
                        <PaymentModal
                            flwUrl={flwUrl}
                            visible={true}
                        />
                    </div>
                );
            } catch (e) {
                console.log(e);
            }
        }
        // const { flwUrl } = this.state;
        // if (flwUrl) {
        //     try {
        //         //return navigation.navigate("WebScreen", { flwUrl, payment, callbackUrl: app.foreignCallback });
        //         return(
        //             <div>
        //                 <PaymentModal
        //                     flwUrl={flwUrl}
        //                     visible={true}
        //                 />
        //             </div>
        //         )
        //     } catch (e) {
        //         console.log(e);
        //     }
        // }
    }


    render() {
        const {paymentPhone, carrier, paymentMonths, months, paymentLoading, payment, profile, packageTitle} = this.state;
        console.log('packageTitle:', packageTitle);
        //show foreign or local currency
        const dollar = profile.companyLocation.countryCode !== "TZ";
        const newMonths = months.map(month => ({ label: month.title, value: JSON.stringify(month), index: month.value }));
        const orderedMonths = _.orderBy(newMonths, ['index'], ['asc']);

        return (
            <div className={"subscriptionContainer policy"}>
                <div className={'subscriptionBody'}>
                    <div className={'topHeader'}>
                        <h4 style={{ color: "gray"}}>Nafasi offers 4 subscription plans</h4>
                        <h5 style={{ fontFamily: "ramblaBold", color: "#0793d9"}}>Choose a plan and start your journey into the Nafasi universe.</h5>
                    </div>

                    <div className={'choosePackageInputBox'}>
                        {
                            dollar ?
                                null :
                                <div>
                                    <div className="form-group">
                                        <label htmlFor="phone-number">Phone Number:</label>
                                        <Input
                                            style={{ width: '100%' }}
                                            allowClear
                                            maxLength={10}
                                            id="phone"
                                            value={paymentPhone}
                                            onChange={this.handlePhoneChange}
                                            placeholder="Enter phone number"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="payment-method">Payment Method:</label>
                                        <Select
                                            placeholder={"Select payment method"}
                                            value={carrier}
                                            style={{
                                                width: "100%",
                                            }}
                                            onChange={this.handleSelectCarrier}
                                            options={[
                                                {
                                                    value: JSON.stringify({ carrierID: "airtelMoney", title: "Airtel Money" }),
                                                    label: 'Airtel Money',
                                                },
                                                {
                                                    value: JSON.stringify({ carrierID: "ezyPesa", title: "Ezy Pesa" }),
                                                    label: 'Ezy Pesa',
                                                },
                                                {
                                                    value: JSON.stringify({ carrierID: "mPesa", title: "M-Pesa" }),
                                                    label: 'M-Pesa',
                                                },
                                                {
                                                    value: JSON.stringify({ carrierID: "tigoPesa", title: "Tigo Pesa" }),
                                                    label: 'Tigo Pesa'
                                                },
                                            ]}
                                        />
                                    </div>
                                </div>
                        }


                        <div className="form-group">
                            <label htmlFor="payment-method">Duration:</label>
                            <Select
                                placeholder={"Pick your subscription duration"}
                                value={paymentMonths}
                                style={{
                                    width: "100%",
                                }}
                                onChange={this.handleSelectMonth}
                                options={orderedMonths}
                            />
                        </div>
                    </div>
                    {
                        paymentLoading ?
                            <div style={{ display: "grid", justifyContent: "center", alignItems: "center", margin: "2rem"}}>
                                <p style={{ fontFamily: "ramblaBold", fontSize: "16px", color: "#0793d9"}}>Finish payment on your phone</p>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <Spinner/>
                                </div>
                                <button style={{ backgroundColor: "#8FBC44", width: "10rem", margin: "2rem auto"  }} onClick={() => this.props.subscriptionFieldUpdated({ prop: "paymentLoading", value: false })}>
                                    <span style={{ margin: "0.5rem", fontFamily: "ramblaRegular"}}>Cancel</span>
                                </button>
                            </div>
                            :
                            <div className={'cardsContainer'}>
                                {
                                    paymentPackages.map(pack => {
                                        let amount = 0;
                                        let period = 1;
                                        if (!(_.isEmpty(pack))) {
                                            if (dollar) {
                                                amount = pack.foreignPrice;
                                            } else {
                                                amount = pack.price;
                                            }
                                        }

                                        if (!(_.isEmpty(paymentMonths))) {
                                            const payMonth = JSON.parse(paymentMonths);
                                            amount = amount * payMonth.value;
                                            period = payMonth.value;
                                        }
                                        return(
                                            <div className={`${pack.className} subscriptionCard`} onClick={() => this.handlePackageClick(pack.title)}>
                                                <h2 className={'title'}>{pack.title}</h2>
                                                <img src={pack.icon} alt={'3 months subscription'} id={'subImage'}/>
                                                <div>
                                                    <h3>{dollar ? "$" : "TSH."} {amount.toLocaleString()}</h3>
                                                    <p>per {period} Month(s)</p>
                                                </div>
                                                <div className={'subscribeBtnContainer'}>
                                                    <div className={'buttonBox2'}>
                                                        <Popconfirm
                                                            title={`${dollar ? "C" : "Unlock your phone to c"}omplete your ${pack.title} subscription for ${dollar ? "$" : "Tsh. "}${amount.toLocaleString()} ${dollar ? "" : "on the USSD popup"}`}
                                                            icon={<HeartOutlined />}
                                                            onConfirm={() => dollar ? this.onMakeForeignPayment(pack) : this.onMakePayment(pack)}
                                                            onCancel={() => this.cancel()}
                                                            okText="Confirm"
                                                            cancelText="No"
                                                            overlayStyle={{ width: "400px" }}
                                                        >
                                                            <button style={{ backgroundColor: "#8FBC44", width: "10rem" }}>
                                                                <span style={{ margin: "0.5rem", fontFamily: "ramblaRegular"}}>Subscribe</span>
                                                            </button>
                                                        </Popconfirm>
                                                    </div>
                                                </div>
                                                {this.renderPopUp(pack)}
                                            </div>
                                        );
                                    })
                                }
                            </div>
                    }
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => {
    const { profile } = state.profile;
    const { paymentPackage, carrier, paymentPhone, paymentMonths, months, packageTitle, paymentPackages, paymentLoading, transID, flwUrl } = state.subscription;

    return {
        profile,

        paymentPackage,
        carrier,
        paymentPhone,
        paymentMonths,

        months,
        packageTitle,
        paymentPackages,
        paymentLoading,
        transID,

        flwUrl
    }
};

// Wrapper functional component to use hooks
function ChoosePackage(props) {
    const navigate = useNavigate();

    return <ChoosePackageBase {...props} navigate={navigate} />;
}

export default connect(mapStateToProps, { subscriptionFieldUpdated, makePayment, fetchPaymentStatus, makeForeignPayment })(ChoosePackage);