import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { connect } from "react-redux";
import _ from "lodash";

//action imports
import { postsFieldChanged, saveRatings } from "../../../actions/PostsActions";

//common imports
import {Spinner} from "../../common/Spinner";

//component imports
import SelectInterviewRating from "../../common/SelectInterviewRating";
import {message, Button} from "antd";


class IASInterview extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            profile: this.props.profile,
            selectedCandidate: this.props.selectedCandidate,
            selectedPost: this.props.selectedPost,
            ratingsLoading: this.props.ratingsLoading,
            myInterviews: this.props.myInterviews,
            interviewsLoading: this.props.interviewsLoading,

            adaptability: this.props.adaptability,
            analyticalThinking: this.props.analyticalThinking,
            creativity: this.props.creativity,
            decisionMaking: this.props.decisionMaking,
            emotionalIntelligence: this.props.emotionalIntelligence,
            initiative: this.props.initiative,
            leadership: this.props.leadership,
            organizationalSkills: this.props.organizationalSkills,
            professionalism: this.props.professionalism,
            teamwork: this.props.teamwork,
            timeManagement: this.props.timeManagement,
            verbalCommunication: this.props.verbalCommunication,
            workEthic: this.props.workEthic,
            writtenCommunication: this.props.writtenCommunication
        }
    }

    componentWillMount() {

    }

    componentWillReceiveProps(nextProps){
        if(nextProps !== this.props){
            this.setState({
                profile: nextProps.profile,
                selectedCandidate: nextProps.selectedCandidate,
                selectedPost: nextProps.selectedPost,
                ratingsLoading: nextProps.ratingsLoading,
                myInterviews: nextProps.myInterviews,
                interviewsLoading: nextProps.interviewsLoading,

                adaptability: nextProps.adaptability,
                analyticalThinking: nextProps.analyticalThinking,
                creativity: nextProps.creativity,
                decisionMaking: nextProps.decisionMaking,
                emotionalIntelligence: nextProps.emotionalIntelligence,
                initiative: nextProps.initiative,
                leadership: nextProps.leadership,
                organizationalSkills: nextProps.organizationalSkills,
                professionalism: nextProps.professionalism,
                teamwork: nextProps.teamwork,
                timeManagement: nextProps.timeManagement,
                verbalCommunication: nextProps.verbalCommunication,
                workEthic: nextProps.workEthic,
                writtenCommunication: nextProps.writtenCommunication

            })
        }
    }

    onSave() {
        const { selectedCandidate, selectedPost,
            adaptability, analyticalThinking, creativity, decisionMaking, emotionalIntelligence, initiative, leadership,
            organizationalSkills, professionalism, teamwork, timeManagement, verbalCommunication, workEthic, writtenCommunication, myInterviews, interviewsLoading } = this.state;

        if ((!(_.isEmpty(selectedCandidate))) && (!(_.isEmpty(selectedPost)))) {
            if (interviewsLoading) {
                return <Spinner/>
            } else {
                let interviewID = "";
                myInterviews.forEach(interview => {
                    if (!interview.infoBox.general) {
                        if (interview.infoBox.postID === selectedPost.postID) {
                            interviewID = interview.infoBox.interviewID;
                        }
                    }
                });

                if (interviewID) {
                    const { userID, firstName } = selectedCandidate;
                    const { postID, postTitle, companyName } = selectedPost;
                    this.props.saveRatings({
                        adaptability, analyticalThinking, creativity, decisionMaking, emotionalIntelligence, initiative, leadership,
                        organizationalSkills, professionalism, teamwork, timeManagement, verbalCommunication, workEthic, writtenCommunication,
                        candidateID: userID, interviewID, postID, firstName, postTitle, companyName
                    });
                } else {
                    message.warn("Can not find the interview ID");
                }
            }
        } else {
            message.warn("Failed to save ratings, try again");
        }
    }

    renderButtons() {
        const {ratingsLoading } = this.state;
        if (ratingsLoading) {
            return <Spinner/>
        } else {
            return (
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                        onClick={() => this.onSave()}
                        style={{ width: "30rem", margin: "1rem auto", backgroundColor: "#0793d9" }}
                    >
                        <span style={{ fontFamily: "ramblaBold", color: "#000" }}>Save Rating</span>
                    </Button>
                </div>
            )
        }
    }

    checkUser() {
        //extract state
        const {profile, selectedCandidate, selectedPost,
            adaptability, analyticalThinking, creativity, decisionMaking, emotionalIntelligence, initiative, leadership,
            organizationalSkills, professionalism, teamwork, timeManagement, verbalCommunication, workEthic, writtenCommunication, myInterviews, interviewsLoading } = this.state;

        if(!(_.isEmpty(profile))) {
            if((profile.role === "admin") || profile.role === "superAdmin") {
                if (selectedPost && !(_.isEmpty(selectedCandidate))) {
                    if (interviewsLoading) {
                        return <Spinner/>
                    } else {

                        let interviews = [];
                        if (myInterviews.length !== 0) {
                            myInterviews.forEach(interview => {
                                if (!interview.infoBox.general) {
                                    interviews.push(interview);
                                }
                            });

                            const status = interviews.some(interview => interview.infoBox.postID === selectedPost.postID);
                            if (status) {
                                return (
                                    <div>
                                        <div className={"topBarBox"}>
                                            <div className={"childHeaderBarBox"}>
                                                <FontAwesomeIcon icon="file-signature" style={{fontSize: '1rem'}}/>
                                                <h2>Interview Rating</h2>
                                            </div>
                                        </div>

                                        <div className={"performInterContainer"}>
                                            <p style={{ fontFamily: "ramblaBold", color: "#ffb400" }}>
                                                In this table, you can rate each interviewee on a scale of 1 to 5 for each criterion.
                                                The rating scale can be defined as follows:
                                            </p>
                                            <ol>
                                                <li>Poor <span style={{ fontFamily: "ramblaBold", color: "#000" }}>- Demonstrates a lack of proficiency or understanding in the particular skill or quality.</span></li>
                                                <li>Below Average <span style={{ fontFamily: "ramblaBold", color: "#000" }}>- Shows some level of competency but needs improvement.</span></li>
                                                <li>Average <span style={{ fontFamily: "ramblaBold", color: "#000" }}>- Demonstrates an acceptable level of skill or quality.</span></li>
                                                <li>Above Average <span style={{ fontFamily: "ramblaBold", color: "#000" }}>- Shows a good level of competency, performing better than average.</span></li>
                                                <li>Excellent <span style={{ fontFamily: "ramblaBold", color: "#000" }}>- Exhibits exceptional proficiency and mastery in the skill or quality</span>.</li>
                                            </ol>
                                            <div>
                                                <div className={"performInterviewBox"}>
                                                    <div><h5>Adaptability</h5></div>
                                                    <div style={{textAlign: "center"}}>
                                                        <SelectInterviewRating
                                                            placeholder={"0"}
                                                            value={adaptability}
                                                            onChange={(value) => this.props.postsFieldChanged({
                                                                prop: "adaptability",
                                                                value
                                                            })}
                                                        />
                                                    </div>
                                                    <div><p>Flexibility and willingness to adapt to new situations and
                                                        challenges.</p></div>
                                                </div>

                                                <div className={"performInterviewBox"}>
                                                    <div><h5>Problem Solving Skills</h5></div>
                                                    <div> </div>
                                                    <div><p style={{backgroundColor: "#ffb400"}}>Ability to identify and
                                                        solve problems efficiently.</p></div>
                                                </div>

                                                <div className={"performInterviewBox"}>
                                                    <div><h5>Analytical Thinking</h5></div>
                                                    <div style={{textAlign: "center"}}>
                                                        <SelectInterviewRating
                                                            placeholder={"0"}
                                                            value={analyticalThinking}
                                                            onChange={(value) => this.props.postsFieldChanged({
                                                                prop: "analyticalThinking",
                                                                value
                                                            })}
                                                        />
                                                    </div>
                                                    <div><p>Capability to analyze complex situations and draw logical
                                                        conclusions.</p></div>
                                                </div>

                                                <div className={"performInterviewBox"}>
                                                    <div><h5>Creativity</h5></div>
                                                    <div style={{textAlign: "center"}}>
                                                        <SelectInterviewRating
                                                            placeholder={"0"}
                                                            value={creativity}
                                                            onChange={(value) => this.props.postsFieldChanged({
                                                                prop: "creativity",
                                                                value
                                                            })}
                                                        />
                                                    </div>
                                                    <div><p>Capacity to think outside the box and generate innovative
                                                        ideas.</p></div>
                                                </div>

                                                <div className={"performInterviewBox"}>
                                                    <div><h5>Decision Making</h5></div>
                                                    <div style={{textAlign: "center"}}>
                                                        <SelectInterviewRating
                                                            placeholder={"0"}
                                                            value={decisionMaking}
                                                            onChange={(value) => this.props.postsFieldChanged({
                                                                prop: "decisionMaking",
                                                                value
                                                            })}
                                                        />
                                                    </div>
                                                    <div><p>Capacity to make sound judgments and decisions.</p></div>
                                                </div>

                                                <div className={"performInterviewBox"}>
                                                    <div><h5>Emotional Intelligence</h5></div>
                                                    <div style={{textAlign: "center"}}>
                                                        <SelectInterviewRating
                                                            placeholder={"0"}
                                                            value={emotionalIntelligence}
                                                            onChange={(value) => this.props.postsFieldChanged({
                                                                prop: "emotionalIntelligence",
                                                                value
                                                            })}
                                                        />
                                                    </div>
                                                    <div><p>Ability to recognize and manage one's own emotions and
                                                        understand others.</p></div>
                                                </div>

                                                <div className={"performInterviewBox"}>
                                                    <div><h5>Initiative</h5></div>
                                                    <div style={{textAlign: "center"}}>
                                                        <SelectInterviewRating
                                                            placeholder={"0"}
                                                            value={initiative}
                                                            onChange={(value) => this.props.postsFieldChanged({
                                                                prop: "initiative",
                                                                value
                                                            })}
                                                        />
                                                    </div>
                                                    <div><p>Proactive approach in taking action and seeking
                                                        opportunities.</p></div>
                                                </div>

                                                <div className={"performInterviewBox"}>
                                                    <div><h5>Leadership</h5></div>
                                                    <div style={{textAlign: "center"}}>
                                                        <SelectInterviewRating
                                                            placeholder={"0"}
                                                            value={leadership}
                                                            onChange={(value) => this.props.postsFieldChanged({
                                                                prop: "leadership",
                                                                value
                                                            })}
                                                        />
                                                    </div>
                                                    <div><p>Capability to lead, guide, and motivate a team.</p></div>
                                                </div>

                                                <div className={"performInterviewBox"}>
                                                    <div><h5>Organization Skills</h5></div>
                                                    <div style={{textAlign: "center"}}>
                                                        <SelectInterviewRating
                                                            placeholder={"0"}
                                                            value={organizationalSkills}
                                                            onChange={(value) => this.props.postsFieldChanged({
                                                                prop: "organizationalSkills",
                                                                value
                                                            })}
                                                        />
                                                    </div>
                                                    <div><p>Ability to efficiently manage resources and stay
                                                        organized.</p></div>
                                                </div>

                                                <div className={"performInterviewBox"}>
                                                    <div><h5>Professionalism</h5></div>
                                                    <div style={{textAlign: "center"}}>
                                                        <SelectInterviewRating
                                                            placeholder={"0"}
                                                            value={professionalism}
                                                            onChange={(value) => this.props.postsFieldChanged({
                                                                prop: "professionalism",
                                                                value
                                                            })}
                                                        />
                                                    </div>
                                                    <div><p>Demonstration of professional behavior and attitude.</p>
                                                    </div>
                                                </div>

                                                <div className={"performInterviewBox"}>
                                                    <div><h5>Teamwork</h5></div>
                                                    <div style={{textAlign: "center"}}>
                                                        <SelectInterviewRating
                                                            placeholder={"0"}
                                                            value={teamwork}
                                                            onChange={(value) => this.props.postsFieldChanged({
                                                                prop: "teamwork",
                                                                value
                                                            })}
                                                        />
                                                    </div>
                                                    <div><p>Ability to collaborate effectively with others towards a
                                                        common goal.</p></div>
                                                </div>

                                                <div className={"performInterviewBox"}>
                                                    <div><h5>Time Management</h5></div>
                                                    <div style={{textAlign: "center"}}>
                                                        <SelectInterviewRating
                                                            placeholder={"0"}
                                                            value={timeManagement}
                                                            onChange={(value) => this.props.postsFieldChanged({
                                                                prop: "timeManagement",
                                                                value
                                                            })}
                                                        />
                                                    </div>
                                                    <div><p>Skill in prioritizing tasks and meeting deadlines.</p></div>
                                                </div>

                                                <div className={"performInterviewBox"}>
                                                    <div><h5>Communication Skills</h5></div>
                                                    <div> </div>
                                                    <div><p style={{backgroundColor: "#ffb400"}}>Ability to effectively
                                                        convey ideas and information.</p></div>
                                                </div>

                                                <div className={"performInterviewBox"}>
                                                    <div><h5>Verbal Communication</h5></div>
                                                    <div style={{textAlign: "center"}}>
                                                        <SelectInterviewRating
                                                            placeholder={"0"}
                                                            value={verbalCommunication}
                                                            onChange={(value) => this.props.postsFieldChanged({
                                                                prop: "verbalCommunication",
                                                                value
                                                            })}
                                                        />
                                                    </div>
                                                    <div><p>Proficiency in expressing thoughts verbally.</p></div>
                                                </div>

                                                <div className={"performInterviewBox"}>
                                                    <div><h5>Written Communication</h5></div>
                                                    <div style={{textAlign: "center"}}>
                                                        <SelectInterviewRating
                                                            placeholder={"0"}
                                                            value={writtenCommunication}
                                                            onChange={(value) => this.props.postsFieldChanged({
                                                                prop: "writtenCommunication",
                                                                value
                                                            })}
                                                        />
                                                    </div>
                                                    <div><p>Proficiency in written communication, including grammar and
                                                        clarity.</p></div>
                                                </div>

                                                <div className={"performInterviewBox"}>
                                                    <div><h5>Work Ethic</h5></div>
                                                    <div style={{textAlign: "center"}}>
                                                        <SelectInterviewRating
                                                            placeholder={"0"}
                                                            value={workEthic}
                                                            onChange={(value) => this.props.postsFieldChanged({
                                                                prop: "workEthic",
                                                                value
                                                            })}
                                                        />
                                                    </div>
                                                    <div><p>Dedication, reliability, and commitment to work.</p></div>
                                                </div>
                                            </div>
                                        </div>

                                        {this.renderButtons()}
                                    </div>
                                );
                            }

                        } else {
                            return <h4 style={{color: "#fff", fontFamily: "ramblaBold", margin: "2rem"}}>Invite
                                candidate to a post interview, to view</h4>;
                        }
                    }
                } else {
                    return <h4 style={{ color: "#fff", fontFamily: "ramblaBold", margin: "2rem" }}>Pick post and candidate to view</h4>;
                }
            }
        } else {
            return <h4 style={{ color: "#fff", fontFamily: "ramblaBold", margin: "2rem" }}>Sign in to view</h4>;
        }
    }

    render() {
        return(
            <div className={"hiringContainer"}>
                {this.checkUser()}
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { selectedCandidate, selectedPost, adaptability, analyticalThinking, creativity, decisionMaking, emotionalIntelligence, initiative, leadership,
        organizationalSkills, professionalism, teamwork, timeManagement, verbalCommunication, workEthic, writtenCommunication, ratingsLoading, myInterviews,
        interviewsLoading } = state.posts;
    const { profile } = state.profile;

    return{
        selectedCandidate,
        profile,
        selectedPost,
        ratingsLoading,
        myInterviews,
        interviewsLoading,

        adaptability, analyticalThinking, creativity, decisionMaking, emotionalIntelligence, initiative, leadership,
        organizationalSkills, professionalism, teamwork, timeManagement, verbalCommunication, workEthic, writtenCommunication
    }
}

export default connect(mapStateToProps, { postsFieldChanged, saveRatings })(IASInterview);