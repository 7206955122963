import {
    CONTACT_US_DETAILS_CHANGED,
    SAVE_CONTACT_DETAILS,
    SAVE_CONTACT_DETAILS_SUCCESSFUL,
    SAVE_CONTACT_DETAILS_FAILED,
    DATA_FOUND,
} from "../actions/Types";


const INITIAL_STATE = {
    //initial reducers
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    contactNote: '',

    contactLoading: false,
    contactLoadingSuccess: false,
    contactLoadingFailed: false,

    dataFound:false,
    fetchedData:{}
};

const deepClone = obj => JSON.parse(JSON.stringify(obj));

const LandingReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case CONTACT_US_DETAILS_CHANGED:
            return {...state, [action.payload.prop]: action.payload.value };

        case SAVE_CONTACT_DETAILS:
            return {...state, contactLoading: true };
        case SAVE_CONTACT_DETAILS_SUCCESSFUL:
            return {...state, contactName: '', contactEmail: '', contactPhone: '', contactNote: '', contactLoading: false, contactLoadingSuccess: true };
        case SAVE_CONTACT_DETAILS_FAILED:
            return {...state, contactName: '', contactEmail: '', contactPhone: '', contactNote: '', contactLoading: false, contactLoadingSuccess: false, contactLoadingFailed: true };

        //fetch reducers
        // case FETCH_COMPANY_FILES:
        //     return {...state, };
        // case FETCH_COMPANY_FILES_SUCCESSFUL:
        //     return {...state, companyFiles: action.payload };
        // case FETCH_COMPANY_FILES_FAILED:
        //     return {...state, };

        case DATA_FOUND:
            console.log(deepClone(action.payload))
        return {...state, dataFound:true, fetchedData:action.payload};
        default:
            return state;
    }

};

export default LandingReducer;