import React, { useState} from "react";
import { Modal, Typography } from "antd";
import _ from "lodash";

const { Text } = Typography;

const TruncatedText = ({ seeker, maxLength,  }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleToggleModal = () => {
        setIsModalVisible(!isModalVisible);
    };

    const capitalizeFirstLetter = (string) => {
        const [firstLetter, ...rest] = string;
        return firstLetter.toUpperCase() + rest.join('');
    };

  

    const renderText = () => {
        const {employment} = seeker;
        if (employment) {
            let expSentence = "";
            if (employment.length !== 0) {
                const experience = employment.map((exp) => `${exp.jobTitle} [${exp.overallYears} Years & ${exp.overallMonths} Months],`);
                expSentence = experience.slice(0, -1).join(", ") + " " + experience[experience.length - 1];
                
            }

            if (expSentence.length === 0) {
                return <Text style={{ textAlign: "center", fontSize: '0.9rem', textOverflow: 'ellipsis', color: "#000", fontFamily: 'ramblaRegular', padding: "0 0.2rem" }}>No Experience</Text>;
            }

            return (
                <Text onClick={handleToggleModal} style={{ cursor: "pointer", fontSize: '0.9rem', textOverflow: 'ellipsis', textAlign: "center", color: "#0793d9", fontFamily: 'ramblaRegular', padding: "0 0.2rem" }}>
                    {expSentence.substring(0, maxLength)}...
                </Text>
            );
        }
    };

    const renderPositions = (experiences) => {
        if (experiences.length !== 0) {
            return (
                <div>
                    {experiences.map((exp) => (
                        <div key={exp.jobTitle}>

                            <h2>{capitalizeFirstLetter(exp.experience)}</h2>
                            
                            {
                                exp.current ? (
                                    <p style={{  textAlign: "left"}}>
                                        Currently in this position for{" "}
                                        <span style={{ fontFamily: "ramblaBold", color: "#000", }}>{exp.experienceYears} Years and {exp.experienceMonths} Months</span>
                                    </p>
                                    ) : (
                                        <p style={{ textAlign: "left"}}>
                                            Was in this position for{" "}
                                            <span style={{ fontFamily: "ramblaBold", color: "#000", textAlign: "left"}}>{exp.experienceYears} Years and {exp.experienceMonths} Months</span>
                                        </p>
                                )}
                                
                        </div>
                    ))}
                </div>
            );
        }
    };

    const renderExperiences = () => {
        const { employment } = seeker;

        if (employment) {
            return (
                <div>
                    {
                        _.map(employment, (exp) => (
                            <div key={exp.jobTitle} style={{ backgroundColor: "#ffb400", borderRadius: 10,padding: "2rem 10rem" ,margin: "1rem", textAlign: "left" }}>
                                <h3>{exp.jobTitle}</h3>
                                {
                                    exp.currentWork ? (
                                        <p style={{ fontFamily: "ramblaBold", color: "#fff", textAlign: "left"}}>
                                            Currently works here for{" "}
                                            <span style={{ fontFamily: "ramblaBold", color: "#000"}}>{exp.overallYears} Years and {exp.overallMonths} Months</span>
                                        </p>
                                    ) : (
                                        <p style={{ fontFamily: "ramblaBold", color: "#000", textAlign: "left"}}>
                                            Worked here for{" "}
                                            <span style={{ fontFamily: "ramblaBold", color: "#000"}}>{exp.overallYears} Years and {exp.overallMonths} Months</span>
                                        </p>
                                    )
                                }

                                {exp.experiences ? <div>{renderPositions(exp.experiences)}</div> : null}
                        </div>
                    ))}
                </div>
            );
        }
    };

    return (
        <div>
            {renderText()}
            <Modal
                title={<h2 style={{ fontFamily: "ramblaBold", color: "black", textAlign: "center"}}>Work Experience</h2>}
                open={isModalVisible}
                onOk={handleToggleModal}
                onCancel={handleToggleModal}
                width={900}
                titleStyle={{ textAlign: "center" }} 
            >
                <div className={"ticketModalDetails"}>{renderExperiences()}</div>
            </Modal>
        </div>
    );
};

export default TruncatedText;