import React, { Component } from 'react';
import { Select } from 'antd';
import { connect } from 'react-redux';
import { searchFieldChanged } from '../../../actions/SearchActions';

const { Option } = Select;

class GenderFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gender: this.props.gender
        }

        this.handleChange = this.handleChange.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props) {
            this.setState({
                gender: nextProps.gender
            });
        }
    }

    handleChange(value) {
        if (value === undefined || value === "") {
            this.props.searchFieldChanged({ prop: "gender", value: null });
        } else {
            this.props.searchFieldChanged({ prop: "gender", value });
        }
    };

    render() {
        const { gender } = this.state;

        return (
            <div>
                <p style={{ color: "#000", fontFamily: "myriadBold", marginBottom: 3, textAlign: "left" }}>Gender</p>
                <Select value={gender} onChange={this.handleChange} allowClear placeholder="Gender" style={{ width: "100%", borderColor: '#000' }}>
                    <Option value={"Male"}>Male</Option>
                    <Option value={"Female"}>Female</Option>
                </Select>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { gender } = state.search;

    return {
        gender
    };
};

const mapDispatchToProps = {
    searchFieldChanged, // Assuming you have an action creator to update the status
};

export default connect(mapStateToProps, mapDispatchToProps)(GenderFilter);
