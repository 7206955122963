// import React from 'react';
// import { Input, AutoComplete } from 'antd';
// import { connect } from 'react-redux';
// import { searchFieldChanged, fetchEducationIndustry } from '../../../actions/SearchActions';

// class IndustryFilter extends React.Component {
//     constructor(props) {
//         super(props);

//         this.state = {
//             educationIndustriesSearchQuery: this.props.educationIndustriesSearchQuery,
//             educationIndustries: this.props.educationIndustries
//         };
//     }

//     componentWillReceiveProps(nextProps, nextContext) {
//         if (nextProps !== this.props) {
//             this.setState({
//                 educationIndustriesSearchQuery: nextProps.educationIndustriesSearchQuery,
//                 educationIndustries: nextProps.educationIndustries
//             });
//         }
//     }

//     handleSearch = (value) => {
//         this.props.searchFieldChanged({ prop: "educationIndustriesSearchQuery", value });
//         this.props.fetchEducationIndustry({ searchQuery: value });
//     };

//     handleSelect = (value, option) => {
//         const { objectID } = option.data;
//         this.props.searchFieldChanged({ prop: "educationIndustriesSearchQuery", value });
//         this.props.searchFieldChanged({ prop: "industryID", value: objectID });
//     };

//     render() {
//         const { educationIndustriesSearchQuery, educationIndustries } = this.state;

//         return (
//             <div>
//                 <p style={{ color: "#000", fontFamily: "myriadBold", marginBottom: 3, textAlign: "left" }}>Education Industry</p>
//                 <AutoComplete
//                     value={educationIndustriesSearchQuery}
//                     onChange={this.handleSearch}
//                     onSelect={this.handleSelect}
//                     options={educationIndustries.map((result) => ({
//                         value: result.title,
//                         label: result.title,
//                         data: {
//                             title: result.title,
//                             objectID: result.objectID,
//                         },
//                     }))} // Convert search results to options format
//                     style={{ width: "100%", borderColor: '#000' }}
//                 >
//                     <Input.Search placeholder="Search Education Industry" />
//                 </AutoComplete>
//             </div>
//         );
//     }
// }

// const mapStateToProps = (state) => {
//     const { educationIndustriesSearchQuery, educationIndustries } = state.search;
//     return {
//         educationIndustriesSearchQuery, educationIndustries
//     };
// };

// const mapDispatchToProps = {
//     searchFieldChanged, fetchEducationIndustry,
// };

// export default connect(mapStateToProps, mapDispatchToProps)(IndustryFilter);


import React from "react";
import { connect } from "react-redux";
import {message, Select} from "antd";

// Action imports
import { searchFieldChanged, fetchEducationIndustry } from "../../../actions/SearchActions";

//property declarations
const { Option } = Select;

class IndustryFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            industryID: this.props.industryID,
            educationIndustries: this.props.educationIndustries,
        }

        this.handleScheduleChange = this.handleScheduleChange.bind(this);
    }

    componentDidMount() {
        this.props.fetchEducationIndustry();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props) {
            this.setState({
                industryID: nextProps.industryID,
                educationIndustries: nextProps.educationIndustries
            });
        }
    }

    handleScheduleChange = (selectedSchedules) => {
        if (selectedSchedules.length === 0) {
            // Dispatch an action to set skill to null
            this.props.searchFieldChanged({ prop: "industryID", value: null });
        } else {
            // Otherwise, proceed as usual
            this.props.searchFieldChanged({ prop: "industryID", value: selectedSchedules });
        }
    };


    render() {
        const { industryID, educationIndustries } = this.state;

        return (
            <div>
                <p style={{ color: "#000", fontFamily: "myriadBold", marginBottom: 3, textAlign: "left" }}>Education Industry</p>
                <Select
                    mode="multiple"
                    placeholder="Search Education Industry"
                    value={industryID}
                    allowClear
                    onChange={this.handleScheduleChange}
                    style={{ width: "100%", borderColor: '#000' }}
                    optionFilterProp="label"
                    filterOption={(input, option) =>
                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {educationIndustries.map((option) => (
                        <Option key={option.objectID} value={option.objectID} label={option.title}>
                            {option.title}
                        </Option>
                    ))}
                </Select>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { educationIndustries, industryID } = state.search;

    return {
        educationIndustries,
        industryID
    };
};

export default connect(mapStateToProps, { searchFieldChanged, fetchEducationIndustry })(IndustryFilter);
