import firebase from "firebase";

//Types import
import {
    FETCH_SYSTEM_INFO, FETCH_SYSTEM_INFO_EMPTY,
    FETCH_SYSTEM_INFO_FAILED,
    FETCH_SYSTEM_INFO_SUCCESSFUL
} from "./Types";


export const fetchSystemInfo = () => {
  //
    return (dispatch) => {

        dispatch({ type: FETCH_SYSTEM_INFO });

        firebase.firestore().collection("system").doc("data")
            .onSnapshot(function (doc) {
                if (doc.exists) {
                    const data = doc.data();

                    dispatch({
                        type: FETCH_SYSTEM_INFO_SUCCESSFUL,
                        payload: data
                    });
                } else {
                    console.log("system info doc doesn't exists");
                    dispatch({ type: FETCH_SYSTEM_INFO_EMPTY });
                }
            }, function (e) {
                console.log("error fetching system info doc");
                console.log(e);
                dispatch({ type: FETCH_SYSTEM_INFO_FAILED });
            })
    }
};