import React from 'react';
import {Select} from "antd";

export class TicketStatusPicker extends React.Component {

    render() {
        return (
            <div className={"inputTypeSelect"}>
                <div>
                    <p>{this.props.label}</p>
                </div>
                <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder={this.props.placeholder}
                    optionFilterProp="children"
                    onChange={this.props.onChange}
                    value={this.props.value}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    <option value="open">Open</option>
                    <option value="closed">Closed</option>
                </Select>
            </div>
        );
    }
}