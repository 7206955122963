import React from 'react';
import {connect} from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import moment from 'moment';
import {Button, Modal} from "antd";

//actions import
import { postsFieldChanged } from "../../../actions/PostsActions";

//components import
import InviteForInterview from "./InviteForInterview";
import {Spinner} from "../../common/Spinner";
import SelectCandidate from "./SelectCandidate";


class Summary extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedCandidate: this.props.selectedCandidate,
            selectedCandidateProfile: this.props.selectedCandidateProfile,
            selectedPost: this.props.selectedPost,
            education: this.props.education,
            educationLoading: this.props.educationLoading,

            selectedCandidateLoading: this.props.selectedCandidateLoading,
            selectedCandidateProfileLoading: this.props.selectedCandidateProfileLoading,

            profile: this.props.profile,
            inviteVisible: this.props.inviteVisible,
            selectionVisible: this.props.selectionVisible,
        };

        this.showModal = this.showModal.bind(this);
        this.showSelectionModal = this.showSelectionModal.bind(this);
        this.handleOk = this.handleOk.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }



    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps !== this.props) {
            this.setState({
                selectedCandidate: nextProps.selectedCandidate,
                selectedCandidateProfile: nextProps.selectedCandidateProfile,
                selectedCandidateLoading: nextProps.selectedCandidateLoading,
                selectedCandidateProfileLoading: nextProps.selectedCandidateProfileLoading,
                selectedPost: nextProps.selectedPost,
                education: nextProps.education,
                educationLoading: nextProps.educationLoading,
                profile: nextProps.profile,
                inviteVisible: nextProps.inviteVisible,
                selectionVisible: nextProps.selectionVisible,
            })
        }
    }

    showModal() {
        this.props.postsFieldChanged({ prop: "inviteVisible", value: true });
    };

    showSelectionModal() {
        this.props.postsFieldChanged({ prop: "selectionVisible", value: true });
    };

    handleOk() {
        this.props.postsFieldChanged({ prop: "inviteVisible", value: false });
        this.props.postsFieldChanged({ prop: "selectionVisible", value: false });
    };

    handleCancel(){
        this.props.postsFieldChanged({ prop: "inviteVisible", value: false });
        this.props.postsFieldChanged({ prop: "selectionVisible", value: false });
    };

    checkUser() {
        //extract state
        let { profile, selectedCandidate, selectedCandidateLoading, selectedCandidateProfile, selectedCandidateProfileLoading, selectedPost, education, educationLoading } = this.state;

        if (selectedCandidateLoading || selectedCandidateProfileLoading ) {
            return <Spinner/>
        } else {
            if(!(_.isEmpty(selectedCandidate)) && !(_.isEmpty(selectedCandidateProfile))) {

                const {
                    acceptedInterviews, birthdate, email, firstName, gender, interviewRequests, lastName, location, maritalStatus,
                    phone, rejectedInterviews, openForRelocation, workSchedule, minimumPay, desiredJobTitle, jobType } = selectedCandidate;

                const { summary } = selectedCandidateProfile;

                //show foreign or local currency
                let currency;
                switch (location.countryCode) {
                    case "NG":
                        currency = "NGN";
                        break;
                    case "TZ":
                        currency = "TSH";
                        break
                    default: 
                        currency = "USD";
                        break;
                }

                let seconds;
                birthdate.seconds ? seconds = birthdate.seconds : seconds = birthdate._seconds;
                const birthday = moment.unix(seconds);
                const today = moment();
                const age = today.diff(birthday, "years");

                //compute the number of days
                const birthdayString = birthday.format('DD MMM YYYY');

                let indSentence = "None";
                if (education.length !== 0) {
                    const edIndustry = education.map(ed => `${ed.industry.title},`);
                    indSentence = edIndustry.slice(0, -1).join(", ") + " " + edIndustry[edIndustry.length - 1];
                }

                let scheduleSentence = "None";
                if (workSchedule) {
                    const schedule = workSchedule.map(work => `${work},`);
                    scheduleSentence = schedule.slice(0, -1).join(", ") + " " + schedule[schedule.length - 1];
                }

                let types = "None";
                if (jobType) {
                    const typesArray = jobType.map(type => `${type},`);
                    types = typesArray.slice(0, -1).join(", ") + " " + typesArray[typesArray.length - 1];
                }

                if(!(_.isEmpty(profile))) {
                    if (profile.role === "admin" || profile.role === "superAdmin") {

                        return(
                            <div className={"registerEmployeeContainer"}>
                                <div className={"newEmployeeHeader"}>
                                    {
                                        selectedPost ?
                                            <Button type="default" onClick={() => this.showSelectionModal()} style={{ width: "20rem", margin: "1rem" }}>
                                                <span style={{ marginTop: "0.05rem", fontFamily: "ramblaRegular"}}>Select Candidate for Vacant Post</span>
                                            </Button> :
                                            <Button type="default" onClick={() => this.showModal()} style={{ width: "20rem", margin: "1rem" }}>
                                                <span style={{ marginTop: "0.05rem", fontFamily: "ramblaRegular"}}>Invite for Interview</span>
                                            </Button>
                                    }
                                </div>
  
                                <div className="profile-container">
                                    <div className="profile-header">    
                                        <div className={"seekerInfo"}>
                                            <FontAwesomeIcon icon="user-circle" size="4x" color='black'/>
                                            <h3>{firstName} {lastName}</h3>
                                        </div>
                                            <p>{summary ? summary : "No Summary"}</p>
                                    </div>
                                    
                                    <div className="profile-details">
                                        <h4 style={{ color: "#056899" }}>Personal Details</h4>
                                            <p>Email: {email} </p>
                                            <p>Phone: {phone}</p>
                                            <p>Martial Status: {maritalStatus ? maritalStatus : "None"}</p>
                                            <p>Gender: {gender}</p>
                                            <p>Birth date:{birthdayString} <span style={{ color: "#056899" }}>({age})</span></p>

                                            <p>Open For Relocation{openForRelocation ? "Yes" : "No"}</p>
                                            <h4 style={{ color: "#056899" }}> Location</h4>
                                            <p>{`${location.country}, ${location.region}`}</p>
                                            <p>{`${location.postalCode}, ${location.streetAddress}`}</p>
                                    
                                    </div>

                                    <div className="profile-details">
                                        <h4 style={{ color: "#056899" }}>Compensation</h4>
                                        <p>Desired Pay:{currency}. {minimumPay ? minimumPay.toLocaleString() : "None" }</p>
                                        <p>Desired Job Position:{desiredJobTitle ? desiredJobTitle.title : "None"}</p>
                                        <p>Industries:{educationLoading ?<Spinner/>: <span>{indSentence.length === 0 ? "None" : indSentence}</span>}</p>
                                        <p>Interested Job Types: {types}</p>       
                                        <p>Availability: {scheduleSentence}</p> 

                                        <h4 style={{ color: "#056899" }}> Interviews</h4>
                                        <p>Accepted Interview Offers:{acceptedInterviews.toLocaleString()}</p>
                                        <p>Accepted Interview Offers:{rejectedInterviews.toLocaleString()}</p>
                                        <p style={{ color: "#056899" }}>Received Interview Offers: {interviewRequests.toLocaleString()}</p>
                                           
                                    </div>

                                </div>

                                    {/* <div>
                                        <h4>Personal Details</h4>
                                    </div>
                                    <div className={"newEmpPersonalInfo"}>
                                        <div className={"newEmpId"}>
                                            <p style={{ color: "#056899" }}>Summary</p>
                                            <p>{summary ? summary : "No Summary"}</p>
                                        </div>
                                        <div className={"newEmpDOB"}>
                                            <p style={{ color: "#056899" }}>Email</p>
                                            <p>{email}</p>
                                        </div>
                                        <div className={"newEmpGender"}>
                                            <p style={{ color: "#056899" }}>Phone</p>
                                            <p>{phone}</p>
                                        </div>
                                        <div className={"newEmpMarital"}>
                                            <p style={{ color: "#056899" }}>Marital Status</p>
                                            <p>{maritalStatus ? maritalStatus : "None"}</p>
                                        </div>
                                        <div className={"newEmpMaritalDate"}>
                                            <p style={{ color: "#056899" }}>Gender</p>
                                            <p>{gender}</p>
                                        </div>
                                        <div className={"newEmpNida"}>
                                            <p style={{ color: "#056899" }}>Birthdate</p>
                                            <p>{birthdayString} <span style={{ color: "#056899" }}>({age})</span></p>
                                        </div>
                                        <div className={"newEmpSSN"}>
                                            <p style={{ color: "#056899" }}>Current Location</p>
                                            <p>{`${location.country}, ${location.region}`}</p>
                                            <p>{`${location.postalCode}, ${location.streetAddress}`}</p>
                                        </div>
                                        <div className={"newEmpAddress"}>
                                            <p style={{ color: "#056899" }}>Open for Relocation</p>
                                            <p>{openForRelocation ? "Yes" : "No"}</p>
                                        </div>
                                    </div>

                                    <div>
                                        <h4>Compensation</h4>
                                    </div>
                                    <div className={"newEmpCompensationInfo"}>
                                        <div className={"newEmpPayRate"}>
                                            <p style={{ color: "#056899" }}>Desired Pay</p>
                                            <p>{currency}. {minimumPay ? minimumPay.toLocaleString() : "None" }</p>
                                        </div>
                                        <div className={"newEmpPer"}>
                                            <p style={{ color: "#056899" }}>Desired Job Position</p>
                                            <p>{desiredJobTitle ? desiredJobTitle.title : "None"}</p>
                                        </div>
                                        <div className={"newEmpPayType"}>
                                            <p style={{ color: "#056899" }}>Industries</p>
                                            {
                                                educationLoading ?
                                                    <Spinner/>
                                                    :
                                                    <p>{indSentence.length === 0 ? "None" : indSentence}</p>
                                            }
                                        </div>
                                        <div className={"newEmpPaySchedule"}>
                                            <p style={{ color: "#056899" }}>Interested Job Types</p>
                                            <p>{types}</p>
                                        </div>
                                        <div className={"newEmpEffectiveDate"}>
                                            <p style={{ color: "#056899" }}>Availability</p>
                                            <p>{scheduleSentence}</p>
                                        </div>
                                    </div>


                                    <div>
                                        <h4>Interviews</h4>
                                    </div> */}
                                    {/* <div className={"newEmpContactsInfo"}>
                                        <div className={"newEmpPhone"}>
                                            <p style={{ color: "#056899" }}>Accepted Interview Offers</p>
                                            <p>{acceptedInterviews.toLocaleString()}</p>
                                        </div>
                                        <div className={"newEmpHomePhone"}>
                                            <p style={{ color: "#056899" }}>Rejected Interview Offers</p>
                                            <p>{rejectedInterviews.toLocaleString()}</p>
                                        </div> */}
                                        {/*<div className={"newEmpWorkEmail"}>*/}

                                        {/*</div>*/}
                                        {/*<div  className={"newEmpPersonalEmail"}>*/}

                                        {/*</div>*/}
                                        {/* <div className={"newEmpLinkedinUrl"}>
                                            <p style={{ color: "#056899" }}>Received Interview Offers</p>
                                            <p>{interviewRequests.toLocaleString()}</p>
                                        </div>
                                    </div> */}
                               
                                <Modal
                                    title="Invite for Interview"
                                    open={this.state.inviteVisible}
                                    onOk={this.handleOk}
                                    onCancel={this.handleCancel}
                                    footer={null}
                                    width={900}
                                >
                                    <div>
                                        <InviteForInterview />
                                    </div>
                                </Modal>

                                <Modal
                                    title={`Select Candidate for ${selectedPost.postTitle} Post`}
                                    open={this.state.selectionVisible}
                                    onOk={this.handleOk}
                                    onCancel={this.handleCancel}
                                    footer={null}
                                    width={900}
                                >
                                    <div>
                                        <SelectCandidate />
                                    </div>
                                </Modal>
                            </div>
                        );
                    } else {
                        return <h3>Un-Authorized Access</h3>;
                    }
                }
            } else {
                return <h2 style={{ color: "#fff" }}>Select a candidate to view</h2>;
            }
        }
    }

    render() {
        return (
            <div>
                { this.checkUser() }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { selectedCandidate, selectedCandidateLoading, selectedCandidateProfile, selectedCandidateProfileLoading, inviteVisible, selectionVisible, selectedPost,
        education, educationLoading } = state.posts;
    const { profile } = state.profile;

    return {
        profile,
        selectedCandidate,
        selectedCandidateLoading,
        selectedCandidateProfile,
        selectedCandidateProfileLoading,
        inviteVisible,
        selectionVisible,
        selectedPost,
        education,
        educationLoading
    };
};

export default connect(mapStateToProps, { postsFieldChanged })(Summary);