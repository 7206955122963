import React, {useEffect, useState} from 'react';
import { Table, Button, Modal, message, Popconfirm } from 'antd';
import { connect, useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SecurityScanFilled } from '@ant-design/icons';
import { Spinner } from '../../common/Spinner';
import TruncatedText from '../modals/TruncatedText';
import TruncatedExperience from '../modals/TruncatedExperience';
import {useNavigate} from "react-router-dom";
import moment from 'moment';

import { addToShortlist, recentViewSeeker } from "../../../actions/SearchActions";
import { postsFieldChanged, pushSeeker } from "../../../actions/PostsActions";
import CustomHeaderCard from './CustomHeaderCard';
import { Add01Icon, AddSquareIcon, MessageNotification01Icon } from 'hugeicons-react';
import SelectPost from '../../shortlist/subComponents/SelectPost';


const ResultCard = ({ results, profile, shortlistLoading, addToShortlist, pushSeeker, seekerHighlights }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedSeeker, setSelectedSeeker] = useState(null);
    const [isPopoverVisible, setPopoverVisible] = useState(false);

    const [state, setState] = useState({
        post: "",
        profile:{}
        
    });

   //extract app state
   const postState = useSelector((state) => state.posts);
   const profileState = useSelector((state) => state.profile);

   console.log('Post', state.post)


   useEffect(() => {
       try {
           // Set initial state when the component mounts
           // Update state whenever dataFromRedux changes
           setState(prevState => ({
               ...prevState,
               post: postState.post,
               profile:profileState.profile
              
           }));

       } catch (e) {
           console.error("error in updating make payment");
       }
   }, [postState, profileState]);


   console.log('Profile', state.profile)


    const handleShortlist = (seeker, isCoverLetterRequested, shortlistInfo) => {



        console.log('facts', seeker)
        addToShortlist({ seeker, isCoverLetterRequested, shortlistInfo });
        message.success('Added to shortlist');
        setIsModalVisible(false); // Close the modal after shortlisting
    };

  
   const onSelectPost =(value)=> {
        dispatch(postsFieldChanged({prop: 'post', value }))
    }


    const onSelectCandidate = (seeker) => {
        if ("paid" in profile) {
            if (profile.paid) {
                pushSeeker({ pushedSeeker: seeker });
                dispatch(recentViewSeeker(seeker));
                navigate("/seeker-profile");
            } else {
                message.info("Upgrade your package to view candidate").then(() => null);
            }
        } else {
            message.info("Upgrade your package to view candidate").then(() => null);
        }
    };

    const showModal = (record) => {
        setSelectedSeeker(record);
        setIsModalVisible(true);
    };

    const justShortlist = (record) => {

      
        setSelectedSeeker(record);

        const isCoverLetterRequested = false;
        const shortlistInfo =  {};
        
        handleShortlist(record, isCoverLetterRequested, shortlistInfo)
       
    };

    const shortListAndRequestCoverLetter = (record) => {
        setSelectedSeeker(record);
         
        const shortlistInfo = {}
        const isCoverLetterRequested = true;
        shortlistInfo.companyName = state.profile.companyName;
        shortlistInfo.adminName = state.profile.firstName;
        shortlistInfo.post = state.post.label;
        shortlistInfo.isCoverLetterSent = false;
        shortlistInfo.adminID = state.profile.userID;

        console.log('the post', shortlistInfo)
        
        handleShortlist(record, isCoverLetterRequested, shortlistInfo)
       
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
     
    

    let mapping = {};
    seekerHighlights.forEach(item1 => {
        if (!mapping[item1.seekerID]) {
            mapping[item1.seekerID] = {...item1 };
        }
    });

    const newResults = results.map(item2 => {
        const item1 = mapping[item2.seekerID];
        let highlights = '';
        if (item1 && item1.shortlist) {
            highlights = 'Shorlisted';
        } else if (item1 && item1.recentShortlist) {
            highlights = 'Recent Shorlisted';
        } else if (item1 && item1.recentView) {
            highlights = 'Recent View';
        }
        return {...item2, highlights };
    });

    // console.log('newResults', newResults);

    let processedResults = newResults.map(result => {
        let seconds;
        result.birthdate.seconds? (seconds = result.birthdate.seconds) : (seconds = result.birthdate._seconds);
        
        const birthday = moment.unix(seconds);
        const today = moment();
        const age = today.diff(birthday, "years");
        
        const name = (result.firstName + " " + result.lastName).toUpperCase();
        const acceptanceRate = result.acceptanceRate;
        const responseRate = result.responseRate;
        const showUpRate = result.showUpRate;

        
        const formattedLocation = `${result.location.country}, ${result.location.region}`;

        let color = "#000";
        switch (result.packageIndex) {
            case 1:
                color = "#228b22";
                break;
            case 2:
                color = "#0793d9";
                break;
            default:
                break;
        }
        
        let indSentence = "";
        if (result.education.length!== 0) {
            const edIndustry = result.education.map((ind) => `${ind},`);
            indSentence = edIndustry.slice(0, -1).join(", ") + " " + edIndustry[edIndustry.length - 1];
        }
        
        return { ...result, indSentence, formattedLocation, name, age, color, acceptanceRate, showUpRate, responseRate};
    });


    const columns = [
        {
            title: <CustomHeaderCard>Rank</CustomHeaderCard>,
            dataIndex: 'rank',
            key: 'rank',
            render: (_, record) => (
                <div style={{ fontSize: '1rem', color: record.color }}>
                    <SecurityScanFilled style={{ fontSize: '1rem', color: record.color }} />
                </div>
            ),
        },
        {
            title: <CustomHeaderCard>Name</CustomHeaderCard>,
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => (
                <div>
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: record.color }}>{record.name}</div>
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: 'blue' }}>{record.highlights.toUpperCase()}</div>
                </div>
            ),
        },
        {
            title: <CustomHeaderCard>Gender</CustomHeaderCard>,
            dataIndex: 'gender',
            key: 'gender',
            render: (_, record) => (
                <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: record.color }}>{record.gender}</div>
            ),
        },
        {
            title: <CustomHeaderCard>Age</CustomHeaderCard>,
            dataIndex: 'age',
            key: 'age',
            render: (_, record) => (
                <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: record.color }}>{record.age}</div>
            ),
        },
        {
            title: <CustomHeaderCard>Location</CustomHeaderCard>,
            dataIndex: 'formattedLocation',
            key: 'formattedLocation',
            render: (_, record) => (
                <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: record.color }}>{record.formattedLocation}</div>
            ),
        },
        {
            title: <CustomHeaderCard>Experience</CustomHeaderCard>,
            dataIndex: 'experience',
            key: 'experience',
            render: (_, record) => (
                <div style={{
                    fontSize: '0.9rem',
                    height: '23px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                }}>
                    <TruncatedExperience seeker={record} maxLength={60}/>
                </div>
            ),
        },
        {
            title: <CustomHeaderCard>Industries</CustomHeaderCard>,
            dataIndex: 'indSentence',
            key: 'indSentence',
            render: (_, record) => (
                <div style={{
                    fontSize: '0.9rem',
                    height: '23px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                }}>
                    <TruncatedText text={`${record.indSentence}`} maxLength={25}/>
                </div>
            ),
        },
        {
            title: <CustomHeaderCard>Response Rate</CustomHeaderCard>,
            dataIndex: 'responseRate',
            key: 'responseRate',
            render: (_, record) => (
                <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: record.color }}>{record.responseRate}<span>%</span> </div>
            ),
        },
        {
            title: <CustomHeaderCard>Show-Up Rate</CustomHeaderCard>,
            dataIndex: 'showUpRate',
            key: 'showUpRate',
            render: (_, record) => (
                <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: record.color }}>{record.showUpRate}<span>%</span> </div>
            ),
        },
        {
            title: <CustomHeaderCard>Acceptance Rate</CustomHeaderCard>,
            dataIndex: 'acceptanceRate',
            key: 'acceptanceRate',
            render: (_, record) => (
                <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: record.color }}>{record.acceptanceRate}<span>%</span> </div>
            ),
        },
        {
            title: <CustomHeaderCard>Shortlist</CustomHeaderCard>,
            dataIndex: 'shortlist',
            key: 'shortlist',
            render: (_, record) => (

                // <FontAwesomeIcon icon="eye" color="#3294ff" size="1x" onClick={() => showModal(record)} style={{ cursor: "pointer" }} />
             

               <Popconfirm
               title={
                "Shortlisting this candidate will add them to your shortlist where you can make quick follow up on them"
               }
  
            
  
              onConfirm={() => {

                showModal(record)  
              }}
                
              onCancel={() => {

                justShortlist(record)
                 
              }}
              okText="Shortlist & Request Cover letter"
              cancelText="Just Shortlist"
              overlayStyle={{ width: "400px" }}
             
            >
                <AddSquareIcon  color="#3294ff"  size={30} style={{ cursor: "pointer" }}  />    
          </Popconfirm>
     
            ),
        },
        {
            title: <CustomHeaderCard>Review</CustomHeaderCard>,
            dataIndex: 'review',
            key: 'review',
            render: (_, record) => (
                <FontAwesomeIcon icon="user" color="#3294ff" size="1x" onClick={() => onSelectCandidate(record)} style={{ cursor: "pointer" }} />
            ),
        }
    ];

  
 

    return (
        <>
            <Table dataSource={processedResults} columns={columns} rowKey="id" />
            {selectedSeeker && (
            <Modal
                title={` Let ${selectedSeeker.firstName} know you're shorlisting them for which job post`}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
            >

                    <div className={"ticketModalDetails"}>
                        <SelectPost
                            placeholder={'Select post to interview for'}
                            label={"Pick Post"}
                            value={state.post}
                            onChange={onSelectPost}
                        />
                    </div>

                <div className={"ticketModalDetails"}>
                    {shortlistLoading? <Spinner /> : 
                        state.post && <Button type="primary" onClick={() => shortListAndRequestCoverLetter(selectedSeeker)} disabled={!state.post}>
                            Shortlist {selectedSeeker.firstName}
                        </Button>
                    }
                </div>
            </Modal>
             )}
        
        </>
    );
};

export default connect(null, { addToShortlist, pushSeeker })(ResultCard);