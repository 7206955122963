import React from 'react';
import {connect} from 'react-redux';
import _ from "lodash";
import { Table, Popconfirm, Modal, message } from 'antd';
import { CheckmarkBadge03Icon, PropertyDeleteIcon } from "hugeicons-react";


//actions import
import {assetDetailsChanged, addCompanyAsset, deleteCompanyAsset} from "../../../actions/SettingsActions";


//components import
import {SelectAssetsCategory} from "../../common/SelectAssetsCategory";
import {Input} from "../../common/Input";
import {Button} from "../../common/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ClipLoader} from "react-spinners";
import CustomHeaderCard from '../../search/cards/CustomHeaderCard';


class AddAssets extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            profile: this.props.profile,
            assets: this.props.assets,
            assetCategory: this.props.assetCategory,
            assetTitle: this.props.assetTitle,

            saveAssetLoading: this.props.saveAssetLoading,
            saveAssetSuccessful: this.props.saveAssetSuccessful,
            saveAssetFailed: this.props.saveAssetFailed,
            deleteAssetLoading: this.props.deleteAssetLoading,
            deleteAssetSuccessful: this.props.deleteAssetSuccessful,
            deleteAssetFailed: this.props.deleteAssetFailed,
        }

        this.onSelectAssetCategory = this.onSelectAssetCategory.bind(this);
        this.renderAssets = this.renderAssets.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }


    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps !== this.props) {
            this.setState({
                profile: nextProps.profile,
                assets: nextProps.assets,
                assetCategory: nextProps.assetCategory,
                assetTitle: nextProps.assetTitle,

                saveAssetLoading: nextProps.saveAssetLoading,
                saveAssetSuccessful: nextProps.saveAssetSuccessful,
                saveAssetFailed: nextProps.saveAssetFailed,
                deleteAssetLoading: nextProps.deleteAssetLoading,
                deleteAssetSuccessful: nextProps.deleteAssetSuccessful,
                deleteAssetFailed: nextProps.deleteAssetFailed
            })
        }
    }

    renderDeleteSuccessMessage() {
        const {deleteAssetSuccessful} = this.state;
        //
        if (deleteAssetSuccessful){
            return <p>{message.success("Asset is deleted successfully")}</p>
        } else {
            return null;
        }
    }

    renderDeleteFailedMessage() {
        const {deleteAssetFailed} = this.state;
        //
        if (deleteAssetFailed){
            return <p>{message.error("Asset is not deleted")}</p>
        } else {
            return null;
        }
    }

    onDelete(asset) {
        const assetID = asset.ID;

        this.props.deleteCompanyAsset({ assetID });
    }

    onCancel() {
        message.info("Deletion is canceled");
    }

    renderAssets() {
        const {assets} = this.state;

        const assetsArray = _.map(assets, asset => {
            return asset
        });

        const columns = [
            {
                title: <CustomHeaderCard>Category</CustomHeaderCard>,
                dataIndex: 'list',
                key: 'list',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: record.color }}>{record.list}</div>
                ),
            },
            {
                title: <CustomHeaderCard>Title</CustomHeaderCard>,
                dataIndex: 'title',
                key: 'title',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: record.color }}>{record.title}</div>
                ),
            },
            {
                title: <CustomHeaderCard>Delete</CustomHeaderCard>,
                dataIndex: 'remove',
                key: 'remove',
                render: (_, record) => (
                    <Popconfirm
                        title="Are you sure to delete this asset?"
                        onConfirm={() => this.onDelete(record)}
                        onCancel={() => this.onCancel()}
                        okText="Yes"
                        cancelText="No"
                    >
                        <PropertyDeleteIcon color="red" size={20} style={{cursor: 'pointer'}} />
                    </Popconfirm>
                ),
            },
        ];

        return (
            <Table dataSource={assetsArray} columns={columns} rowKey="id" />
        );
    }

    renderSuccessMessage() {
        const {saveAssetSuccessful} = this.state;
        //
        if (saveAssetSuccessful){
            return <p>{message.success("Asset is saved successfully")}</p>
        }
    }

    renderFailedMessage() {
        const {saveAssetFailed} = this.state;
        //
        if (saveAssetFailed){
            return <p>{message.error("Asset is not saved")}</p>
        }
    }

    onSelectAssetCategory(value) {
        this.props.assetDetailsChanged({prop: 'assetCategory', value})
    }

    onSave() {
        //-> extract state
        const { profile } = this.state;
        const {assetCategory, assetTitle } = this.state;

        if (!(_.isEmpty(profile))) {
            if (assetCategory && assetTitle) {
                this.props.addCompanyAsset({assetCategory, assetTitle})
            } else {
                message.warning("Fill in all the fields");
            }
        }
    }

    renderButton() {
        const {saveAssetLoading}  = this.state;

        if (saveAssetLoading) {
            return (
                <div style={{align: 'center', marginLeft: 330,  }}>
                    <ClipLoader
                        size={20}
                    />
                </div>
            );
        } else {
            return  (
                <div className={"buttonBox"} style={{ marginTop:"3rem" }}>
                    <Button
                        onPress={() => this.onSave()}
                        text={'Save'}
                    />
                </div>
            );
        }
    }



    checkUser() {
        //extract state
        const { profile } = this.state;

        if(!(_.isEmpty(profile))) {
            if (profile.role === "superAdmin") {
                return (
                    <div>
                        <div className={"settingsAssetsBox"}>
                            <div className={"addAssetsSettingsBox"}>
                                <h4 style={{ fontFamily: "ramblaBold", color: "black" }}>Assets</h4>

                                <div className={"addAssetsInfoBox"}>
                                    <div>
                                        <SelectAssetsCategory
                                            label={"Category"}
                                            onChange={this.onSelectAssetCategory}
                                            value={this.state.assetCategory}
                                        />
                                    </div>
                                    <div >
                                        <Input
                                            label={"Title"}
                                            type={'text'}
                                            onChange={e => this.props.assetDetailsChanged({prop: 'assetTitle', value: e.target.value})}
                                            value={this.state.assetTitle}
                                        />
                                    </div>

                                    {this.renderButton()}
                                    {this.renderSuccessMessage()}
                                    {this.renderFailedMessage()}
                                </div>
                            </div>
                        </div>
                        <br/>
                        <div className={"infoBox"}>
                            <div className={"assetsDetailsBox"}>
                                <div>
                                    {this.renderAssets()}
                                    {this.renderDeleteSuccessMessage()}
                                    {this.renderDeleteFailedMessage()}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        }
    }

    render() {

        return (
            <div>
                { this.checkUser() }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    //-> extract state
    const { profile } = state.profile;
    const {assets, assetCategory, assetTitle, saveAssetLoading, saveAssetSuccessful, saveAssetFailed, deleteAssetLoading, deleteAssetSuccessful, deleteAssetFailed, } = state.settings;

    return{
        profile,
        assets,
        assetCategory,
        assetTitle,

        saveAssetLoading,
        saveAssetSuccessful,
        saveAssetFailed,
        deleteAssetLoading,
        deleteAssetSuccessful,
        deleteAssetFailed,
    }
}

export default connect(mapStateToProps, {assetDetailsChanged, addCompanyAsset, deleteCompanyAsset })(AddAssets);