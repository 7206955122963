import React from 'react';
import { connect } from 'react-redux';
import firebase from "firebase";
import { Form, Input, Button, Tooltip, message } from 'antd';
import { GoogleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import {fillWarning, smileyIcon} from "../common/MessageAlertIcons";
import {isMobile} from 'react-device-detect';

//action imports
import { userSignUp } from "../../actions/AuthenticationActions";

//component imports
import ForgotPassword from "./subComponents/ForgotPassword";

//file imports
import Logo from "../../assets/logos/taglineLogo.png";
import AppleBadge from "../../assets/img/app-store-badge.svg";
import GoogleBadge from "../../assets/img/google-play-badge.svg";

//style imports
import './SignIn.css';


//property declarations

class SignInBase extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showPasswordRules: false,
            loading: this.props.loading
        };

    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.loading !== this.props.loading) {
            this.setState({ loading: nextProps.loading });
        }
    }

    validatePassword = (_, value) => {
        const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{6,}$/;
        if (!value) {
            return Promise.reject(new Error('Please input your password!'));
        } else if (!passwordRegex.test(value)) {
            return Promise.reject(new Error('Password must have at least 6 characters, one uppercase letter, and one special character.'));
        }
        return Promise.resolve();
    };

    handlePasswordFocus = () => {
        this.setState({ showPasswordRules: true });
    };

    handlePasswordBlur = () => {
        this.setState({ showPasswordRules: false });
    };

    handleSubmit = async (values) => {
        this.setState({loading: true});

        const { email, password } = values;
        const { navigate } = this.props;
        const there = this;
    
        if (email && password) {
            try {

                function sanitizeEmailForFirestore(e) {
                    const sanitizedEmail = e.replace(/[\/\.\.\_\~%]/g, '').substring(0, 1500);
                    return sanitizedEmail;
                }
                  
                  const userEmail = sanitizeEmailForFirestore(email);

                const docRef = firebase.firestore().collection('activeSessions').doc(userEmail);
                const doc = await docRef.get();

                // if (doc.exists) {
                //     this.setState({loading: false});
                //     message.success({
                //         content: "You are already logged in from another device. Please log out from there first.",
                //         duration: 3,
                //         icon: smileyIcon(),
                //     }).then(() => null);
                // } else {
                    firebase.auth().signInWithEmailAndPassword(email, password)
                    .then( async () => {
                        await docRef.set({ count: 1 });
                        navigate('/');
                        message.success({
                            content: "Yay, You are logged in!",
                            duration: 3,
                            icon: smileyIcon(),
                        }).then(() => null);
                     })
                    .catch((error) => {
                        there.setState({ loading: false });
                        console.log(error);
                        message.warning({
                            content: `${error.message}`,
                            duration: 3,
                            icon: fillWarning(),
                        }).then(() => null);
                    });
                // }
            } catch (error) {
                there.setState({loading: false});
                console.log(error);
                message.warning({
                    content: `${error}`,
                    duration: 3,
                    icon: fillWarning(),
                }).then(() => null);
            }
        } else {
            message.warning('Fill all required fields').then(() => null);
        }
    };

    // handleSubmit = (values) => {
    //     //-> handle login
    //     this.setState({loading: true});

    //     const { email, password } = values;
    //     const { navigate } = this.props;
    //     const there = this;


    //     //-> checking if fields are not empty
    //     if (email && password) {
    //         //sign in
    //         firebase.auth().signInWithEmailAndPassword(email, password)
    //             .then(() => {
    //                 //make sure to push to new page before reloading
    //                 navigate('/');
    //                 //window.location.reload();
    //                 message.success({
    //                     content: " Yay, You are logged in!",
    //                     duration: 3,
    //                     icon: smileyIcon(),
    //                 }).then(() => null);
    //             })
    //             .catch((error) => {
    //                 there.setState({loading: false});
    //                 console.log(error);
    //                 message.warning({
    //                     content: `${error}`,
    //                     duration: 3,
    //                     icon: fillWarning(),
    //                 }).then(() => null);
    //                 // getLoadingFalse();
    //             })
    //     } else {
    //         message.warning('Fill all required fields').then(() => null);
    //     }
    // }

    handleGoogleSignIn = async () => {
        try {
            //check that user already exists
            const { navigate } = this.props;

            const companyLocation = {
                country: '',
                countryCode: '',
                region: '',
                regionCode: '',
                streetAddress: '',
                postalCode: ''
            }
            //call login action from actions
            this.props.userSignUp({ email: '', password: '', firstName: '', lastName: '', phone: '', companyName: '', companyLocation, companyPhone: '', companyEmail: '', logo: '', navigate, google: true, login: true });
        } catch (error) {
            console.error('Google Sign-In Error:', error);
        }
    };

    render() {
        const { showPasswordRules } = this.state;

        if(isMobile) {
            return (
                <div className={"signup-container"}  >
                    <h3 className={"bold black"} style={{textAlign: "left",  color: "#fff"}}>For Organizations</h3>
                    <p style={{fontFamily: "ramblaBold", color: "#fff"}}>Complete registration from a computer,<br/>
                        <span
                            style={{color: "#0793d9" }}
                            onClick={() => navigate('/pricing')}
                        > go back</span>
                    </p>
                    <h4 className={"bold black"} style={{textAlign: "left",  color: "#fff", fontFamily: "ramblaBold"}}>For Job Applicants</h4>
                    <div style={{display:'flex', alignItems:'center'}}>

                        <a className="me-lg-3 mb-lg-0" href={"https://play.google.com/store/apps/details?id=com.nafasi.io"} target={"_blank"}>
                            <img
                                src={GoogleBadge}
                                alt="..." height={140} width={160}/>
                        </a>

                        <a href={"https://apps.apple.com/tz/app/nafasi/id6450264936"} target={"_blank"}>
                            <img
                                src={AppleBadge}
                                alt="..." height={140} width={160}/>

                        </a>
                    </div>

                </div>
            );
        } else {
            return (
                <div className={"signin-container"} style={{marginTop: "50px", marginBottom: '50px'}}>
                    <Form
                        name="login"
                        onFinish={this.handleSubmit}
                        className="signin-form"
                        scrollToFirstError
                    >
                        <img src={Logo} alt={"nafasi logo"}/>
                        <p className={"bold black"} style={{textAlign: "left"}}>For Organizations</p>

                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    type: 'email',
                                    message: 'The input is not valid E-mail!',
                                },
                                {
                                    required: true,
                                    message: 'Please input your E-mail!',
                                },
                            ]}
                        >
                            <Input placeholder="Email" className={"regular"}/>
                        </Form.Item>

                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                }
                            ]}
                        >
                            <Input.Password
                                placeholder="Password"
                                onFocus={this.handlePasswordFocus}
                                onBlur={this.handlePasswordBlur}
                                className={"regular"}
                            />
                        </Form.Item>

                        {showPasswordRules && (
                            <div style={{marginBottom: '16px', color: 'gray', fontFamily: "ramblaRegular"}}>
                                <Tooltip
                                    title="Password must have at least 6 characters, one uppercase letter, and one special character.">
                                    <InfoCircleOutlined style={{marginRight: '8px'}}/>
                                    Password Requirements
                                </Tooltip>
                            </div>
                        )}

                        <ForgotPassword/>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" className={"regular"} loading={this.state.loading}>
                                Sign In
                            </Button>
                            <Button type="default" icon={<GoogleOutlined/>} style={{marginLeft: 10}}
                                    className={"regular"} onClick={this.handleGoogleSignIn}>
                                Sign in with Google
                            </Button>
                        </Form.Item>

                        <Form.Item>
                            <Link to="/register">
                                <p type="link">Don't have an account? Register here</p>
                            </Link>

                        </Form.Item>
                        <div>
                            <div style={{borderTop: "1px solid silver", margin: "0 0 1rem 0"}}/>
                            <span style={{textAlign: "left", color: "black", fontSize: "14px", fontFamily: "ramblaBold"}}>For Job Applicants</span>

                            <div style={{display: 'flex', alignItems: 'center'}}>

                                <a className="me-lg-3 mb-lg-0"
                                   href={"https://play.google.com/store/apps/details?id=com.nafasi.io"}
                                   target={"_blank"}>
                                    <img
                                        src={GoogleBadge}
                                        alt="..." height={140} width={160}/>
                                </a>

                                <a href={"https://apps.apple.com/tz/app/nafasi/id6450264936"} target={"_blank"}>
                                    <img
                                        src={AppleBadge}
                                        alt="..." height={140} width={160}/>

                                </a>
                            </div>

                        </div>

                    </Form>

                </div>
            );
        }
    }
}

const mapStateToProps = (state) => {
    const { loading } = state.authentication;
    return {
        loading,

    }
};

// Wrapper functional component to use hooks
function SignIn(props) {
    const navigate = useNavigate();

    return <SignInBase {...props} navigate={navigate} />;
}

export default connect(mapStateToProps, { userSignUp })(SignIn);

// import React from 'react';
// import { connect } from 'react-redux';
// import {NavLink, useNavigate} from "react-router-dom";
// import firebase from "firebase";
// import {message} from "antd";
// // import 'antd/dist/antd.css';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { BarLoader } from 'react-spinners';
// import './SignIn.css';
//
// //-> env imports
//
// //-> action imports
// import { credentialsChanged, userLogin, getLoading, getLoadingFalse } from '../../actions/AuthenticationActions';
//
// //-> component imports
// import {Input} from '../common/Input';
// import {Button} from '../common/Button';
// import {fillWarning, smileyIcon} from "../common/MessageAlertIcons";
//
//
// //-> file imports
// import logo from '../../assets/logos/logo.png';
// import typographyLogo from '../../assets/images/typographyLogo.png';
// import backgroundImage from "../../assets/images/transparent/blue-flower.png";
// import ForgotPassword from "./subComponents/ForgotPassword";
//
//
// class SignInBase extends React.Component {
//
//     constructor(props) {
//         super(props);
//
//         this.state = {
//             email: this.props.email,
//             password: this.props.password,
//             privateID: this.props.privateID,
//
//             isPasswordVisible: false,
//
//             loading: false,
//         };
//
//         this.onLoginPress = this.onLoginPress.bind(this);
//         this.keyPressed = this.keyPressed.bind(this);
//         // this.onLogIn = this.onLogIn.bind(this)
//     };
//
//     componentWillReceiveProps(nextProps, nextContext) {
//         if (nextProps.email !== this.props.email) {
//             this.setState({ email: nextProps.email});
//         }
//
//         if (nextProps.password !== this.props.password) {
//             this.setState({ password: nextProps.password });
//         }
//         if (nextProps.privateID !== this.props.privateID) {
//             this.setState({ privateID: nextProps.privateID });
//         }
//         if (nextProps.loading !== this.props.loading) {
//             this.setState({ loading: nextProps.loading });
//         }
//
//     };
//
//     // onLogIn() {
//     //     const {navigate} = this.props;
//     //     this.props.userLogin(navigate);
//     //     this.props.userLogin(navigate);
//     // }
//
//     onLoginPress() {
//         //-> handle login
//         this.setState({loading: true});
//
//         const { email, password } = this.state;
//         const { navigate } = this.props;
//         const there = this;
//
//
//         //-> checking if fields are not empty
//         if (email && password) {
//             //sign in
//             firebase.auth().signInWithEmailAndPassword(email, password)
//                 .then(() => {
//                     //make sure to push to new page before reloading
//                     navigate('/');
//                     //window.location.reload();
//                     message.success({
//                         content: " Yay, You are logged in!",
//                         duration: 3,
//                         icon: smileyIcon(),
//                     }).then(() => null);
//                 })
//                 .catch((error) => {
//                     there.setState({loading: false});
//                     console.log(error);
//                     message.warning({
//                         content: `${error}`,
//                         duration: 3,
//                         icon: fillWarning(),
//                     }).then(() => null);
//                     // getLoadingFalse();
//                 })
//         } else {
//             message.warning('Fill all required fields').then(() => null);
//         }
//     }
//
//     keyPressed = (e) => {
//         //it triggers by pressing the enter key
//         if (e.key === 'Enter') {
//             this.onLoginPress();
//         }
//     };
//
//     renderLoginButton(){
//         const {loading} = this.state;
//
//         if(loading){
//             return (
//                 <div>
//                     <BarLoader
//                         size={20}
//                     />
//                 </div>
//             );
//         } else {
//             return(
//                 <div className={"buttonBox loginButton"}>
//                     <Button
//                         text={"Login now"}
//                         onPress={() => this.onLoginPress()}
//                     />
//                 </div>
//             );
//         }
//     }
//
//     render(){
//         return (
//             <div className={"loginLogoutContainer"}>
//                 <div className={"topLogoContainer"}>
//                     <img src={typographyLogo} alt="Nafasi Typography Logo" style={{ margin: "2rem"}}/>
//                 </div>
//                 <div className={"loginBackgroundImageContainer"}>
//                     <img src={backgroundImage} alt="background artwork" style={{ width: "100%", zIndex: 1 }}/>
//                 </div>
//                 <div className={"loginLogoutBox"}>
//                     <div className={"logoArea"}>
//                         <img src={logo} alt="nafasi Logo" />
//                         <p style={{ fontFamily: "ramblaBold", color: "#000" }}>For organizations</p>
//                     </div>
//
//                     <div>
//                         <Input
//                             type={'text'}
//                             placeholder={'Email'}
//                             onChange={e => this.props.credentialsChanged({prop: 'email', value: e.target.value}) }
//                             value={this.props.email}
//                             onKeyDown={this.keyPressed}
//                         />
//                     </div>
//
//                     <div className={"passwordBox"}>
//                         {this.state.isPasswordVisible ?
//                             <Input
//                                 type='text'
//                                 onChange={(e) => this.setState({password: e.target.value})}
//                                 value={this.state.password}
//                                 onKeyDown={this.keyPressed}
//                             />
//                             :
//                             <Input
//                                 type={'password'}
//                                 placeholder={'Password'}
//                                 onChange={e => this.props.credentialsChanged({prop: 'password', value: e.target.value}) }
//                                 value={this.state.password}
//                                 onKeyDown={this.keyPressed}
//                             />
//                         }
//                         <div
//                             className={"showPasswordBox"}
//                             onClick={() => this.setState({isPasswordVisible: !this.state.isPasswordVisible})}
//                         >
//                             {this.state.isPasswordVisible ? <FontAwesomeIcon  icon={"eye-slash"}  size={"1x"} color={"#3295ff"} /> : <FontAwesomeIcon  icon={"eye"}  size={"1x"} color={"#3295ff"} />}
//                         </div>
//                     </div>
//                     <ForgotPassword/>
//
//                     { this.renderLoginButton() }
//
//                     <h5 style={{textAlign: 'center'}}><NavLink to={"/register"}>Don't have an account? Register here</NavLink></h5>
//                 </div>
//             </div>
//         )
//     }
// }
//
// const mapStateToProps = (state) => {
//     const { email, password, privateID, loading } = state.authentication;
//     return {
//         email,
//         password,
//         privateID,
//
//         loading,
//
//     }
// };
//
// // Wrapper functional component to use hooks
// function SignIn(props) {
//     const navigate = useNavigate();
//
//     return <SignInBase {...props} navigate={navigate} />;
// }
//
// export default connect(mapStateToProps,{credentialsChanged, userLogin, getLoading, getLoadingFalse})(SignIn);