import React from 'react';
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment";
import {Modal} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';

// Action and common imports remain the same
import {fetchSelectedSeeker, fetchSeekerProfile, fetchSeekerPath} from "../../../actions/PostsActions";


class PDFCV extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			visible: false,

			selectedCandidateProfile: {},
			selectedCandidate: {},

			skills: [],
			hobbies: [],
			employment: [],
			education: [],
			military: [],
			awards: [],
			licenses: [],
			links: [],
			languages: [],
			certificates: [],
			referees: [],
		}
	}

	// The function to generate PDF will need adjustments to use jsPDF or similar library
	componentWillReceiveProps(nextProps, nextContext) {
		if (nextProps !== this.props) {
			this.setState({
				selectedCandidateProfile: nextProps.selectedCandidateProfile,
				selectedCandidate: nextProps.selectedCandidate,
				skills: nextProps.skills,
				hobbies: nextProps.hobbies,
				employment: nextProps.employment,
				education: nextProps.education,
				military: nextProps.military,
				awards: nextProps.awards,
				licenses: nextProps.licenses,
				links: nextProps.links,
				languages: nextProps.languages,
				certificates: nextProps.certificates,
				referees: nextProps.referees,
			});
		}
	}

	handlePDFPress() {
		const { userID } = this.props.candidate;
		const seekerID = userID;
		this.props.fetchSelectedSeeker({ seekerID });
		this.props.fetchSeekerProfile({ seekerID });
		this.props.fetchSeekerPath({ seekerID, path: "interviews" });
		this.props.fetchSeekerPath({ seekerID, path: "certificates" });
		this.props.fetchSeekerPath({ seekerID, path: "education" });
		this.props.fetchSeekerPath({ seekerID, path: "employment" });
		this.props.fetchSeekerPath({ seekerID, path: "languages" });
		this.props.fetchSeekerPath({ seekerID, path: "awards" });
		this.props.fetchSeekerPath({ seekerID, path: "licenses" });
		this.props.fetchSeekerPath({ seekerID, path: "hobbies" });
		this.props.fetchSeekerPath({ seekerID, path: "military" });
		this.props.fetchSeekerPath({ seekerID, path: "skills" });
		this.props.fetchSeekerPath({ seekerID, path: "links" });
		this.props.fetchSeekerPath({ seekerID, path: "referees" });

		this.setState({ visible: true });
	}

	handleOk = () => {
		this.setState({
			visible: false,
		});
	};

	handleCancel = () => {
		this.setState({
			visible: false,
		});
	};

	showModal = () => {
		this.setState({
			visible: true,
		});
	};

	render() {
		//extract state
		const {
			selectedCandidateProfile,
			selectedCandidate,

			skills,
			hobbies,
			employment,
			education,
			military,
			awards,
			licenses,
			links,
			languages,
			certificates,
			referees,
		} = this.state;

		return (
			// Use View or button instead of TouchableOpacity
			// Replace Modal with a web-compatible modal component
			// Use FaPrint for the print icon
			// Adjustments to styles and other components to be web-compatible
			<div>
				<div style={{textAlign: 'center'}}><FontAwesomeIcon icon={"eye"} size={"1x"} className={"editIcon2"} onClick={() => this.handlePDFPress()}/></div>

				<Modal
					title={"View Feedback"}
					open={this.state.visible}
					onOk={this.handleOk}
					onCancel={this.handleCancel}

					footer={null}
					width={900}
				>
					{/*<div style={{textAlign: 'center'}}><FontAwesomeIcon icon={"eye"} size={"1x"} color={"#000"} onClick={() => this.handleGeneratePDF()}/></div>*/}

					<div>
						<PDFDownloadLink
							document={
								<GeneratePDF
									data={{
										selectedCandidateProfile,
										selectedCandidate,
										skills,
										hobbies,
										employment,
										education,
										military,
										awards,
										licenses,
										links,
										languages,
										certificates,
										referees
									}}
								/>
							}
							fileName="cv.pdf"
						>
							{({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
						</PDFDownloadLink>
					</div>
				</Modal>
			</div>
		);
	}
}


const GeneratePDF = ({ data }) => {
	try {
		const {
			selectedCandidateProfile,
			selectedCandidate,

			skills,
			hobbies,
			employment,
			education,
			military,
			awards,
			licenses,
			links,
			languages,
			certificates,
			referees
		} = data;

		const {
			firstName,
			lastName,
			email,
			phone,
			location,
			summary
		} = selectedCandidateProfile;

		const {
			birthdate,
			gender,
			maritalStatus,
		} = selectedCandidate;

		let seconds;
		birthdate.seconds ? seconds = birthdate.seconds : seconds = birthdate._seconds;
		const dateMoment = moment.unix(seconds);
		const birthday = dateMoment.format("DD MMM YYYY");

		const generateListItems = ({ path, items }) => {
			if (!items || !items.length) {
				return '';
			}

			switch (path) {
				case "skills":
					return (
						<View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
							{items.map((item, index) => (
								<Text key={index} style={styles.sectionContentText}>
									{item.title}{index < items.length - 1 ? ', ' : ''}
								</Text>
							))}
						</View>
					);
				case "hobbies":
					return (
						<View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
							{items.map((item, index) => (
								<Text key={index} style={styles.sectionContentText}>
									{item.title}{index < items.length - 1 ? ', ' : ''}
								</Text>
							))}
						</View>
					);
				case "employment":
					return items.map(item => {
						let fromSeconds;
						item.timeOfWorkFrom.seconds ? fromSeconds = item.timeOfWorkFrom.seconds : fromSeconds = item.timeOfWorkFrom._seconds;
						const from = moment.unix(fromSeconds).format("MMM YYYY");

						let toSeconds;
						item.timeOfWorkTo.seconds ? toSeconds = item.timeOfWorkTo.seconds : toSeconds = item.timeOfWorkTo._seconds;
						const to = moment.unix(toSeconds).format("MMM YYYY");

						const experiences = [];

						if ('experience' in item) {
							const experience = item.experience;
							_.forEach(experience,exp => {
								let exFromSeconds;
								exp.from.seconds ? exFromSeconds = exp.from.seconds : exFromSeconds = exp.from._seconds;
								const exFrom = moment.unix(exFromSeconds).format("MMM YYYY");

								let exToSeconds;
								exp.to.seconds ? exToSeconds = exp.to.seconds : exToSeconds = exp.to._seconds;
								const exTo = moment.unix(exToSeconds).format("MMM YYYY");

								const present = exp.current ? "Present" : exTo;
								const ID = exp.ID;

								experiences.push({ exFrom, present, title: _.capitalize(ID) })
							});
						}

						return(
							<View>
								<Text>
									<Text style={{ ...styles.weight, color: "#0793d9" }}>{item.jobCompany}</Text> / {item.jobTitle.title}
								</Text>
								<Text style={styles.subTitle}>
									{from} - {item.currentWork ? "Present" : to}, {item.location.region} | {item.location.country}
								</Text>
								<View style={styles.verticalSpace}>
									<Text style={{ fontSize: 12, color: "#737576" }}>Positions</Text>
									{experiences.map((ex, index) => (
										<View key={index} style={styles.card}>
											<Text style={styles.sectionContentText}>{ex.title} {ex.exFrom} - {ex.present}</Text>
										</View>
									))}
								</View>
								<View style={{ paddingBottom: 10 }}>
									<Text style={styles.sectionContentText}>{item.description}</Text>
								</View>
							</View>
						)
					});
				case "education":
					return items.map(item => {
						let fromSeconds;
						item.timeOfStudyFrom.seconds ? fromSeconds = item.timeOfStudyFrom.seconds : fromSeconds = item.timeOfStudyFrom._seconds;
						const from = moment.unix(fromSeconds).format("MMM YYYY");

						let toSeconds;
						item.timeOfStudyTo.seconds ? toSeconds = item.timeOfStudyTo.seconds : toSeconds = item.timeOfStudyTo._seconds;
						const to = moment.unix(toSeconds).format("MMM YYYY");

						return(
							<View>
								<Text>
									<Text style={{ ...styles.weight, color: "#0793d9" }}>{item.institution}</Text> / {item.educationLevel.title}
								</Text>
								<Text style={styles.subTitle}>
									{from} - {item.currentEnrollment ? "Present" : to}, {item.location.region} | {item.location.country}
								</Text>
								<View style={[styles.verticalSpace, styles.weight]}>
									<Text style={styles.sectionContentText}>{item.educationField}</Text>
								</View>
							</View>

						)
					});
				case "military":
					return items.map(item => {
						let fromSeconds;
						item.serveFrom.seconds ? fromSeconds = item.serveFrom.seconds : fromSeconds = item.serveFrom._seconds;
						const from = moment.unix(fromSeconds).format("MMM YYYY");

						let toSeconds;
						item.serveTo.seconds ? toSeconds = item.serveTo.seconds : toSeconds = item.serveTo._seconds;
						const to = moment.unix(toSeconds).format("MMM YYYY");

						return(
							<View>
								<Text style={styles.weight}>
									<Text style={{ ...styles.weight, color: "#0793d9" }}>{item.branch}</Text> / {item.rank}
								</Text>
								<Text style={styles.subTitle}>
									{from} - {item.currentlyServing ? "Present" : to} | {item.location.country}
								</Text>
								<View style={styles.verticalSpace}>
									<Text style={styles.sectionContentText}>{item.description}</Text>
								</View>
							</View>

						)
					});
				case "awards":
					return items.map(item => {
						let seconds;
						item.dateAwarded.seconds ? seconds = item.dateAwarded.seconds : seconds = item.dateAwarded._seconds;
						const date = moment.unix(seconds).format("MMM YYYY");

						return(
							<View>
								<Text style={styles.weight}>
									<Text style={{ ...styles.weight, color: "#0793d9" }}>{item.title}</Text>
									<Text style={{ fontSize: 10, color: "#737576", fontWeight: "bold" }}> - {date}</Text>
								</Text>
								<View style={[styles.verticalSpace, styles.weight]}>
									<Text style={styles.sectionContentText}>{item.description}</Text>
								</View>
							</View>
						)
					});
				case "licenses":
					return items.map(item => {
						let seconds;
						item.expirationDate.seconds ? seconds = item.expirationDate.seconds : seconds = item.expirationDate._seconds;
						const date = moment.unix(seconds).format("MMM YYYY");

						return(
							<View>
								<Text style={styles.weight}>
									<Text style={{ ...styles.weight, color: "#0793d9" }}>{item.licenseCategory.title}</Text>
									<Text style={{ fontSize: 10, color: "#737576", fontWeight: "bold" }}>
										- {item.expires ? date : "NO Expiry"}
									</Text>
								</Text>
							</View>
						)
					});
				case "links":
					const addHttpsIfNeeded = (url) => {
						if (!url.startsWith('https://')) {
							url = 'https://' + url;
						}
						return url;

					};

					return items.map((item, index) => (
						<Text key={index} style={styles.link}>
							🔗 <Text style={styles.bold}>{item.title}</Text> - {addHttpsIfNeeded(item.url)}
						</Text>
					));
				case "languages":
					return (
						<View style={styles.inlineView}>
							{items.map((item, index) => (
								<Text key={index} style={styles.inlineText}>
									{`${item.title} - ${item.proficiency.title}${index !== items.length - 1 ? ', ' : ''}`}
								</Text>
							))}
						</View>
					)
				case "certificates":
					return (
						<View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
							{items.map((item, index) => (
								<Text key={index} style={styles.inlineText}>
									{item.title}{index < items.length - 1 ? ', ' : ''}
								</Text>
							))}
						</View>
					);
				case "referees":
					return items.map(item => {
						return(
							<View>
								<Text style={{...styles.weight, color: "#0793d9"}}>{item.firstName} {item.lastName}</Text>
								<Text style={styles.subTitle}>{item.title} | {item.company}</Text>
								<View style={styles.verticalSpace}>
									<Text style={styles.sectionContentText}>{item.phone}</Text>
									<Text style={styles.sectionContentText}>{item.email}</Text>
								</View>
							</View>
						)
					});
				default:
					return (
						<View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
							{items.map((item, index) => (
								<Text key={index} style={styles.inlineText}>
									{item.title}{index < items.length - 1 ? ', ' : ''}
								</Text>
							))}
						</View>
					);
			}

		};

		return (
			<Document>
				<Page size="A4">
					<View style={styles.main}>
						<View style={[styles.mainTitle, styles.weight]}>
							<Text style={{ color: '#ffffff', fontSize: 22 }}>{firstName} {lastName}</Text>
							<View style={styles.weight}>
								<Text style={{ ...styles.sectionContentText, color: "#ffffff"}}>{location.streetAddress}</Text>
								<Text style={{ ...styles.sectionContentText, color: "#ffffff"}}>{location.postalCode}, {location.region}</Text>
								<Text style={{ ...styles.sectionContentText, color: "#ffffff"}}>{location.country}</Text>
							</View>
						</View>
						<View style={styles.section}>
							<View style={[styles.weight, styles.height]}>
								<Text style={{ ...styles.sectionContentText, color: '#ffffff' }}>{firstName} {lastName}</Text>
								<Text style={{ ...styles.sectionContentText, color: '#C0C0C0' }}>{birthday} | {gender}</Text>
								<Text style={{ ...styles.sectionContentText, color: '#C0C0C0' }}>{maritalStatus}</Text>
							</View>

							<View style={[styles.colored, styles.height]}>
								<Text style={styles.sectionContentText}>{phone}</Text>
								<Text style={styles.sectionContentText}>{email}</Text>
							</View>

							<View style={styles.sectionContent}>
								<Text style={[styles.weight, { color: '#737576' }]}>Summary</Text>
								<Text>{summary}</Text>
							</View>
						</View>
					</View>

					<View style={styles.main}>
						<View style={styles.titles}>
							<Text style={styles.sectionTitle}>Experience</Text>
						</View>

						<View style={styles.section}>
							{generateListItems({path: "employment", items: employment})}
						</View>
					</View>

					<View style={styles.main}>
						<View style={styles.titles}>
							<Text style={styles.sectionTitle}>Skills</Text>
						</View>

						<View style={styles.section}>
							<View style={styles.list}>
								{generateListItems({path: "skills", items: skills})}
							</View>
						</View>
					</View>

					{
						awards.length !== 0 ?
							<View style={styles.main}>
								<View style={styles.titles}>
									<Text style={styles.sectionTitle}>Awards</Text>
								</View>

								<View style={styles.section}>
									{generateListItems({path: "awards", items: awards})}
								</View>
							</View> : null
					}

					<View style={styles.main}>
						<View style={styles.titles}>
							<Text style={styles.sectionTitle}>Education</Text>
						</View>

						<View style={styles.section}>
							{generateListItems({path: "education", items: education})}
						</View>
					</View>

					{
						certificates.length !== 0 ?
							<View style={styles.main}>
								<View style={styles.titles}>
									<Text style={styles.sectionTitle}>Certificates</Text>
								</View>

								<View style={styles.section}>
									{generateListItems({path: "certificates", items: certificates})}
								</View>
							</View> : null
					}


					{
						military.length !== 0 ?
							<View style={styles.main}>
								<View style={styles.titles}>
									<Text style={styles.sectionTitle}>Military</Text>
								</View>


								<View style={styles.section}>
									{generateListItems({path: "military", items: military})}
								</View>
							</View> : null

					}


					{
						licenses.length !== 0 ?
							<View style={styles.main}>
								<View style={styles.titles}>
									<Text style={styles.sectionTitle}>Licenses</Text>
								</View>

								<View style={styles.section}>
									{generateListItems({path: "licenses", items: licenses})}
								</View>
							</View> : null
					}


					<View style={styles.main}>
						<View style={styles.titles}>
							<Text style={styles.sectionTitle}>Languages</Text>
						</View>

						<View style={styles.section}>
							<View style={styles.list}>
								{generateListItems({path: "languages", items: languages})}
							</View>
						</View>
					</View>

					{
						links.length !== 0 ?
							<View style={styles.main}>
								<View style={styles.titles}>
									<Text style={styles.sectionTitle}>Links</Text>
								</View>

								<View style={styles.section}>
									{generateListItems({path: "links", items: links})}
								</View>
							</View> : null
					}

					<View style={styles.main}>
						<View style={styles.titles}>
							<Text style={styles.sectionTitle}>Hobbies</Text>
						</View>

						<View style={styles.section}>
							<View style={styles.list}>
								{generateListItems({path: "hobbies", items: hobbies})}
							</View>
						</View>
					</View>



					<View style={styles.main}>
						<View style={styles.titles}>
							<Text style={styles.sectionTitle}>Referees</Text>
						</View>

						<View style={styles.section}>
							{generateListItems({path: "referees", items: referees})}
						</View>
					</View>
				</Page>
			</Document>
		);
	} catch (error) {
		console.error('Error generating PDF:', error);
		return null;
	}
};


const styles = StyleSheet.create({
	main: {
		display: 'flex',
		flexDirection: 'row',
		padding: 10,
	},
	mainTitle: {
		flex: 1,
		marginBottom: 8,
		paddingHorizontal: 10,
		paddingVertical: 5,
		backgroundColor: '#000000',
	},
	mainTitleText: {
		color: '#ffffff',
		fontSize: 10,
	},
	titles: {
		flex: 1,
		marginRight: 10,
		borderTopWidth: 10,
		borderTopStyle: "dashed",
		borderTopColor: 'silver',
		paddingVertical: 5,
	},
	section: {
		flex: 3,
		borderTopWidth: 10,
		borderTopStyle: "solid",
		borderTopColor: '#000000',
		padding: 10,
	},
	sectionTitle: {
		color: '#000',
		fontWeight: 600,
		fontSize: 16,
	},
	sectionContent: {
		fontSize: 10,
	},
	sectionContentText: {
		fontSize: 10,
	},
	sectionH2: {
		fontWeight: 200,
		fontSize: 10,
	},
	sectionH3: {
		fontSize: 10,
	},
	first: {
		marginTop: 0,
		backgroundColor: '#262B2F',
		padding: 10,
	},
	weight: {
		fontWeight: 600,
		fontSize: 16,
	},
	colored: {
		marginVertical: 10,
	},
	subTitle: {
		color: '#737576',
		fontWeight: 600,
		fontSize: 14,
	},
	card: {
		backgroundColor: '#ffb400',
		borderRadius: 5,
		paddingVertical: 5,
		paddingHorizontal: 10,
		marginVertical: 5,
		marginHorizontal: 10,
		display: 'inline-block',
		width: '100%',
	},
	verticalSpace: {
		marginVertical: 10,
	},
	list: {
		display: 'inline',
		padding: 0,
	},
	listItem: {
		display: 'inline',
		fontSize: 10,
		fontWeight: 'bold',
	},

	link: {
		margin: 2, // Example value
		// other styles
	},
	bold: {
		fontWeight: 'bold',
		fontSize: 12,
	},

	inlineView: {
		flexDirection: 'row',
		flexWrap: 'wrap',
	},
	inlineText: {
		// Add styles for inline text here, if necessary
		fontSize: 12,
	},
});

// Use these styles in your components like so:
// <Text style={styles.mainTitleText}>Hello World</Text>




const mapStateToProps = state => {
	const {
		selectedCandidateProfile, selectedCandidate, skills, hobbies, employment, education, military,
		awards, licenses, links, languages, certificates, referees
	} = state.posts;

	return {
		selectedCandidateProfile,
		selectedCandidate,

		skills,
		hobbies,
		employment,
		education,
		military,
		awards,
		licenses,
		links,
		languages,
		certificates,
		referees,
	}
}


// Replace inline styles with CSS stylees or styled-components

export default connect(mapStateToProps, { fetchSelectedSeeker, fetchSeekerProfile, fetchSeekerPath })(PDFCV);