import React from 'react';
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Table, Popconfirm, Modal, message, Switch } from 'antd';

import moment from "moment";

//action imports

import CustomHeaderCard from '../../../search/cards/CustomHeaderCard';
import DescriptionModal from './DescriptionModal';

class RecruitersRequestsCard extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			items: this.props.items,
		}
	}

	render() {
		const { items } = this.state;
		let processedResults = items.map(result => {
			let seconds;
			result.createdOn.seconds ? seconds = result.createdOn.seconds : seconds = result.createdOn._seconds;
			const time = moment.unix(seconds).format("DD/MM/YYYY | HH:mm");
	
			return { ...result, time};
		})

		// let processedResults = [
		// 	{
		// 		id: 1,
		// 		companyName: "Tech Innovations Inc.",
		// 		location: "San Francisco, CA, USA",
		// 		adminName: "Alice Smith",
		// 		email: "alice.smith@techinnovations.com",
		// 		phone: "+14155552671",
		// 		time: "24/05/2024 | 12:30",
		// 		verified: false,
		// 		companyLocation: {
		// 			region: "SF",
		// 			country: "USA",
		// 			postalCode: "94103",
		// 			streetAddress: "123 Market St"
		// 		},
		// 		firstName: "Alice",
		// 		lastName: "Smith",
		// 		companyEmail: "support@techinnovations.com",
		// 		companyPhone: "+1122334455",
		// 		active: true,
		// 		disabled: false,
		// 		paid: true
		// 	},
		// 	{
		// 		id: 2,
		// 		companyName: "Green Energy Solutions",
		// 		location: "Los Angeles, CA, USA",
		// 		adminName: "Bob Johnson",
		// 		email: "bob.johnson@greenenergysolutions.com",
		// 		phone: "+12121234567",
		// 		time: "25/05/2024 | 14:45",
		// 		verified: true,
		// 		companyLocation: {
		// 			region: "LA",
		// 			country: "USA",
		// 			postalCode: "90001",
		// 			streetAddress: "456 Hollywood Blvd"
		// 		},
		// 		firstName: "Bob",
		// 		lastName: "Johnson",
		// 		companyEmail: "sales@greenenergysolutions.com",
		// 		companyPhone: "+1133444556",
		// 		active: false,
		// 		disabled: true,
		// 		paid: false
		// 	},
		// 	{
		// 		id: 3,
		// 		companyName: "Digital Frontier Corp.",
		// 		location: "Chicago, IL, USA",
		// 		adminName: "Charlie Davis",
		// 		email: "charlie.davis@digitalfrontier.com",
		// 		phone: "+12223334488",
		// 		time: "26/05/2024 | 16:15",
		// 		verified: false,
		// 		companyLocation: {
		// 			region: "CHI",
		// 			country: "USA",
		// 			postalCode: "60601",
		// 			streetAddress: "789 Michigan Ave"
		// 		},
		// 		firstName: "Charlie",
		// 		lastName: "Davis",
		// 		companyEmail: "info@digitalfrontier.com",
		// 		companyPhone: "+1144445567",
		// 		active: true,
		// 		disabled: false,
		// 		paid: true
		// 	}
		// ];
		

		const columns = [
            {
                title: <CustomHeaderCard>Company Name</CustomHeaderCard>,
                dataIndex: 'companyName',
                key: 'companyName',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: record.color }}>{record.companyName}</div>
                ),
            },
			{
                title: <CustomHeaderCard>Location</CustomHeaderCard>,
                dataIndex: 'location',
                key: 'location',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: record.color }}>{record.companyLocation.region}, {record.companyLocation.country}</div>
                ),
            },
			{
                title: <CustomHeaderCard>Admin Name</CustomHeaderCard>,
                dataIndex: 'adminName',
                key: 'adminName',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: record.color }}>{record.firstName} {record.lastName}</div>
                ),
            },
			{
                title: <CustomHeaderCard>Email</CustomHeaderCard>,
                dataIndex: 'email',
                key: 'email',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: record.color }}>{record.email}</div>
                ),
            },
			{
                title: <CustomHeaderCard>Phone</CustomHeaderCard>,
                dataIndex: 'phone',
                key: 'phone',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: record.color }}>{record.phone}</div>
                ),
            },
			{
                title: <CustomHeaderCard>Time</CustomHeaderCard>,
                dataIndex: 'time',
                key: 'time',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: record.color }}>{record.time}</div>
                ),
            },
			{
                title: <CustomHeaderCard>View</CustomHeaderCard>,
                dataIndex: 'view',
                key: 'view',
                render: (_, record) => (
                    <div style={{fontSize: '0.9rem',height: '23px',overflow: 'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap'}}>
                        <DescriptionModal record={record}/>
                    </div>
                ),
            }
		];
		return (
            <Table dataSource={processedResults} columns={columns} rowKey="id" />
		);
	}
}

export default RecruitersRequestsCard;