import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from 'react-redux';
import { NavLink } from "react-router-dom";
import ReactToPrint from "react-to-print";

//-> component imports
import AddSubscriptionInvoice from '../AddSubscriptionInvoice';
import { Button } from "../../../../common/Button";

class Print extends React.Component {

    render(){
        return(
            <div>
                <AddSubscriptionInvoice/>
            </div>
        )
    }
}


class AddPrintInvoice extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            subscription: this.props.subscription
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps.subscription !== this.props.subscription) {
            this.setState({
                subscription: nextProps.subscription
            });
        }
    }

    renderProceedButton(){
        const { subscription } = this.state;

        if(subscription){
            return(
                <div className={'invoicePaymentProceedBox'}>
                    <div className={'buttonBox2'}>
                        <Button
                            text={'Proceed to Payment'}
                            onPress={() => this.props.history.push('/_addTermsAndConditions')}
                        />
                       
                    </div>
                </div>
            )
        } else {
            return null;
        }
    }


    render() {

        return(
            <div className={'printInvoiceContainer'}>
                <ReactToPrint
                    // trigger={() =>
                    //     // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                    //     // to the root node of the returned component as it will be overwritten.
                    //     <span tooltip="print invoice" flow="down">
                    //         <FontAwesomeIcon icon={'print'} size={'2x'} color={'#8b91ff'} style={{cursor: 'pointer'}} />
                    //     </span>
                    // }
                    trigger={() =>
                        <div className={'invoicePrintBox'}>
                            <span tooltip="print invoice" flow="down">
                                <FontAwesomeIcon icon={'print'} size={'2x'} color={'#8b91ff'} style={{cursor: 'pointer'}} />
                            </span>
                        </div>
                    }
                    content={() => this.componentRef}
                />
                <Print ref={(el) => (this.componentRef = el)} />

                <div>
                    {this.renderProceedButton()}
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    const { subscription } = state.subscription;

    return {
        subscription,
    }
}

export default connect(mapStateToProps, {})(AddPrintInvoice);