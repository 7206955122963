import React from "react";
import { Table, Button, Modal, message } from 'antd';

//-> action imports

//-> component import
import CustomHeaderCard from "../../../search/cards/CustomHeaderCard";

class skillsCard extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            skills: this.props.skills,
        };
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps !== this.props) {
            this.setState({
                skills: nextProps.skills,
            });
        }
    }

    renderSkill(){
        const { skills } = this.state;

        const columns = [
            {
                title: <CustomHeaderCard>Title</CustomHeaderCard>,
                dataIndex: 'title',
                key: 'title',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.title}</div>
                ),
            }
        ];

         return (
            <Table dataSource={skills} columns={columns} rowKey="id" />
        )
    }

    render() {
        return(
            <div>
                {this.renderSkill()}
            </div>
        );
    }
}

export default skillsCard;