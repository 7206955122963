import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import {useNavigate} from "react-router-dom";

//action imports
import {clearSelectPost} from "../../actions/PostsActions";

//components import
import ShortlistCandidates from "./subComponents/ShortlistCandidates";
import {Spinner} from "../common/Spinner";
import { PackageAddIcon } from "hugeicons-react";

class ShortlistBase extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            shortlist: this.props.shortlist,
            loading: this.props.loading,
            profile: this.props.profile,
            isToggled:this.props.isToggled
        }
    }

    componentDidMount() {
        //clear select post so that perform interview option is hidden
        this.props.clearSelectPost();
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps !== this.props) {
            this.setState({
                shortlist: nextProps.shortlist,
                loading: nextProps.loading,
                profile: nextProps.profile,
                isToggled:nextProps.isToggled,
            });
        }
    }

    renderShortlist() {
        //extract state
        const { shortlist,isToggled } = this.state;

        if (shortlist.length !== 0) {
            //count employees
            let numberOfShortlist = shortlist.length;
            return (
                <div className={"peopleBox"}>
                    <div className={"peopleRegistryBox"}>
                        <div className={"peopleIconHeadingBox"}>
                            <h4 style={{ color: "#0793d9", fontFamily: "ramblaBold" }}>Candidates</h4>
                            <h5 style={{ color: "#0793d9", fontFamily: "ramblaBold" }}>{`(${numberOfShortlist})`}</h5>
                        </div>
                    </div>

                    <div className={"peopleInfoBox"}>
                        <ShortlistCandidates shortlist={shortlist} isToggled ={isToggled}  />
                    </div>
                </div>
            );
        } else {
            return <h5 className={'adminAlert'}>No candidates have been shortlisted yet, shortlist candidates from <span style={{ color: "#ffb400", cursor: "pointer"}}
              onClick={() => this.props.navigate("/search")}
            >
                Search</span></h5>
        }
    }

    checkUser() {
        //extract state
        const { profile, loading } = this.state;

        if(!(_.isEmpty(profile))) {

            if (loading) {
                return <Spinner/>
            } else {
                if ((profile.role === "admin") || (profile.role === "superAdmin")) {
                    return (
                        <div className={"peopleContainer"}>
                            <div className={"topBarBox"}>
                                <div className={"childHeaderBarBox"}>
                                    <PackageAddIcon size={30} style={{ color: "#000"}} />
                                    <h3>Your Shortlist</h3>
                                </div>
                            </div>

                            <div className={"reportsTicketsBox"}>
                                {this.renderShortlist()}
                            </div>
                        </div>
                    );
                } else {
                    return <h3>Un-Authorized Access</h3>;
                }
            }
        }   else {
            return <h3>Profile hasn't loaded yet</h3>;
        }
    }

    render() {

        return (
            <div>
                { this.checkUser() }
            </div>
        );
    }
}

const mapStateTopProps = state => {
    const { shortlist, loading } = state.posts;
    const { profile } = state.profile;
    const { isToggled } = state.home;


    return {
        shortlist,
        loading,
        profile,
        isToggled
    }
};

// Wrapper functional component to use hooks
function Shortlist(props) {
    const navigate = useNavigate();

    return <ShortlistBase {...props} navigate={navigate} />;
}

export default connect(mapStateTopProps, { clearSelectPost })(Shortlist);
