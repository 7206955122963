import React from "react";
import { connect } from "react-redux";
import _ from 'lodash';
import {message, Modal, Switch} from 'antd';
import {ClipLoader} from "react-spinners";

//action imports
import { notificationFieldChanged, adminNotification, seekerNotification, topicNotification } from "../../../actions/NotificationsActions";

//component imports
import { Input } from "../../common/Input";
import { TextArea } from "../../common/TextArea";
import { PickDate } from "../../common/PickDate";
import {Button} from "../../common/Button";

class ManageNotifications extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            notificationTitle: this.props.notificationTitle,
            description: this.props.description,
            notificationTime: this.props.notificationTime,
            url: this.props.url,

            saveNotificationLoading: this.props.saveNotificationLoading,
            saveNotificationSuccessful: this.props.saveNotificationSuccessful,
            saveNotificationFailed: this.props.saveNotificationFailed,
            profile: this.props.profile,

            adminStatus: true,
            notificationStatus: true
        };

        this.onPickTime = this.onPickTime.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props) {
            this.setState({
                notificationTitle: nextProps.notificationTitle,
                description: nextProps.description,
                notificationTime: nextProps.notificationTime,
                url: nextProps.url,
                saveNotificationLoading: nextProps.saveNotificationLoading,
                saveNotificationSuccessful: nextProps.saveNotificationSuccessful,
                saveNotificationFailed: nextProps.saveNotificationFailed,
                profile: nextProps.profile
            });
        }
    }


    renderButton() {
        const {saveNotificationLoading}  = this.state;

        if (saveNotificationLoading) {
            return (
                <div style={{align: 'center', marginLeft: 330 }}>
                    <ClipLoader
                        size={20}
                    />
                </div>
            );
        } else {
            return  (
                <div>
                    <Button
                        text={"Save"}
                        onPress={() => this.onSave()}
                    />
                </div>
            );
        }
    }

    renderSuccessMessage() {
        const {saveNotificationSuccessful} = this.state;
        //
        if (saveNotificationSuccessful){
            return (
                <div>
                    <p>{message.success("Notification is successfully saved")}</p>
                </div>
            );
        } else {
            return null;
        }
    }

    renderFailedMessage() {
        const {saveNotificationFailed} = this.state;
        //
        if (saveNotificationFailed){
            return (
                <div>
                    <p>{message.error("Sorry! Notification is not saved")}</p>
                </div>
            );
        } else {
            return null;
        }
    }


    onPickTime(value) {
        this.props.notificationFieldChanged({prop: 'notificationTime', value})
    }

    onSave() {
        //extract state
        const { notificationTitle, description, notificationTime, profile, adminStatus, notificationStatus, url } = this.state;

        if (!(_.isEmpty(profile))){
            if (notificationTitle && description) {
                if (notificationStatus) {
                    if (adminStatus) {
                        if (notificationTime) {
                            this.props.adminNotification({ notificationTitle, description, notificationTime })
                        } else {
                            message.warning("Kindly enter the time");
                        }
                    } else {
                        //for seekers
                        this.props.seekerNotification({ notificationTitle, description })
                    }
                } else {
                    //for topics
                    if (url) {
                        //proceed
                        message.info("topics");
                        this.props.topicNotification({ notificationTitle, description, url });
                    } else {
                        message.warning("Kindly enter the url");
                    }
                }
            } else {
                message.warning("Kindly fill in all required fields");
            }
        }
    }

    showModal() {
        this.setState({
            visible: true,
        });
    };

    handleOk = () => {
        this.setState({
            visible: false,
        });
    };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };

    onAdminStatusChange = (checked) => {
        console.log(`switch to ${checked}`);
        this.setState({
            adminStatus: checked
        })
    };

    onNotificationStatusChange = (checked) => {
        console.log(`switch to ${checked}`);
        this.setState({
            notificationStatus: checked
        })
    };

    checkAccess(){
        //-> extract state
        const { profile } = this.state;
        //- check access level
        if(!(_.isEmpty(profile))) {
            //-> extract user role
            const {role} = this.state.profile;

            if (role === 'superAdmin') {
                return(
                    <div className={"addNotificationBox"}>
                        <h4>Add new notification</h4>

                        <div className={"addNotificationInfoBox"}>
                            <div className={"addNotyTitle"}>
                                <Input
                                    label={"Title"}
                                    type={'text'}
                                    onChange={e => this.props.notificationFieldChanged({prop: 'notificationTitle', value: e.target.value})}
                                    value={this.state.notificationTitle}
                                />
                            </div>

                            <div className={"addNotyTextArea"}>
                                <TextArea
                                    label={"Description"}
                                    onChange={e => this.props.notificationFieldChanged({prop: 'description', value: e.target.value})}
                                    value={this.state.description}
                                />
                            </div>

                            <div className={"addNotyDate"}>
                                <PickDate
                                    label={"Notification Time"}
                                    onChange={this.onPickTime}
                                    value={this.state.notificationTime}
                                />
                            </div>

                            {
                                this.state.notificationStatus ?
                                    null :
                                    <div>
                                        <Input
                                            label={"URL"}
                                            type={'text'}
                                            onChange={e => this.props.notificationFieldChanged({prop: 'url', value: e.target.value})}
                                            value={this.state.url}
                                        />
                                    </div>
                            }
                        </div>

                        <div style={{ backgroundColor: "black", margin: "1rem", padding: "0.5rem"}}>
                            <Switch
                                checkedChildren="Notification"
                                unCheckedChildren="Topic"
                                onChange={this.onNotificationStatusChange}
                                checked={this.state.notificationStatus}
                            />

                        </div>

                        {
                            this.state.notificationStatus ?
                                <div style={{ backgroundColor: "black", margin: "1rem", padding: "0.5rem"}}>
                                    <Switch
                                        checkedChildren="Admin"
                                        unCheckedChildren="Seekers"
                                        onChange={this.onAdminStatusChange}
                                        checked={this.state.adminStatus}
                                    />
                                </div> : null
                        }

                        <div>
                            {this.renderButton()}
                        </div>
                        <div>
                            {this.renderSuccessMessage()}
                            {this.renderFailedMessage()}
                        </div>
                    </div>
                );
            }
        }
    }

    renderShowButton() {
        //-> extract state
        const { profile } = this.state;
        //- check access level
        if(!(_.isEmpty(profile))){
            //-> extract user role
            const { role } = this.state.profile;

            if( role === 'superAdmin') {
                return(
                    <div className={"addJobOpeningBox"}>
                        <div style={{ margin: "1rem"}}>
                            <Button
                                text={"Add Notification"}
                                onPress={() => this.showModal()}
                            />
                        </div>
                    </div>
                );
            }
        }
    }

    render() {

        return (
            <div>
                {this.renderShowButton()}
                <Modal
                    title={"Add a Notification"}
                    open={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}

                    footer={null}
                    width={900}
                >
                    {this.checkAccess()}
                </Modal>
            </div>
        );
    }

}

const mapStateToProps = state => {

    const { notificationTitle, description, notificationTime, url, saveNotificationLoading, saveNotificationSuccessful, saveNotificationFailed } = state.notifications;
    const { profile} = state.profile;

    return {
        notificationTitle,
        description,
        notificationTime,
        url,
        profile,

        saveNotificationLoading,
        saveNotificationSuccessful,
        saveNotificationFailed,
    }
};

export default connect(mapStateToProps, { notificationFieldChanged, adminNotification, seekerNotification, topicNotification })(ManageNotifications);