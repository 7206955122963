import React from 'react';
import {connect} from 'react-redux';
import _ from "lodash";

//actions import

//components import
import MilitaryCard from "./cards/MilitaryCard";
import {Spinner} from "../../common/Spinner";

class Military extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedCandidate: this.props.selectedCandidate,
            military: this.props.military,
            militaryLoading: this.props.militaryLoading,
            profile: this.props.profile,
        }

        this.renderMilitary = this.renderMilitary.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props) {
            this.setState({
                selectedCandidate: nextProps.selectedCandidate,
                military: nextProps.military,
                militaryLoading: nextProps.militaryLoading,
                profile: nextProps.profile,
            })
        }
    }

    renderMilitary() {
        const {military, militaryLoading, selectedCandidate} = this.state;

        if (militaryLoading) {
            return <Spinner/>
        } else {
            if (!(_.isEmpty(selectedCandidate))) {
                return (
                    <div>
                        <MilitaryCard military={military}/>
                    </div>
                );
            } else {
                return <h2 style={{ color: "#000", margin: "2rem 0"  }}>Select a candidate to view</h2>;
            }
        }
    }


    checkUser() {
        //extract state
        const { profile } = this.state;

        if(!(_.isEmpty(profile))) {
            if((profile.role === "admin") || profile.role === "superAdmin") {
                return (
                    <div>
                        <div className={"settingsAssetsBox"}>
                            <div className={"addAssetsSettingsBox"}>
                                <h4 style={{ fontFamily: "ramblaBold", color: "black" }}>Military</h4>
                            </div>
                        </div>
                        <br/>
                        <div className={"infoBox"}>
                            <div className={"assetsDetailsBox"}>
                                <div>
                                    {this.renderMilitary()}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        } else {
            return <h2 style={{ color: "#000" }}>Sign in to view</h2>;
        }
    }

    render() {
        return (
            <div className={'employeeToolContainer'}>
                { this.checkUser() }
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    const { military, militaryLoading, selectedCandidate } = state.posts;
    const { profile } = state.profile;

    return{
        selectedCandidate,
        military,
        militaryLoading,
        profile
    }
}

export default connect(mapStateToProps, {})(Military);