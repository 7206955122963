import firebase from 'firebase';
import algoliasearch from 'algoliasearch';
import {
    ADD_TO_SHORTLIST,
    ADD_TO_SHORTLIST_FAILED,
    ADD_TO_SHORTLIST_SUCCESSFUL,
    CLEAR_SEARCH, DELETE_FROM_SHORTLIST, DELETE_FROM_SHORTLIST_FAILED, DELETE_FROM_SHORTLIST_SUCCESSFUL,
    FETCH_ADMIN_HIGHLIGHTS,
    FETCH_ADMIN_HIGHLIGHTS_FAIL,
    FETCH_ADMIN_HIGHLIGHTS_SUCCESS,
    FETCH_CERTIFICATES,
    FETCH_CERTIFICATES_FAILED,
    FETCH_CERTIFICATES_SUCCESSFUL,
    FETCH_EDUCATION_INDUSTRY,
    FETCH_EDUCATION_INDUSTRY_FAILED,
    FETCH_EDUCATION_INDUSTRY_SUCCESSFUL,
    FETCH_EDUCATION_LEVELS,
    FETCH_EDUCATION_LEVELS_FAILED,
    FETCH_EDUCATION_LEVELS_SUCCESSFUL,
    FETCH_JOB_TITLES,
    FETCH_JOB_TITLES_FAILED,
    FETCH_JOB_TITLES_SUCCESSFUL,
    FETCH_LANGUAGES,
    FETCH_LANGUAGES_FAILED,
    FETCH_LANGUAGES_SUCCESSFUL,
    FETCH_LICENSES,
    FETCH_LICENSES_FAILED,
    FETCH_LICENSES_SUCCESSFUL,
    FETCH_LINKS, FETCH_LINKS_FAILED,
    FETCH_LINKS_SUCCESSFUL,
    FETCH_SKILLS,
    FETCH_SKILLS_FAILED,
    FETCH_SKILLS_SUCCESSFUL,
    SAVE_SEARCHES,
    SAVE_SEARCHES_FAIL,
    SAVE_SEARCHES_SUCCESS,
    SEARCH,
    SEARCH_FAILED,
    SEARCH_FIELD_CHANGED,
    SEARCH_SUCCESSFUL
} from "./Types";
import {message} from "antd";

//property declarations
//property declarations
const client = algoliasearch('35LINDSEFZ', 'c13dbd56579a2df8cd4b736e2c331780');
const assetIndex = client.initIndex('dev_NAFASI');

export const searchFieldChanged = ({ prop, value }) => {
    return {
        type: SEARCH_FIELD_CHANGED,
        payload: { prop, value }
    }
};

export const clearSearch = () => {
    return {
        type: CLEAR_SEARCH
    }
};

export const searchDB = ({
                            packageIndex, openForRelocation, country, region,
                            jobTypeArray, workScheduleArray, gender,
                            maritalStatus, startBirthDate,
                            endBirthDate, certificateID, jobTitle,
                            educationLevel,
                            currentEnrollment, studiedAbroad, yearOfCompletion, industryID,
                            currentAtWork, experience, foreignJob, language, license, militaryCountry,
                            industryPrime,
                            industrySec,
                            positionPrime,
                            positionSec,
                            skill, 
                            link, 
                            resultsSearchLimit
                         }) => {


    //allow cors
    return async (dispatch) => {
        dispatch({ type: SEARCH });
        try {

            const userID = firebase.auth().currentUser.uid;

            const data = { packageIndex, openForRelocation, country, region,
                jobTypeArray, workScheduleArray, gender,
                maritalStatus, startBirthDate,
                endBirthDate, certificateID, jobTitle,
                educationLevel,
                currentEnrollment, studiedAbroad, experience, foreignJob, yearOfCompletion, industryID,
                currentAtWork, language, license, militaryCountry,
                industryPrime,
                industrySec,
                positionPrime,
                positionSec,
                skill, link, resultsSearchLimit, userID }

            const response = await fetch('https://us-central1-nafasiio.cloudfunctions.net/searchCV', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
                mode: 'cors',
            });

            if (!response.ok) {
                return dispatch({
                    type: SEARCH_FAILED
                });
            }

            const seekers = await response.json();

            dispatch({
                type: SEARCH_SUCCESSFUL,
                payload: {seekers, resultsSearchLimit}
            });
        } catch (e) {
            console.log(e);
            dispatch({
                type: SEARCH_FAILED
            });
            throw new Error('Error searching database');
        }
    };
};


export const saveSearches = ({
    packageIndex,openForRelocation,country, region,jobTypeArray,workScheduleArray,gender,maritalStatus,
    startBirthDate,endBirthDate,certificateID,jobTitle,educationLevel,currentEnrollment,studiedAbroad,
    foreignJob,experience,yearOfCompletion,industryID,currentAtWork,language,license,militaryCountry,
    industryPrime,industrySec,positionPrime,positionSec,skill,link,resultsSearchLimit,searchID,timestamp
}) => {

    return async (dispatch) => {
        try {
            dispatch({type: SAVE_SEARCHES});
            const userID = firebase.auth().currentUser.uid;

            const savedSearchRef = firebase.firestore().collection("adminBucket").doc(userID).collection("savedSearches").doc(searchID);
            await savedSearchRef.set({
                packageIndex,openForRelocation,country, region,jobTypeArray,workScheduleArray,gender,maritalStatus,
                startBirthDate,endBirthDate,certificateID,jobTitle,educationLevel,currentEnrollment,studiedAbroad,
                foreignJob,experience,yearOfCompletion,industryID,currentAtWork,language,license,militaryCountry,
                industryPrime,industrySec,positionPrime,positionSec,skill,link,resultsSearchLimit,searchID,timestamp
            });

            dispatch({type: SAVE_SEARCHES_SUCCESS});

        } catch (e) {
            dispatch({type: SAVE_SEARCHES_FAIL});
            console.log(e);
        }
    }
}



// ---------------------------- SEARCH QUERIES -------------------
export const fetchEducationLevels = () => {
    return async (dispatch) => {
        dispatch({ type: FETCH_EDUCATION_LEVELS });

        try {
            assetIndex.setSettings({
                attributesForFaceting: [
                    'list'
                ]
            }).then(() => {
                // done
            });

            const { hits } = await assetIndex.search('', {
                filters: 'list:educationLevel',
                hitsPerPage: 1000
            });

            let educationLevels = [];
            hits.forEach(hit => {
                if (hit) {
                    if ("title" in hit) {
                        educationLevels.push(hit);
                    }
                }
            });

            dispatch({
                type: FETCH_EDUCATION_LEVELS_SUCCESSFUL,
                payload: educationLevels,
            });

        } catch (error) {
            console.error('Error searching education levels:', error);
            dispatch({
                type: FETCH_EDUCATION_LEVELS_FAILED,
            });
        }
    };
};


// export const fetchEducationLevels = ({ searchQuery }) => {
//     return async (dispatch) => {
//         dispatch({ type: FETCH_EDUCATION_LEVELS });

//         try {
//             assetIndex.setSettings({
//                 attributesForFaceting: [
//                     'list' // or 'filterOnly(brand)' for filtering purposes only
//                 ]
//             }).then(() => {
//                 // done
//             });
//             const {hits} = await assetIndex.search(searchQuery, {
//                 filters: 'list:educationLevel',
//                 hitsPerPage: 5
//             });

//             let educationLevels = [];
//             hits.forEach(hit => {
//                 if (hit) {
//                     if ("title" in hit) {
//                         educationLevels.push(hit);
//                     }
//                 }
//             });

//             dispatch({
//                 type: FETCH_EDUCATION_LEVELS_SUCCESSFUL,
//                 payload: educationLevels,
//             });

//         } catch (error) {
//             console.error('Error searching education levels:', error);
//             dispatch({
//                 type: FETCH_EDUCATION_LEVELS_FAILED,
//             });
//         }
//     };
// };


export const fetchEducationIndustry = () => {
    return async (dispatch) => {
        dispatch({ type: FETCH_EDUCATION_INDUSTRY });

        try {
            assetIndex.setSettings({
                attributesForFaceting: [
                    'list'
                ]
            }).then(() => {
                // done
            });

            const { hits } = await assetIndex.search('', {
                filters: 'list:educationIndustry',
                hitsPerPage: 1000
            });

            let educationIndustries = [];
            hits.forEach(hit => {
                if (hit) {
                    if ("title" in hit) {
                        educationIndustries.push(hit);
                    }
                }
            });

            dispatch({
                type: FETCH_EDUCATION_INDUSTRY_SUCCESSFUL,
                payload: educationIndustries,
            });

        } catch (error) {
            console.error('Error searching education industries:', error);
            dispatch({
                type: FETCH_EDUCATION_INDUSTRY_FAILED,
            });
        }
    };
};


// export const fetchEducationIndustry = ({ searchQuery }) => {
//     return async (dispatch) => {
//         dispatch({ type: FETCH_EDUCATION_INDUSTRY });

//         try {
//             assetIndex.setSettings({
//                 attributesForFaceting: [
//                     'list' // or 'filterOnly(brand)' for filtering purposes only
//                 ]
//             }).then(() => {
//                 // done
//             });
//             const {hits} = await assetIndex.search(searchQuery, {
//                 filters: 'list:educationIndustry',
//                 hitsPerPage: 10
//             });

//             let educationIndustries = [];
//             hits.forEach(hit => {
//                 if (hit) {
//                     if ("title" in hit) {
//                         educationIndustries.push(hit);
//                     }
//                 }
//             });

//             dispatch({
//                 type: FETCH_EDUCATION_INDUSTRY_SUCCESSFUL,
//                 payload: educationIndustries,
//             });

//         } catch (error) {
//             console.error('Error searching education industries:', error);
//             dispatch({
//                 type: FETCH_EDUCATION_INDUSTRY_FAILED,
//             });
//         }
//     };
// }


export const fetchJobTitles = ({ searchQuery }) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_JOB_TITLES });

        try {
            assetIndex.setSettings({
                attributesForFaceting: [
                    'list' // or 'filterOnly(brand)' for filtering purposes only
                ]
            }).then(() => {
                // done
            });
            const {hits} = await assetIndex.search(searchQuery, {
                filters: 'list:jobTitle',
                hitsPerPage: 10
            });

            let jobTitles = [];
            hits.forEach(hit => {
                if (hit) {
                    if ("title" in hit) {
                        jobTitles.push(hit);
                    }
                }
            });

            dispatch({
                type: FETCH_JOB_TITLES_SUCCESSFUL,
                payload: jobTitles,
            });

        } catch (error) {
            console.error('Error searching job titles:', error);
            dispatch({
                type: FETCH_JOB_TITLES_FAILED,
            });
        }
    };
};

export const fetchSkills = () => {
    return async (dispatch) => {
        dispatch({ type: FETCH_SKILLS });

        try {
            assetIndex.setSettings({
                attributesForFaceting: [
                    'list'
                ]
            }).then(() => {
                // done
            });

            const { hits } = await assetIndex.search('', {
                hitsPerPage: 1000
            });

            let skills = [];
            hits.forEach(hit => {
                if (hit && "title" in hit) {
                    skills.push(hit);
                }
            });

            dispatch({
                type: FETCH_SKILLS_SUCCESSFUL,
                payload: skills,
            });

        } catch (error) {
            console.error('Error searching skills:', error);
            dispatch({
                type: FETCH_SKILLS_FAILED,
            });
        }
    };
};


// export const fetchSkills = ({ searchQuery }) => {
//     return async (dispatch) => {
//         dispatch({ type: FETCH_SKILLS });

//         try {
//             assetIndex.setSettings({
//                 attributesForFaceting: [
//                     'list' // or 'filterOnly(brand)' for filtering purposes only
//                 ]
//             }).then(() => {
//                 // done
//             });
//             const {hits} = await assetIndex.search(searchQuery, {
//                 filters: 'list:skill',
//                 hitsPerPage: 10
//             });

//             let skills = [];
//             hits.forEach(hit => {
//                 if (hit) {
//                     if ("title" in hit) {
//                         skills.push(hit);
//                     }
//                 }
//             });

//             dispatch({
//                 type: FETCH_SKILLS_SUCCESSFUL,
//                 payload: skills,
//             });

//         } catch (error) {
//             console.error('Error searching skills:', error);
//             dispatch({
//                 type: FETCH_SKILLS_FAILED,
//             });
//         }
//     };
// };

export const fetchCertificates = ({ searchQuery }) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_CERTIFICATES });

        try {
            assetIndex.setSettings({
                attributesForFaceting: [
                    'list' // or 'filterOnly(brand)' for filtering purposes only
                ]
            }).then(() => {
                // done
            });
            const {hits} = await assetIndex.search(searchQuery, {
                filters: 'list:certificate',
                hitsPerPage: 5
            });

            let certificates = [];
            hits.forEach(hit => {
                if (hit) {
                    if ("title" in hit) {
                        certificates.push(hit);
                    }
                }
            });

            dispatch({
                type: FETCH_CERTIFICATES_SUCCESSFUL,
                payload: certificates,
            });

        } catch (error) {
            console.error('Error searching certificates:', error);
            dispatch({
                type: FETCH_CERTIFICATES_FAILED,
            });
        }
    };
};

export const fetchLanguages = ({ searchQuery }) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_LANGUAGES });

        try {
            assetIndex.setSettings({
                attributesForFaceting: [
                    'list' // or 'filterOnly(brand)' for filtering purposes only
                ]
            }).then(() => {
                // done
            });
            const {hits} = await assetIndex.search(searchQuery, {
                filters: 'list:language',
                hitsPerPage: 5
            });

            let languages = [];
            hits.forEach(hit => {
                if (hit) {
                    if ("title" in hit) {
                        languages.push(hit);
                    }
                }
            });

            dispatch({
                type: FETCH_LANGUAGES_SUCCESSFUL,
                payload: languages,
            });

        } catch (error) {
            console.error('Error searching languages:', error);
            dispatch({
                type: FETCH_LANGUAGES_FAILED,
            });
        }
    };
};

export const fetchLicenses = ({ searchQuery }) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_LICENSES });

        try {
            assetIndex.setSettings({
                attributesForFaceting: [
                    'list' // or 'filterOnly(brand)' for filtering purposes only
                ]
            }).then(() => {
                // done
            });
            const {hits} = await assetIndex.search(searchQuery, {
                filters: 'list:license',
                hitsPerPage: 5
            });

            let licenses = [];
            hits.forEach(hit => {
                if (hit) {
                    if ("title" in hit) {
                        licenses.push(hit);
                    }
                }
            });

            dispatch({
                type: FETCH_LICENSES_SUCCESSFUL,
                payload: licenses,
            });

        } catch (error) {
            console.error('Error searching licenses:', error);
            dispatch({
                type: FETCH_LICENSES_FAILED,
            });
        }
    };
};

export const fetchCVLinks = ({ searchQuery }) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_LINKS });

        try {
            assetIndex.setSettings({
                attributesForFaceting: [
                    'list' // or 'filterOnly(brand)' for filtering purposes only
                ]
            }).then(() => {
                // done
            });
            const {hits} = await assetIndex.search(searchQuery, {
                filters: 'list:link',
                hitsPerPage: 5
            });

            let links = [];
            hits.forEach(hit => {
                if (hit) {
                    if ("title" in hit) {
                        links.push(hit);
                    }
                }
            });

            dispatch({
                type: FETCH_LINKS_SUCCESSFUL,
                payload: links,
            });

        } catch (error) {
            console.error('Error searching links:', error);
            dispatch({
                type: FETCH_LINKS_FAILED,
            });
        }
    };
};


//--------------------------------------- SHORTLIST

export const addToShortlist = ({ seeker, isCoverLetterRequested, shortlistInfo}) => {
    return(dispatch) => {
        dispatch({ type: ADD_TO_SHORTLIST });
        const userID = firebase.auth().currentUser.uid;
        firebase.firestore().collection("adminBucket").doc(userID).collection("shortlist").doc(seeker.seekerID)
            .set({ ...seeker, addedOn: new Date() })
            .then(() => {
                addSeekerShortlist({seeker, userID,isCoverLetterRequested, shortlistInfo, dispatch});
            })
            .catch(e => {
                dispatch({ type: ADD_TO_SHORTLIST_FAILED });
                console.log(e);
                message.warning(`Could not add ${seeker.firstName} to your shortlist`);
            })
    }
}

// async function addSeekerShortlist({seeker, userID, isCoverLetterRequested, shortlistInfo, dispatch}) {
//     firebase.firestore().collection("adminBucket").doc(userID).collection("highlights").doc(seeker.seekerID)
//     .set({ shortlist: true, seekerID: seeker.seekerID, shortlistDate: new Date() }, {merge: true})
//     .then(() => {
//         dispatch({ type: ADD_TO_SHORTLIST_SUCCESSFUL });
//         message.success(`Added ${seeker.firstName} to your shortlist`);

//         if (isCoverLetterRequested) {

//             sendCoverLetterRequest(seeker, shortlistInfo)
//             .then(() =>{

//                 fetchAdminHighlights();

//             })
            
//         }

//         fetchAdminHighlights();
//     })
//     .catch(e => {
//         dispatch({ type: ADD_TO_SHORTLIST_FAILED });
//         console.log(e);
//         message.warning(`Could not add ${seeker.firstName} to your shortlist`);
//     })
// }

async function addSeekerShortlist({ seeker, userID, isCoverLetterRequested, shortlistInfo, dispatch }) {
    try {
        // Add seeker to the shortlist
        await firebase.firestore().collection("adminBucket").doc(userID).collection("highlights").doc(seeker.seekerID)
            .set({ shortlist: true, seekerID: seeker.seekerID, shortlistDate: new Date() }, { merge: true });

        // Dispatch success action
        dispatch({ type: ADD_TO_SHORTLIST_SUCCESSFUL });
        message.success(`Added ${seeker.firstName} to your shortlist`);

        // Send cover letter request if needed, then fetch admin highlights
        if (isCoverLetterRequested) {
            await sendCoverLetterRequest(seeker, shortlistInfo);
        }

        // Fetch admin highlights
        fetchAdminHighlights();
    } catch (e) {
        // Dispatch failure action and log error
        dispatch({ type: ADD_TO_SHORTLIST_FAILED });
        console.error(e);
        message.warning(`Could not add ${seeker.firstName} to your shortlist`);
    }
}

async function sendCoverLetterRequest(seeker, shortlistInfo) {
    const requestID = Math.random().toString(36).substring(6).toUpperCase();
    const userID = firebase.auth().currentUser.uid;
    const coverLetterRequested = { coverLetterRequested: true };

    shortlistInfo.requestID = requestID;

    try {
        const docRef = firebase.firestore().collection("seekers").doc(seeker.seekerID).collection("coverLetters").doc(requestID);

        // Check if document exists and set it
        const doc = await docRef.get();
        if (doc.exists) {
            await docRef.set(shortlistInfo);
        } else {
            // Create a new document and set it
            await firebase.firestore().collection("seekers").doc(seeker.seekerID).collection("coverLetters").doc(requestID).set(shortlistInfo);
            message.success("Cover Letter Request Sent")
   
        }
            // Set coverLetterRequested in adminBucket highlights
          await firebase.firestore().collection("adminBucket").doc(userID).collection("shortlist").doc(seeker.seekerID).set(coverLetterRequested, { merge: true });
    } catch (error) {
        console.error("Error sending seeker request:", error);
    }
}

export const requestCoverLetter = (seeker, shortlistInfo) => {

    return async (dispatch) => {

        const requestID = Math.random().toString(36).substring(6).toUpperCase();
        const userID = firebase.auth().currentUser.uid;
        const coverLetterRequested = { coverLetterRequested: true };
        shortlistInfo.requestID = requestID;
    
        try {
            const docRef = firebase.firestore().collection("seekers").doc(seeker.seekerID).collection("coverLetters").doc(requestID);
    
            // Check if document exists and set it
            const doc = await docRef.get();
            if (doc.exists) {
                await docRef.set(shortlistInfo);
            } else {
                // Create a new document and set it
                await firebase.firestore().collection("seekers").doc(seeker.seekerID).collection("coverLetters").doc(requestID).set(shortlistInfo);
                message.success("Cover Letter Request Sent")
       
            }
                // Set coverLetterRequested in adminBucket highlights
              await firebase.firestore().collection("adminBucket").doc(userID).collection("shortlist").doc(seeker.seekerID).set(coverLetterRequested, { merge: true });
        } catch (error) {
            console.error("Error sending seeker request:", error);
        }
        
    }
};

export const fetchAdminHighlights = () => {

    return(dispatch) => {
        dispatch({ type: FETCH_ADMIN_HIGHLIGHTS });
        let seekerHighlights = [];
        const user = firebase.auth().currentUser;
        firebase.firestore().collection("adminBucket").doc(user.uid).collection("highlights")
            .onSnapshot(function(snapshot) {
                if (snapshot.size === 0) {
                    dispatch({ type: FETCH_ADMIN_HIGHLIGHTS_FAIL });
                } else {
                    snapshot.forEach(function(doc) {
                        seekerHighlights.push(doc.data());
                    });

                    const uniqueArray = seekerHighlights.filter((item, index, self) =>
                        index === self.findIndex(t => t.seekerID === item.seekerID)
                    );

                    dispatch({ type: FETCH_ADMIN_HIGHLIGHTS_SUCCESS, payload: uniqueArray});
                }
            }, function (error) {
                console.log("Error fetching recent searches");
                console.log(error);
                dispatch({ type: FETCH_ADMIN_HIGHLIGHTS_FAIL });
            });
    }
};


export const deleteFromShortlist = ({ seeker }) => {
    return(dispatch) => {
        dispatch({ type: DELETE_FROM_SHORTLIST });
        const userID = firebase.auth().currentUser.uid;
        firebase.firestore().collection("adminBucket").doc(userID).collection("shortlist").doc(seeker.seekerID)
            .delete()
            .then(() => {
                removeSeekerShortlist({seeker, userID, dispatch});
            })
            .catch(e => {
                dispatch({ type: DELETE_FROM_SHORTLIST_FAILED });
                console.log(e);
                message.warning(`Could not delete ${seeker.firstName} from your shortlist`);
            })
    }
}


async function removeSeekerShortlist({seeker, userID, dispatch}) {
    firebase.firestore().collection("adminBucket").doc(userID).collection("highlights").doc(seeker.seekerID)
    .set({ shortlist: false, seekerID: seeker.seekerID, recentShortlist: true, shortlistDate: new Date() }, {merge: true})
    .then(() => {
        dispatch({ type: DELETE_FROM_SHORTLIST_SUCCESSFUL });
        message.success(`Deleted ${seeker.firstName} from your shortlist`);
        fetchAdminHighlights();
        removeCoverLetterRequest(seeker, userID);
    })
    .catch(e => {
        dispatch({ type: DELETE_FROM_SHORTLIST_FAILED });
        console.log(e);
        message.warning(`Could not add ${seeker.firstName} to your shortlist`);
    })
}

async function removeCoverLetterRequest(seeker, userID) {
    try {
        const coverLetterQuerySnapshot = await firebase.firestore()
            .collection("seekers")
            .doc(seeker.seekerID)
            .collection("coverLetters")
            .where("adminID", "==", userID)
            .get();

        // Check if there are documents to delete
        if (coverLetterQuerySnapshot.empty) {
            console.log('No matching documents.');
            return;
        }

       
        const deletePromises = coverLetterQuerySnapshot.docs.map(doc => doc.ref.delete());
        
        // Wait for all delete operations to complete
        await Promise.all(deletePromises);

    } catch (error) {
        console.error('Error removing cover letter requests:', error);
      
    }
}



export const recentViewSeeker = (seeker) => {
    return () => {
        const userID = firebase.auth().currentUser.uid;

        firebase.firestore().collection("adminBucket").doc(userID).collection("highlights").doc(seeker.seekerID)
       .set({ recentView: true, seekerID: seeker.seekerID, recentViewDate: new Date() }, { merge: true })
       .then(() => {
            fetchAdminHighlights();
        })
       .catch(e => {
            console.error('Error updating or setting document:', e);
        });
    };
};


















































//TODO: DO NOT WAKE THIS MONSTER UP
// export const searchDB = ({
//                              packageIndex, openForRelocation, country, region,
//                              jobTypeArray, workScheduleArray, gender,
//                              maritalStatus, startBirthDate,
//                              endBirthDate, certificateID, jobTitle,
//                              educationLevel,
//                              currentEnrollment, yearOfCompletion, industryID,
//                              currentAtWork, language, license, militaryCountry,
//                              skill, link, resultsSearchLimit
//                          }) => {
//
//     console.log({ packageIndex, openForRelocation, country, region,
//         jobTypeArray, workScheduleArray, gender,
//         maritalStatus, startBirthDate,
//         endBirthDate, certificateID, jobTitle,
//         educationLevel,
//         currentEnrollment, yearOfCompletion, industryID,
//         currentAtWork, language, license, militaryCountry,
//         skill, link, resultsSearchLimit });
//     //allow cors
//     return async (dispatch) => {
//         dispatch({ type: SEARCH });
//         try {
//
//             let startBD = null;
//             let endBD = null;
//             if (startBirthDate !== null) {
//                 startBD = startBirthDate.toDate();
//             }
//             if (endBirthDate !== null) {
//                 endBD = endBirthDate.toDate();
//             }
//
//             const now = moment();
//             const searchMoment = now.format("DDMMYYYYHHmmss");
//             const userID = firebase.auth().currentUser.uid;
//             const searchRef = firebase.firestore().collection("adminBucket").doc(userID).collection("recentSearches").doc(searchMoment);
//             await searchRef.set({
//                 packageIndex, openForRelocation, country, region,
//                 jobTypeArray, workScheduleArray, gender,
//                 maritalStatus, startBirthDate: startBD,
//                 endBirthDate: endBD, certificateID, jobTitle,
//                 educationLevel,
//                 currentEnrollment, yearOfCompletion, industryID,
//                 currentAtWork, language, license, militaryCountry,
//                 skill, link, resultsSearchLimit,
//                 timestamp: now.toDate(),
//                 searchID: searchMoment
//             });
//
//             let query = firebase.firestore().collection("seekers");
//
//             if (packageIndex) {
//                 console.log("entered in package");
//                 //query = query.where("packageIndex", ">=", packageIndex);
//             }
//
//             if (openForRelocation === null) {
//                 //do nothing
//             } else {
//                 console.log("entered in open for relocation");
//                 query = query.where("openForRelocation", "==", openForRelocation);
//             }
//
//             if (country) {
//                 console.log("entered in country");
//                 query = query.where("country", "==", country);
//             }
//
//             if (region) {
//                 console.log("entered in region");
//                 query = query.where("location.regionCode", "==", region);
//             }
//
//             if (jobTypeArray && jobTypeArray.length > 0) {
//                 console.log("entered in jobtype");
//                 query = query.where("jobType", "array-contains-any", jobTypeArray);
//             }
//
//             if (workScheduleArray && workScheduleArray.length > 0) {
//                 console.log("entered in work schedule");
//                 query = query.where("workSchedule", "array-contains-any", workScheduleArray);
//             }
//
//             if (gender) {
//                 console.log("entered in gender");
//                 query = query.where("gender", "==", gender);
//             }
//
//             if (maritalStatus) {
//                 console.log("entered in maritalStatus");
//                 query = query.where("maritalStatus", "==", maritalStatus);
//             }
//
//             if (jobTitle) {
//                 console.log("entered in jobTitle");
//                 query = query.where("desiredJobTitle.jobID", "==", jobTitle);
//             }
//
//             // Convert the selected dates to Firestore Timestamps
//             const startBirthDateTimestamp = startBirthDate ? firebase.firestore.Timestamp.fromDate(startBirthDate.toDate()) : null;
//             const endBirthDateTimestamp = endBirthDate ? firebase.firestore.Timestamp.fromDate(endBirthDate.toDate()) : null;
//
//             if (startBirthDateTimestamp) {
//                 console.log("entered in start birthdate");
//                 query = query.where('birthdate', '>=', startBirthDateTimestamp);
//             }
//             if (endBirthDateTimestamp) {
//                 console.log("entered in end birthdate");
//                 query = query.where('birthdate', '<=', endBirthDateTimestamp);
//             }
//
//             let seekersQuerySnapshot;
//
//             if (startBirthDate !== null || endBirthDate !== null) {
//                 seekersQuerySnapshot = await query
//                     .where("complete", "==", true)
//                     .orderBy('birthdate')
//                     .orderBy("packageIndex")
//                     .limit(resultsSearchLimit)
//                     .get();
//             } else {
//                 seekersQuerySnapshot = await query
//                     .where("complete", "==", true)
//                     .orderBy("packageIndex")
//                     .limit(resultsSearchLimit)
//                     .get();
//             }
//
//             console.log({ size: seekersQuerySnapshot.size });
//             const seekers = [];
//             for (const seekerDoc of seekersQuerySnapshot.docs) {
//                 const seekerData = seekerDoc.data();
//
//                 const seekerID = seekerDoc.id;
//
//                 //certificates ----------------------------------------------------------
//                 let certificatesQuery = firebase.firestore()
//                     .collection("seekers")
//                     .doc(seekerID)
//                     .collection("certificates");
//
//                 let certificatesQuerySnapshot;
//                 if (certificateID === null) {
//                     //do nothing
//                 } else {
//                     if (certificateID) {
//                         certificatesQuery = certificatesQuery.where("certificateID", "==", certificateID);
//                     } else {
//                         // Exclude seekers without the specified certificateID
//                         certificatesQuery = certificatesQuery.limit(0);
//                     }
//                 }
//
//                 certificatesQuerySnapshot = await certificatesQuery.get();
//                 console.log({ certSize: certificatesQuerySnapshot.size });
//                 // Check if the seeker has certificates
//                 if (certificateID !== null) {
//                     if (certificatesQuerySnapshot.empty) {
//                         continue; // Skip the seeker if no certificates found
//                     }
//                 }
//
//                 //education ----------------------------------------------------------
//                 let educationQuery = firebase.firestore()
//                     .collection("seekers")
//                     .doc(seekerID)
//                     .collection("education")
//
//                 let educationQuerySnapshot;
//                 if (industryID === null) {
//                     //do nothing
//                 } else {
//                     if (industryID) {
//                         educationQuery = educationQuery.where("industry.industryID", "==", industryID);
//                     } else {
//                         // Exclude seekers without the specified industry ID
//                         educationQuery = educationQuery.limit(0);
//                     }
//                 }
//
//                 if (yearOfCompletion === null) {
//                     //do nothing
//                 } else {
//                     if (yearOfCompletion) {
//                         educationQuery = educationQuery.where("yearOfCompletion", "==", yearOfCompletion);
//                     } else {
//                         // Exclude seekers without the specified industry ID
//                         educationQuery = educationQuery.limit(0);
//                     }
//                 }
//
//                 if (educationLevel === null) {
//                     //do nothing
//                 } else {
//                     if (educationLevel) {
//                         educationQuery = educationQuery.where("educationLevel.industryID", "==", educationLevel);
//                     } else {
//                         // Exclude seekers without the specified industry ID
//                         educationQuery = educationQuery.limit(0);
//                     }
//                 }
//
//                 if (currentEnrollment === null) {
//                     //do nothing
//                 } else {
//                     educationQuery = educationQuery.where("currentEnrollment", "==", currentEnrollment);
//                 }
//
//                 educationQuerySnapshot = await educationQuery.get();
//                 if (industryID !== null || yearOfCompletion !== null || educationLevel !== null || currentEnrollment !== null) {
//                     // Check if the seeker has specified education parameters
//                     if (educationQuerySnapshot.empty) {
//                         continue; // Skip the seeker if no certificates found
//                     }
//                 }
//
//                 //employment ----------------------------------------------------------
//                 let employmentQuery = firebase.firestore()
//                     .collection("seekers")
//                     .doc(seekerID)
//                     .collection("employment")
//
//                 let employmentQuerySnapshot;
//                 if (currentAtWork === null) {
//                     //do nothing
//                 } else {
//                     employmentQuery = employmentQuery.where("currentWork", "==", currentAtWork)
//                 }
//
//                 employmentQuerySnapshot = await employmentQuery.get();
//                 if (currentAtWork !== null) {
//                     // Check if the seeker has specified employment parameters
//                     if (employmentQuerySnapshot.empty) {
//                         continue; // Skip the seeker if no certificates found
//                     }
//                 }
//
//                 //language ----------------------------------------------------------
//                 let languagesQuery = firebase.firestore()
//                     .collection("seekers")
//                     .doc(seekerID)
//                     .collection("languages")
//
//                 let languagesQuerySnapshot;
//                 if (language === null) {
//                     //do nothing
//                 } else {
//                     if (language) {
//                         languagesQuery = languagesQuery.where("languageID", "==", language);
//                     } else {
//                         // Exclude seekers without the specified industry ID
//                         languagesQuery = languagesQuery.limit(0);
//                     }
//                 }
//
//                 languagesQuerySnapshot = await languagesQuery.get();
//                 if (language !== null) {
//                     // Check if the seeker has specified language
//                     if (languagesQuerySnapshot.empty) {
//                         continue; // Skip the seeker if no language found
//                     }
//                 }
//
//
//                 //licenses ----------------------------------------------------------
//                 let licensesQuery = firebase.firestore()
//                     .collection("seekers")
//                     .doc(seekerID)
//                     .collection("licenses")
//
//                 let licensesQuerySnapshot;
//                 if (license === null) {
//                     //do nothing
//                 } else {
//                     if (license) {
//                         licensesQuery = licensesQuery.where("licenseCategory.licenseCategoryID", "==", license)
//                     } else {
//                         // Exclude seekers without the specified industry ID
//                         licensesQuery = licensesQuery.limit(0);
//                     }
//                 }
//
//                 licensesQuerySnapshot = await licensesQuery.get();
//                 if (license !== null) {
//                     // Check if the seeker has specified licenses
//                     if (licensesQuerySnapshot.empty) {
//                         continue; // Skip the seeker if no licenses found
//                     }
//                 }
//
//                 //military ----------------------------------------------------------
//                 let militaryQuery = firebase.firestore()
//                     .collection("seekers")
//                     .doc(seekerID)
//                     .collection("military")
//
//                 let militaryQuerySnapshot;
//                 if (militaryCountry === null) {
//                     //do nothing
//                 } else {
//                     if (militaryCountry) {
//                         militaryQuery = militaryQuery.where("country", "==", militaryCountry);
//                     } else {
//                         // Exclude seekers without the specified industry ID
//                         militaryQuery = militaryQuery.limit(0);
//                     }
//                 }
//
//                 militaryQuerySnapshot = await militaryQuery.get();
//                 if (militaryCountry !== null) {
//                     // Check if the seeker has specified military country
//                     if (militaryQuerySnapshot.empty) {
//                         continue; // Skip the seeker if no military country is found
//                     }
//                 }
//
//                 //skills ----------------------------------------------------------
//                 let skillsQuery = firebase.firestore()
//                     .collection("seekers")
//                     .doc(seekerID)
//                     .collection("skills")
//
//                 let skillsQuerySnapshot;
//                 if (skill === null) {
//                     //do nothing
//                 } else {
//                     if (skill) {
//                         skillsQuery = skillsQuery.where("skillID", "==", skill)
//                     } else {
//                         // Exclude seekers without the specified industry ID
//                         skillsQuery = skillsQuery.limit(0);
//                     }
//                 }
//
//                 skillsQuerySnapshot = await skillsQuery.get();
//                 if (skill !== null) {
//                     // Check if the seeker has specified skill
//                     if (skillsQuerySnapshot.empty) {
//                         continue; // Skip the seeker has no skill
//                     }
//                 }
//
//                 //links ----------------------------------------------------------
//                 let linksQuery = firebase.firestore()
//                     .collection("seekers")
//                     .doc(seekerID)
//                     .collection("links")
//
//                 let linksQuerySnapshot;
//                 if (link === null) {
//                     //do nothing
//                 } else {
//                     if (link) {
//                         linksQuery = linksQuery.where("linkID", "==", link)
//                     } else {
//                         // Exclude seekers without the specified industry ID
//                         linksQuery = linksQuery.limit(0);
//                     }
//                 }
//
//                 linksQuerySnapshot = await linksQuery.get();
//                 if (link !== null) {
//                     // Check if the seeker has specified skill
//                     if (linksQuerySnapshot.empty) {
//                         continue; // Skip the seeker has no skill
//                     }
//                 }
//
//                 //final compilation ----------------------------------------------------------
//
//                 const seeker = Object.assign({}, seekerData, {
//                     seekerID,
//                     education: educationQuerySnapshot.docs.map((doc) => {
//                         const ed = doc.data();
//                         return ed.industry.title;
//                     }),
//                     // certificates: certificatesQuerySnapshot.docs.map((doc) => doc.data()),
//                     // employment: employmentQuerySnapshot.docs.map((doc) => doc.data()),
//                     // languages: languagesQuerySnapshot.docs.map((doc) => doc.data()),
//                     // licenses: licensesQuerySnapshot.docs.map((doc) => doc.data()),
//                     // military: militaryQuerySnapshot.docs.map((doc) => doc.data()),
//                     // skills: skillsQuerySnapshot.docs.map((doc) => doc.data()),
//                     // links: linksQuerySnapshot.docs.map((doc) => doc.data())
//                 });
//
//                 seekers.push(seeker);
//             }
//
//             console.log({ seekers });
//             dispatch({
//                 type: SEARCH_SUCCESSFUL,
//                 payload: seekers
//             });
//         } catch (e) {
//             console.log(e);
//             dispatch({
//                 type: SEARCH_FAILED
//             });
//         }
//     };
// };
