import React from 'react';
import {connect} from 'react-redux';
import _ from "lodash";
import {message, Button, Input,Popconfirm } from "antd";
import {ClipLoader} from "react-spinners";
import moment from "moment";


//actions import
import {sendInterviewOffer, postsFieldChanged} from "../../../actions/PostsActions";

//components import
import {PickDate} from "../../common/PickDate";
import SelectPost from "./SelectPost";
import { MessageNotification01Icon } from 'hugeicons-react';


//property declarations
const { TextArea } = Input;


class InviteForInterview extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

            selectedCandidate: this.props.selectedCandidate,
            post: this.props.post,
            interviewDate: this.props.interviewDate,
            profile: this.props.profile,
            inviteComment: this.props.inviteComment,
            inviteInterviewLoading: this.props.inviteInterviewLoading,
            addToCalendar:false
        }
        this.onSelectPost = this.onSelectPost.bind(this);
        this.onSelectInterviewDate = this.onSelectInterviewDate.bind(this);
    
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps !== this.props) {
            this.setState({
                profile: nextProps.profile,
                selectedCandidate: nextProps.selectedCandidate,
                post: nextProps.post,
                interviewDate: nextProps.interviewDate,
                inviteComment: nextProps.inviteComment,
                inviteInterviewLoading: nextProps.inviteInterviewLoading
            })
        }
    }

    onSelectPost(value) {
        this.props.postsFieldChanged({prop: 'post', value })
    }

    onSelectInterviewDate(value) {
        this.props.postsFieldChanged({prop: 'interviewDate', value})
    }


    renderButton() {
        const {inviteInterviewLoading} = this.state;

        if (inviteInterviewLoading){
            return (
                <div style={{align: 'center', marginLeft: 230 }}>
                    <ClipLoader
                        size={20}
                    />
                </div>
            );
        } else {
            return (

                <div>
               
                <Popconfirm
                 title={
                  "Add this event on your Google Calendar ?"
                 }

                icon={<MessageNotification01Icon  />}

                onConfirm={() => {
                    this.setState({ addToCalendar: !this.state.addToCalendar }, () => {
                      // This callback function is executed after setState is completed
                      this.onAssign();
                    });
                   
                }}
                  
                onCancel={() => {
                    this.setState({ addToCalendar: false }, () => {
                      // This callback function is executed after setState is completed
                      this.onAssign();
                    });
                }}
                okText="Yes, Add & Invite"
                cancelText="No, Just Invite"
                overlayStyle={{ width: "400px" }}
            >
                <button className={"modalButton"}>
                    <span style={{ margin: "0.5rem", fontFamily: "ramblaRegular", color:"white" }}>Invite</span>
                </button>
            </Popconfirm>
            </div>
              
            );
        }

       
    }

  

    onAssign() {
     
            let { selectedCandidate, profile, interviewDate, post, inviteComment, addToCalendar } = this.state;
            let postValue;
            if (post) {
                postValue = post.value;
            }

            if(!(_.isEmpty(profile))) {
                if((profile.role === "admin") || (profile.role === "superAdmin")) {
                    //check if selected employee is not empty
                    if (!(_.isEmpty(selectedCandidate))) {
    
                        const userID = selectedCandidate.userID;
                        const firstName = selectedCandidate.firstName;
                        const lastName = selectedCandidate.lastName;
                        const email = selectedCandidate.email;
                        const name = `${firstName} ${lastName}`;
                        const acceptedInterviews = selectedCandidate.acceptedInterviews;
                        const rejectedInterviews = selectedCandidate.rejectedInterviews;
    
                        //check if fetched asset is not empty
                        if (interviewDate && postValue) {
    
                            const jobPost = JSON.parse(postValue);
    
                            let seconds;
                            jobPost.deadline.seconds? seconds = jobPost.deadline.seconds : seconds = jobPost.deadline._seconds;
                            const deadlineMoment = moment.unix(seconds);
                            const deadline = deadlineMoment.toDate();

                            console.log('candidate', selectedCandidate)
                           
                            this.props.sendInterviewOffer({
                                companyLocation: jobPost.companyLocation, companyName: jobPost.companyName, deadline, interviewDate: interviewDate.toDate(),
                                postTitle: jobPost.postTitle, selected: null, status: "pending", statusDate: new Date(), offeredOn: new Date(),
                                withdraw: false, postID: jobPost.postID, userID, firstName, acceptedInterviews, rejectedInterviews, name, iasRating: false, interviewed: false,
                                inviteComment, email, addToCalendar,selectedCandidate,post
                            })
                            this.setState({ addToCalendar: false})
                        } else {
                            message.warning('Please select a post and interview date');
                        }
                    } else {
                        message.warning("There is no candidate to invite");
                    }
                } else {
                    message.warning("Un-Authorized Operation");
                }
            }
     
    }
    

    render() {
        return (
            <div>
                <div className={"modalDetails"}>
                    <div>
                        <SelectPost
                            placeholder={'Select post to interview for'}
                            label={"Pick Post"}
                            value={this.state.post}
                            onChange={this.onSelectPost}
                        />
                    </div>
                    <div>
                        <PickDate
                            label={"Interview Date"}
                            onChange={this.onSelectInterviewDate}
                            value={this.state.interviewDate}
                        />
                    </div>
                </div>

                <div style={{ margin: "1rem 0"}}>
                    <p style={{ fontFamily: "ramblaBold", color: "#000" }}>Add in any short comment</p>
                    <TextArea
                        rows={3}
                        onChange={(event) => this.props.postsFieldChanged({ prop: "inviteComment", value: event.target.value })}
                        placeholder="Max length is 200 characters" maxLength={200}
                    />
                </div>
                <div  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}> 
                {this.renderButton()}        
                </div>

               
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    const {selectedCandidate, post, interviewDate, inviteInterviewLoading, inviteComment } = state.posts;
    const { profile } = state.profile;

    return {
        selectedCandidate,
        post,
        interviewDate,
        inviteInterviewLoading,
        profile,
        inviteComment
    }
};

export default connect(mapStateToProps, {sendInterviewOffer, postsFieldChanged})(InviteForInterview);