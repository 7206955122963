import React from 'react';
import {connect} from 'react-redux';
import _ from "lodash";
// import {Modal, Popconfirm} from "antd";
import moment from "moment";

//actions import

//components import
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ClipLoader} from "react-spinners";
import FeedbackCard from "./cards/FeedbackCard";


class Feedback extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			visible: false,
			profile: this.props.profile,
			feedback: this.props.feedback,
			loading: this.props.loading,
		}
	}


	componentWillReceiveProps(nextProps, nextContext) {
		if (nextProps !== this.props) {
			this.setState({
				profile: nextProps.profile,
				feedback: nextProps.feedback,
				loading: nextProps.loading,
			})
		}
	}

	renderFeedbacks() {
		const {feedback} = this.state;

		if (this.state.loading) {
			return <ClipLoader/>
		} else {
			const feedbackArray = _.orderBy(feedback, ['timestamp'], ['desc']);

			return (
				<div>
					<FeedbackCard items={feedbackArray}/>
				</div>
			);
		}
	}


	checkUser() {
		//extract state
		const { profile } = this.state;

		if(!(_.isEmpty(profile))) {
			if (profile.role === "superAdmin") {
				return (
					<div>
						<div className={"settingsAssetsBox"}>
							<div className={"addAssetsSettingsBox"}>
								<h4 style={{ fontFamily: "ramblaBold", color: "black" }}>Feedback</h4>
							</div>
						</div>
						<br/>
						<div className={"infoBox"}>
							<div className={"assetsDetailsBox"}>
								<div>
									{this.renderFeedbacks()}
								</div>
							</div>
						</div>
					</div>
				);
			}
		}
	}

	render() {
		return (
			<div className={'employeeToolContainer'}>
				{ this.checkUser() }
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	//-> extract state
	const { profile } = state.profile;
	const { feedback, loading } = state.settings;

	return{
		profile,
		feedback,
		loading
	}
}

export default connect(mapStateToProps, { })(Feedback);