import React, { Component } from 'react';
import { Select } from 'antd';
import { connect } from 'react-redux';
import { searchFieldChanged } from '../../../actions/SearchActions';

const { Option } = Select;

class RelocationFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openForRelocation: this.props.openForRelocation
        }

        this.handleChange = this.handleChange.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props) {
            this.setState({
                openForRelocation: nextProps.openForRelocation
            });
        }
    }

    handleChange(value) {
        if (value === undefined || value === "") {
            this.props.searchFieldChanged({ prop: "openForRelocation", value: null });
        } else {
            this.props.searchFieldChanged({ prop: "openForRelocation", value });
        }
    };

    render() {
        const { openForRelocation } = this.state;

        return (
            <div>
                <p style={{ color: "#000", fontFamily: "myriadBold", marginBottom: 3, textAlign: "left" }}>Relocation</p>
                <Select value={openForRelocation} allowClear onChange={this.handleChange} placeholder="Relocation" style={{ width: "100%", borderColor: '#000' }}>
                    <Option value={true}>Open</Option>
                    <Option value={false}>No</Option>
                </Select>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { openForRelocation } = state.search;

    return {
        openForRelocation
    };
};

const mapDispatchToProps = {
    searchFieldChanged, // Assuming you have an action creator to update the status
};

export default connect(mapStateToProps, mapDispatchToProps)(RelocationFilter);
