import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate} from "react-router-dom";
import { Table, Button, Modal, message, Popconfirm } from 'antd';


//-> action imports
import { pushSeeker, withdrawCandidate, postsFieldChanged } from "../../../actions/PostsActions";

//common imports
import {Spinner} from "../../common/Spinner";

//-> component import
import EditInviteForInterview from "../../shortlist/subComponents/EditInviteForInterview";
import { PropertyDeleteIcon } from "hugeicons-react";
import CustomHeaderCard from '../../search/cards/CustomHeaderCard'
 
class PostReviewCardBase extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            selectedPost: this.props.selectedPost,
            visible: false,
            inviteVisible: false,
            candidates: this.props.candidates,
            showLoading: false
        };

        this.onSelectCandidate = this.onSelectCandidate.bind(this);
        this.onWithdrawCandidate = this.onWithdrawCandidate.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps !== this.props) {
            this.setState({
                selectedPost: nextProps.selectedPost,
                candidates: nextProps.candidates,
            });
        }
    }

    componentWillUnmount() {
        this.setState({ showLoading: false });
    }

    showModal() {
        this.setState({
            visible: true,
        });
    };

    handleOk = () => {
        this.setState({
            visible: false,
        });
    };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };

    onSelectCandidate(candidate) {
        const {navigate} = this.props;

        this.setState({ showLoading: true });
        this.props.pushSeeker({ pushedSeeker: candidate });
        navigate("/seeker-profile");
    }

    onWithdrawCandidate(candidate) {
        const { selectedPost } = this.state;
        const interviewID = candidate.interviewID;
        const candidateID = candidate.userID;
        const status = candidate.withdraw;
        const postID = selectedPost.postID;

        this.props.withdrawCandidate({ interviewID, candidateID, postID, status: !status });
    }

    onCancel() {
        message.info("offer withdraw is canceled");
    }

    handleInterviewOk = () => {
        this.setState({
            inviteVisible: false,
        });
    };

    handleInterviewCancel = () => {
        this.setState({
            inviteVisible: false,
        });
    };

    editModal(candidate) {
        let seconds;
        candidate.interviewDate.seconds ? seconds = candidate.interviewDate.seconds : seconds = candidate.interviewDate._seconds;
        const dateMoment = moment.unix(seconds);

        this.props.postsFieldChanged({ prop: "interviewDate", value: dateMoment });
        this.props.postsFieldChanged({ prop: "inviteComment", value: candidate.inviteComment });

        this.setState({ inviteVisible: true });
    }

    renderCandidate(){
        //extract state
        const { candidates, showLoading } = this.state;

        let processedCandidates = candidates.map(candidate => {
            let seconds;
            candidate.statusDate.seconds ? seconds = candidate.statusDate.seconds : seconds = candidate.statusDate._seconds;
            const statusDate = moment.unix(seconds).format('ddd, Do MMM YYYY');

            let interviewSeconds;
            candidate.interviewDate.seconds ? interviewSeconds = candidate.interviewDate.seconds : interviewSeconds = candidate.interviewDate._seconds;
            const interviewDateFormat = moment.unix(interviewSeconds).format('ddd, Do MMM YYYY');
            
            return { ...candidate, statusDate, interviewDateFormat };
        });


        
        const columns = [
            {
                title: <CustomHeaderCard>Name</CustomHeaderCard>,
                dataIndex: 'name',
                key: 'name',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.name}</div>
                ),
            },
            {
                title: <CustomHeaderCard>Status</CustomHeaderCard>,
                dataIndex: 'status',
                key: 'status',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.status}</div>
                ),
            },
            {
                title: <CustomHeaderCard>Status Date</CustomHeaderCard>,
                dataIndex: 'statusDate',
                key: 'statusDate',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.statusDate}</div>
                ),
            },
            {
                title: <CustomHeaderCard>Interview Date</CustomHeaderCard>,
                key: 'interviewDateFormat',
                render: (_, record) => {
                  return (
                        <div>
                            <div 
                                onClick={() => this.editModal(record)}
                                style={{ cursor: 'pointer', color: "#0793d9" }}
                            >
                                {record.interviewDateFormat}
                            </div>
                            <Modal
                                title="Invite for Interview"
                                visible={this.state.inviteVisible}
                                onOk={this.handleInterviewOk}
                                onCancel={this.handleInterviewCancel}
                                footer={null}
                                width={900}
                            >
                                <EditInviteForInterview selectedCandidate={record} />
                            </Modal>
                        </div>
                    );
                }
            },     
            {
                title: <CustomHeaderCard>Interviewed</CustomHeaderCard>,
                dataIndex: 'interviewed',
                key: 'interviewed',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.interviewed ? "Done" : "Not Done"}</div>
                ),
            },  
            {
                title: <CustomHeaderCard>Selected</CustomHeaderCard>,
                dataIndex: 'selected',
                key: 'selected',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.selected ? "Yes" : "No"}</div>
                ),
            },
            {
                title: <CustomHeaderCard>Acc. Int</CustomHeaderCard>,
                dataIndex: 'acceptedInterviews',
                key: 'acceptedInterviews',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.acceptedInterviews}</div>
                ),
            },
            {
                title: <CustomHeaderCard>Rej. Int</CustomHeaderCard>,
                dataIndex: 'rejectedInterviews',
                key: 'rejectedInterviews',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.rejectedInterviews}</div>
                ),
            }, 
            {
                title: <CustomHeaderCard>I.A.S Rating</CustomHeaderCard>,
                dataIndex: 'iasRating',
                key: 'iasRating',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.iasRating ? "Done" : "Not Done"}</div>
                ),
            },                
            {
                title: <CustomHeaderCard>Profile</CustomHeaderCard>,
                key: 'profile',
                render: (_, record) => (
                    <FontAwesomeIcon icon="user" color="#3294ff" size="1x" onClick={() => this.onSelectCandidate(record)} style={{ cursor: "pointer" }} />
                ),
            },
            {
                title: <CustomHeaderCard>Delete</CustomHeaderCard>,
                key: 'delete',
                render: (_, record) => {
                    return (
                        <Popconfirm
                            title="Are you sure to delete this post?"
                            onConfirm={() => this.onWithdrawCandidate(record)}
                            onCancel={() => this.onCancel()}
                            okText="Yes"
                            cancelText="No"
                        >
                            {
                                record.withdraw ?
                                    <div style={{textAlign: 'center'}}><PropertyDeleteIcon size={20} color="green" /></div>
                                    :
                                    <div style={{textAlign: 'center'}}><PropertyDeleteIcon size={20} color="red" /></div> 
                            }
                        </Popconfirm>
                    );
                }
            }
        ];
    
        return (
            <>
                <Table dataSource={processedCandidates} columns={columns} rowKey="id" />
            </>
        );

        // //extract time
        // let seconds;
        // candidate.statusDate.seconds ? seconds = candidate.statusDate.seconds : seconds = candidate.statusDate._seconds;
        // const statusDate = moment.unix(seconds).format('ddd, Do MMM YYYY');

        // //extract time
        // let interviewSeconds;
        // candidate.interviewDate.seconds ? interviewSeconds = candidate.interviewDate.seconds : interviewSeconds = candidate.interviewDate._seconds;
        // const interviewDate = moment.unix(interviewSeconds).format('ddd, Do MMM YYYY');

        // let text = "Are you sure to withdraw this offer?";
        // if (candidate.withdraw) {
        //     text = "Are you sure to reinstate this offer?";
        // }

        // return(
        //     <div key={candidate.candidateID} className={"candidatesValuesBox"}>
        //         <div><p style={{ color: "black"}}>{candidate.name}</p></div>
        //         <div><p style={{ color: "black"}}>{candidate.status}</p></div>
        //         <div><p style={{ color: "black"}}>{statusDate}</p></div>
        //         <div>
        //             <p onClick={ () => this.editModal() } style={{cursor: 'pointer', color: "#0793d9"}}>{interviewDate}</p>
        //             <Modal
        //                 title="Invite for Interview"
        //                 open={this.state.inviteVisible}
        //                 onOk={this.handleInterviewOk}
        //                 onCancel={this.handleInterviewCancel}
        //                 footer={null}
        //                 width={900}
        //             >
        //                 <div>
        //                     <EditInviteForInterview selectedCandidate={candidate} />
        //                 </div>
        //             </Modal>
        //         </div>
        //         <div><p style={{ color: "black"}}>{candidate.interviewed ? "Done" : "Not Done"}</p></div>
        //         <div><p style={{ color: "black"}}>{candidate.selected ? "Yes" : "No"}</p></div>
        //         <div><p style={{ color: "black"}}>{candidate.acceptedInterviews}</p></div>
        //         <div><p style={{ color: "black"}}>{candidate.rejectedInterviews}</p></div>
        //         <div><p style={{ color: "black"}}>{candidate.iasRating ? "Done" : "Not Done"}</p></div>
        //         <div className={"dayOffRequestBox"}>
        //             <div>
        //                 {
        //                     showLoading ?
        //                         <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "1rem" }}>
        //                             <Spinner/>
        //                         </div>
        //                         : <p onClick={ () => this.onSelectCandidate(candidate) } style={{cursor: 'pointer', color: "#0793d9"}}>Go to Profile</p>
        //                 }
        //             </div>
        //         </div>
        //         <div>
        //             <Popconfirm
        //                 title={text}
        //                 onConfirm={() => this.onWithdrawCandidate(candidate)}
        //                 onCancel={() => this.onCancel()}
        //                 okText="Yes"
        //                 cancelText="No"
        //             >
        //                 {
        //                     candidate.withdraw ?
        //                         <div style={{textAlign: 'center', marginTop: 15}}><PropertyDeleteIcon size={20} color="green" className={"trashIcon2"}/></div>
        //                         :
        //                         <div style={{textAlign: 'center', marginTop: 15}}><PropertyDeleteIcon size={20} color="red" className={"trashIcon2"} /></div> 
        //                 }
        //             </Popconfirm>
        //         </div>
        //     </div>
        // );
    }

    render() {
        return(
            <div>
                {this.renderCandidate()}
            </div>
        );
    }
}

const mapStateToProps = ( state ) => {
    const { selectedPost } = state.posts;

    return {
        selectedPost
    }
};

// Wrapper functional component to use hooks
function PostReviewCard(props) {
    const navigate = useNavigate();

    return <PostReviewCardBase {...props} navigate={navigate} />;
}

export default connect(mapStateToProps,{withdrawCandidate, pushSeeker, postsFieldChanged})(PostReviewCard);