import firebase from "firebase";
//-> Message Alert Icons
import { sendIcon } from '../components/common/MessageAlertIcons';

import {
    FETCH_MY_SUPPORT_TICKETS,
    FETCH_MY_SUPPORT_TICKETS_FAILED,
    FETCH_MY_SUPPORT_TICKETS_SUCCESSFUL,
    FETCH_PAYMENTS,
    FETCH_PAYMENTS_FAILED,
    FETCH_PAYMENTS_SUCCESSFUL,
    FETCH_SUPPORT_TICKETS,
    FETCH_SUPPORT_TICKETS_FAILED,
    FETCH_SUPPORT_TICKETS_SUCCESSFUL,
    REGISTER_READ_REPORT,
    REGISTER_READ_REPORT_FAILED,
    REGISTER_READ_REPORT_SUCCESSFUL,
    SEND_SUPPORT_TICKET,
    SEND_SUPPORT_TICKET_FAILED,
    SEND_SUPPORT_TICKET_SUCCESSFUL,
    SUPPORT_DETAILS_CHANGED,
    UPDATE_PAYMENT_STATUS,
    UPDATE_PAYMENT_STATUS_FAILED,
    UPDATE_PAYMENT_STATUS_SUCCESSFUL,
    UPDATE_SUPPORT_TICKET,
    UPDATE_SUPPORT_TICKET_FAILED,
    UPDATE_SUPPORT_TICKET_SUCCESSFUL
} from "./Types";
import {message} from "antd";

export const supportDetailsChanged = ({ prop, value }) => {

    return {
        type: SUPPORT_DETAILS_CHANGED,
        payload: { prop, value }
    };

};

export const sendSupportTicket = ({ ticketSubject, relatedService, priority, ticketMessage, employeeName, locationID, employeeDepartment }) => {

    //grab user ID
    const userID = firebase.auth().currentUser.uid;
    //create payment ID
    const ticketID = Math.random().toString(36).substring(6).toUpperCase();
    //log a date object
    const openTime = new Date();
    //create an open status
    const status = "open";

    return(dispatch) => {
        dispatch({ type: SEND_SUPPORT_TICKET });

        firebase.firestore().collection("supportTickets").doc(ticketID)
            .set({ userID, ticketID, ticketSubject, relatedService, priority, ticketMessage, employeeName, openTime, status, locationID, employeeDepartment }, { merge: true })
            .then(() => {
                console.log("successfully sent support ticket");
                getAllSupportTickets({locationID, dispatch});
                getMySupportTickets({userID, dispatch});
                message.success({
                    content:" Yay, Tickets' successfully sent, help is on the way!",
                    duration: 5,
                    icon: sendIcon(),
                });
                dispatch({ type: SEND_SUPPORT_TICKET_SUCCESSFUL });
            }).catch(e => {
                console.log(e);
                dispatch({ type: SEND_SUPPORT_TICKET_FAILED });
        })
    };
};


//------------------------------------------------------------ FETCH MY SUPPORT TICKETS -----------------------------------------------------//

export const fetchMySupportTickets = () => {
    //grab the logged in userID
    const userID = firebase.auth().currentUser.uid;

    return(dispatch) => {
        //
        getMySupportTickets({userID, dispatch})
    }
};

function getMySupportTickets({userID, dispatch}) {

    dispatch({ type: FETCH_MY_SUPPORT_TICKETS });

    let mySupportTickets = {};

    firebase.firestore().collection("supportTickets").where("userID", "==", userID)
        .onSnapshot(function(querySnapshot) {

            querySnapshot.forEach(function(doc) {
                mySupportTickets[doc.id] = doc.data();
            });

            dispatch({
                type: FETCH_MY_SUPPORT_TICKETS_SUCCESSFUL,
                payload: mySupportTickets
            });

        }, function (error) {
            console.log("Error fetching my support tickets");
            console.log(error);
            dispatch({ type: FETCH_MY_SUPPORT_TICKETS_FAILED });
        });
}


//------------------------------------------------------------ FETCH MY ALL TICKETS -----------------------------------------------------//

export const fetchSupportTickets = ({ locationID }) => {
    return(dispatch) => {
        //
        getAllSupportTickets({locationID, dispatch});
    }
};

function getAllSupportTickets({locationID, dispatch}) {

    dispatch({ type: FETCH_SUPPORT_TICKETS });

    let supportTickets = {};

    firebase.firestore().collection("supportTickets").where("locationID", "==", locationID)
        .onSnapshot(function(querySnapshot) {

            querySnapshot.forEach(function(doc) {
                supportTickets[doc.id] = doc.data();
            });

            dispatch({
                type: FETCH_SUPPORT_TICKETS_SUCCESSFUL,
                payload: supportTickets
            });

        }, function (error) {
            console.log("Error fetching support tickets");
            console.log(error);
            dispatch({ type: FETCH_SUPPORT_TICKETS_FAILED });
        });
}

export const fetchAllSupportTickets = () => {
    return(dispatch) => {
        //
        getSupportTickets({dispatch});
    }
};

function getSupportTickets({dispatch}) {

    dispatch({ type: FETCH_SUPPORT_TICKETS });

    let supportTickets = {};

    firebase.firestore().collection("supportTickets")
        .onSnapshot(function(querySnapshot) {

            querySnapshot.forEach(function(doc) {
                supportTickets[doc.id] = doc.data();
            });

            dispatch({
                type: FETCH_SUPPORT_TICKETS_SUCCESSFUL,
                payload: supportTickets
            });

        }, function (error) {
            console.log("Error fetching support tickets");
            console.log(error);
            dispatch({ type: FETCH_SUPPORT_TICKETS_FAILED });
        });
}


//------------------------------------------------------------ UPDATE TICKET STATUS -----------------------------------------------------//

export const updateSupportTicketStatus = ({ ticketID, status }) => {

    //grab user ID
    const userID = firebase.auth().currentUser.uid;

    return(dispatch) => {
        dispatch({ type: UPDATE_SUPPORT_TICKET });

        //create update object
        const updates = {
            status
        };

        //if status is closed, register close time
        if (status === "closed") {
            updates["closeTime"] = new Date();
        }

        firebase.firestore().collection("supportTickets").doc(ticketID)
            .update(updates)
            .then(() => {
                getAllSupportTickets({dispatch});
                getMySupportTickets({userID,dispatch});
                dispatch({ type: UPDATE_SUPPORT_TICKET_SUCCESSFUL });
                message.success("support ticket updated successfully");
            })
            .catch(error => {
                console.log(error);
                dispatch({ type: UPDATE_SUPPORT_TICKET_FAILED });
            })

    };
};

export const registerReadReport = (ticketID) => {

    //grab user ID
    const userID = firebase.auth().currentUser.uid;

    return(dispatch) => {
        dispatch({ type: REGISTER_READ_REPORT });

        const readTime = new Date();

        firebase.firestore().collection("supportTickets").doc(ticketID)
            .update({ readReport: true, readTime })
            .then(() => {
                getAllSupportTickets({dispatch});
                getMySupportTickets({userID,dispatch});
                dispatch({ type: REGISTER_READ_REPORT_SUCCESSFUL });
            })
            .catch(error => {
                console.log(error);
                dispatch({ type: REGISTER_READ_REPORT_FAILED });
            })

    };
};