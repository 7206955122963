import React, { Component } from 'react';
import {connect} from "react-redux";
import {Select, Button, message, Switch} from 'antd';
// import 'antd/dist/antd.css'; // Import Ant Design styles

//action imports
import {extractIncompleteSignUps, extractPaidCompleteSignUps, extractUnPaidCompleteSignUps, extractCompleteSignUps,
	extractLastLogins, extractAllSignUps } from "../../../actions/SettingsActions";

//common imports
import {Spinner} from '../../common/Spinner';

class UserDataExtractor extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedOption: null,
			email: false,
			plus: false,
			loading: this.props.loading
		};
	}

	componentWillReceiveProps(nextProps, nextContext) {
		if (this.props !== nextProps) {
			this.setState({
				loading: nextProps.loading
			})
		}
	}

	handleSelectionChange = (value) => {
		this.setState({ selectedOption: value });
	}

	extractData = () => {
		const { selectedOption } = this.state;
		switch(selectedOption) {
			case 'incompleteSignUps':
				return this.handleIncompleteSignUps();
			case 'paidCompleteSignUps':
				return this.handlePaidCompleteSignUps();
			case 'unpaidCompleteSignUps':
				return this.handleUnpaidCompleteSignUps();
			case 'allCompleteSignUps':
				return this.handleAllCompleteSignUps();
			case 'inactiveUsers':
				return this.handleInactiveUsers();
			case 'allUsers':
				return this.handleAllUsers();
			default:
				message.info('Please select an option.');
		}
	}

	handleIncompleteSignUps = () => {
		const { email, plus } = this.state;
		message.info('Extracting Incomplete SignUps...');
		// Implement your logic here
		this.props.extractIncompleteSignUps({ email, plus });
	}

	handlePaidCompleteSignUps = () => {
		const { email, plus } = this.state;
		message.info('Extracting Paid Complete SignUps...');
		// Implement your logic here
		this.props.extractPaidCompleteSignUps({ email, plus });
	}

	handleUnpaidCompleteSignUps = () => {
		const { email, plus } = this.state;
		message.info('Extracting Unpaid Complete SignUps...');
		// Implement your logic here
		this.props.extractUnPaidCompleteSignUps({ email, plus });
	}

	handleAllCompleteSignUps = () => {
		const { email, plus } = this.state;
		message.info('Extracting All Complete SignUps...');
		// Implement your logic here
		this.props.extractCompleteSignUps({ email, plus });
	}

	handleInactiveUsers = () => {
		const { email, plus } = this.state;
		message.info('Extracting Inactive Users...');
		// Implement your logic here
		this.props.extractLastLogins({ email, plus });
	}

	handleAllUsers = () => {
		const { email, plus } = this.state;
		message.info('Extracting All Users...');
		// Implement your logic here
		console.log(email);
		this.props.extractAllSignUps({ email, plus });
	}

	renderButton = () => {
		if (this.state.loading) {
			return <Spinner/>
		} else {
			return <Button onClick={this.extractData} style={{ marginLeft: 8 }}>Extract</Button>
		}
	}

	render() {
		console.log({ plus: this.state.plus });
		return (
			<div>
				<Select
					style={{ width: 240 }}
					onChange={this.handleSelectionChange}
					placeholder="Select a user group"
				>
					<Select.Option value="incompleteSignUps">Incomplete SignUps</Select.Option>
					<Select.Option value="paidCompleteSignUps">Paid Complete SignUps</Select.Option>
					<Select.Option value="unpaidCompleteSignUps">Unpaid Complete SignUps</Select.Option>
					<Select.Option value="allCompleteSignUps">All Complete SignUps</Select.Option>
					<Select.Option value="inactiveUsers">Inactive Users</Select.Option>
					<Select.Option value="allUsers">All Users</Select.Option>
				</Select>
				<div style={{ margin: "1rem", padding: "0.5rem"}}>
					<Switch
						checkedChildren="Email"
						unCheckedChildren="Phone"
						onChange={() => this.setState({ email: !this.state.email })}
						checked={this.state.email}
					/>
				</div>
				<div style={{ margin: "1rem", padding: "0.5rem"}}>
					<Switch
						checkedChildren="Plus"
						unCheckedChildren="No Plus"
						onChange={() => this.setState({ plus: !this.state.plus })}
						checked={this.state.plus}
					/>
				</div>
				{this.renderButton()}
			</div>
		);
	}
}

const mapStateToProps = state => {
	const { loading } = state.settings;

	return {
		loading
	}
}

export default connect(mapStateToProps, { extractIncompleteSignUps, extractPaidCompleteSignUps, extractUnPaidCompleteSignUps,
	extractCompleteSignUps, extractLastLogins, extractAllSignUps })(UserDataExtractor);
