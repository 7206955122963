import React from 'react';
import {connect} from 'react-redux';
import _ from "lodash";
import {message, Button, Input } from "antd";
import {ClipLoader} from "react-spinners";
import moment from "moment";

//actions import
import {editInterviewOffer, postsFieldChanged} from "../../../actions/PostsActions";

//components import
import {PickDate} from "../../common/PickDate";

//property declarations
const { TextArea } = Input;


class EditInviteForInterview extends React.Component {

	constructor(props) {
		super(props);

		this.state = {

			selectedCandidate: this.props.selectedCandidate,
			selectedPost: this.props.selectedPost,
			interviewDate: this.props.interviewDate,
			profile: this.props.profile,
			inviteComment: this.props.inviteComment,
			inviteInterviewLoading: this.props.inviteInterviewLoading,
		}

		this.onSelectInterviewDate = this.onSelectInterviewDate.bind(this);
	}

	componentWillReceiveProps(nextProps, nextContext) {

		if (nextProps !== this.props) {
			this.setState({
				profile: nextProps.profile,

				selectedCandidate: nextProps.selectedCandidate,

				selectedPost: nextProps.selectedPost,
				interviewDate: nextProps.interviewDate,
				inviteComment: nextProps.inviteComment,
				inviteInterviewLoading: nextProps.inviteInterviewLoading
			})
		}
	}

	onSelectInterviewDate(value) {
		this.props.postsFieldChanged({prop: 'interviewDate', value})
	}

	renderButton() {
		const {inviteInterviewLoading} = this.state;
		//
		if (inviteInterviewLoading){
			return (
				<div style={{align: 'center', marginLeft: 330 }}>
					<ClipLoader
						size={20}
					/>
				</div>
			);
		} else {
			return (
				<div className={"modalButton"}>
					<Button type="default" onClick={() => this.onAssign()}>
						<span style={{ marginTop: "0.15rem", fontFamily: "ramblaRegular"}}>Update</span>
					</Button>
				</div>
			);
		}
	}

	onAssign() {
		let { selectedCandidate, profile, interviewDate, selectedPost, inviteComment } = this.state;

		if(!(_.isEmpty(profile))) {
			if((profile.role === "admin") || (profile.role === "superAdmin")) {
				//check if selected employee is not empty
				if (!(_.isEmpty(selectedCandidate))) {

					const userID = selectedCandidate.userID;
					const name = selectedCandidate.name;
					const firstName = name.split(" ")[0];

					//check if fetched asset is not empty
					if (interviewDate && !(_.isEmpty(selectedPost)) && inviteComment) {

						const companyName = selectedPost.companyName;

						this.props.editInterviewOffer({
							interviewID: selectedCandidate.interviewID,
							updatedOn: new Date(),
							interviewDate: interviewDate.toDate(),
							inviteComment,
							postID: selectedPost.postID,
							userID,
							firstName,
							companyName
						});

					} else {
						message.warning('Please select an interview date and write a comment');
					}
				} else {
					message.warning("There is no candidate to invite");
				}
			} else {
				message.warning("Un-Authorized Operation");
			}
		}
	}

	render() {
		const { selectedPost, selectedCandidate, inviteComment } = this.state;

		let seconds;
		selectedCandidate.interviewDate.seconds ? seconds = selectedCandidate.interviewDate.seconds : seconds = selectedCandidate.interviewDate._seconds;
		const dateMoment = moment.unix(seconds);

		return (
			<div>
				<div className={"modalDetails"}>
					<div>
						<p style={{ fontFamily: "ramblaBold", color: "#000" }}>{selectedPost ? selectedPost.postTitle : null}</p>
					</div>
					<div>
						<p style={{ fontFamily: "ramblaBold", color: "green", textAlign: 'left' }}>{dateMoment.format("DD / MM / YYYY | HH:mm:ss")}</p>
						<PickDate
							label={"New Interview Date"}
							onChange={this.onSelectInterviewDate}
							value={this.state.interviewDate}
						/>
					</div>
				</div>

				<div style={{ margin: "1rem 0"}}>
					<p style={{ fontFamily: "ramblaBold", color: "#000" }}>New short comment</p>
					<TextArea
						rows={3}
						onChange={(event) => this.props.postsFieldChanged({ prop: "inviteComment", value: event.target.value })}
						placeholder="Max length is 200 characters" maxLength={200}
						value={inviteComment}
					/>
				</div>

				{this.renderButton()}
			</div>
		);
	}
}

const mapStateToProps = (state) => {

	const {selectedPost, interviewDate, inviteInterviewLoading, inviteComment} = state.posts;
	const { profile } = state.profile;

	return {
		selectedPost,
		interviewDate,
		inviteInterviewLoading,
		profile,
		inviteComment
	}
};

export default connect(mapStateToProps, {editInterviewOffer, postsFieldChanged})(EditInviteForInterview);