import React from 'react';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import { FileAttachmentIcon } from "hugeicons-react";
import { Tabs, message } from 'antd';
import SupportTickets from "./subComponents/SupportTickets";
import _ from "lodash";
const XLSX = require('xlsx');

//actions import
import { uploadSkillsSet } from '../../actions/ReportActions';

//common imports
import { Button } from '../common/Button';
import {Spinner} from "../common/Spinner";
import {Folder02Icon} from "hugeicons-react";

//components import


class Reports extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            systemInfo: this.props.systemInfo,
            skill: [],
        }

        this.onUploadClick = this.onUploadClick.bind(this);

    }

    componentDidMount() {


    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props) {
            this.setState({
                systemInfo: nextProps.systemInfo
            });
        }
    }

    handleFileChange = (e) => {
        const reader = new FileReader();
        reader.readAsBinaryString(e.target.files[0]);

        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: 'binary' });
            const SheetNames = workbook.SheetNames[0];
            const sheet = workbook.Sheets[SheetNames];
            const parsedData = XLSX.utils.sheet_to_json(sheet);
            this.setState({skill: parsedData});
        }
    };

    onUploadClick() {
        const { skill } = this.state;
        if (skill.length !== 0) {
            console.log("running.....")
            this.props.uploadSkillsSet(this.state.skill);
        } else {
            message.warning('Select a file to upload');
        }
    }


    renderButton () {
        return (
            <div className={"allLeaveRequestsButtonBox"}>
                <Button
                    text={"Upload"}
                    onPress={() => this.onUploadClick()}
                />
            </div>
        );
    }

    

    renderComponent() {
        //
        const { TabPane } = Tabs;
        //extract state
        const { systemInfo } = this.state;

        if (!(_.isEmpty(systemInfo))) {
            if ("reports" in systemInfo) {
                if (systemInfo.reports) {
                    return (
                        <div style={{ marginTop: "0.5rem"}}>
                            <Tabs
                                // onChange={this.callBack()}
                                defaultActiveKey="1"
                                type="line"
                                tabBarStyle={{
                                    minHeight: '100vh',
                                    backgroundColor: '#ecedff',
                                    borderBottom: '0px',
                                    fontFamily: 'avenirBlack',
                                    color: '#2d7cbd',
                                    padding: '0.1rem 0 0.1rem 0',
                                }}
                                tabBarGutter="1"
                                size="small"
                                tabPosition='top'
                                animated={{inkBar: true}}
                            >
                                <TabPane tab="Support" key="1"><SupportTickets/></TabPane>
                                {/*<TabPane tab="Payments" key="2"></TabPane>*/}
                                {/*<TabPane tab="Leaves" key="3"><Leaves/></TabPane>*/}
                            </Tabs>
                        </div>
                    )
                } else {
                    return <h3 className={"adminAlert"}>Your reports will appear here</h3>
                }
            } else {
                // return(
                //     <div>
                //         <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                //             <h5 style={{textAlign: "center", color: '#000'}}>Upload excel file</h5>
                //             <input type="file" accept='.xlsx, .xls' onChange={this.handleFileChange} />
                //             <FileAttachmentIcon size={40} style={{ marginBottom: '1.5rem', marginRight: '1rem', alignItems: "center" }}/>
                //         </div>
                //         <div className={"masterFileUploadButton"}>
                //             {this.renderButton()}
                //         </div>
                //     </div>
                // );
                return <h3 className={"adminAlert"}>Your reports will appear here</h3>
            }
        } else {
            return <Spinner/>
        }
    }

    render() {
        console.log("file:", this.state.skill);

        return(
            <div className={'reportsContainer'}>
                <div className={"topBarBox"}>
                    <div className={"childHeaderBarBox"}>
                        <Folder02Icon size={30} style={{ color: "#000"}} />
                        <h3>Your Reports</h3>
                    </div>
                </div>

                <div className={"reportsTicketsBox"}>
                    {this.renderComponent()}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { systemInfo } = state.system

    return {
        systemInfo
    }
};

export default connect(mapStateToProps,{ uploadSkillsSet })(Reports);