import React from 'react';
import { Input, AutoComplete } from 'antd';
import { connect } from 'react-redux';
import { searchFieldChanged, fetchJobTitles } from '../../../actions/SearchActions';

class JobTitleFilter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            jobTitlesSearchQuery: this.props.jobTitlesSearchQuery,
            jobTitles: this.props.jobTitles
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props) {
            this.setState({
                jobTitlesSearchQuery: nextProps.jobTitlesSearchQuery,
                jobTitles: nextProps.jobTitles
            });
        }
    }

    handleSearch = (value) => {
        this.props.searchFieldChanged({ prop: "jobTitlesSearchQuery", value });
        this.props.fetchJobTitles({ searchQuery: value });
    };

    handleSelect = (value, option) => {
        const { objectID } = option.data;
        this.props.searchFieldChanged({ prop: "jobTitlesSearchQuery", value });
        this.props.searchFieldChanged({ prop: "jobTitle", value: objectID });
    };

    handleClear = () => {
        this.props.searchFieldChanged({ prop: "jobTitlesSearchQuery", value: "" });
        this.props.searchFieldChanged({ prop: "jobTitle", value: null });
    }

    render() {
        const { jobTitlesSearchQuery, jobTitles } = this.state;

        return (
            <div>
                <p style={{ color: "#000", fontFamily: "myriadBold", marginBottom: 3, textAlign: "left" }}>Desired Job Title</p>
                <AutoComplete
                    value={jobTitlesSearchQuery}
                    allowClear
                    onClear={this.handleClear}
                    onChange={this.handleSearch}
                    onSelect={this.handleSelect}
                    options={jobTitles.map((result) => ({
                        value: result.title,
                        label: result.title,
                        data: {
                            title: result.title,
                            objectID: result.objectID,
                        },
                    }))} // Convert search results to options format
                    style={{ width: "100%", borderColor: '#000' }}
                >
                    <Input.Search placeholder="Search Job Title" />
                </AutoComplete>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { jobTitlesSearchQuery, jobTitles } = state.search;
    return {
        jobTitlesSearchQuery, jobTitles
    };
};

const mapDispatchToProps = {
    searchFieldChanged, fetchJobTitles,
};

export default connect(mapStateToProps, mapDispatchToProps)(JobTitleFilter);
