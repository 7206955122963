import React, {  } from 'react';
import { connect } from 'react-redux';

//-> action import

//-> file imports
import twentyThreeLogo from '../../../../assets/logos/logo.png';

class SubscriptionInvoice extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            accountInfo: this.props.accountInfo,

            subscription: this.props.subscription,
            price: this.props.price,
            rate: this.props.rate,

            numberOfEmployees: this.props.numberOfEmployees,
        };

        this.renderComponent = this.renderComponent.bind(this);
    };

    componentWillMount(){

    }

    componentWillReceiveProps(nextProps, nextContext){
        if(nextProps.accountInfo !== this.props.accountInfo){
            this.setState({accountInfo: nextProps.accountInfo})
        }
        if(nextProps.subscription !== this.props.subscription){
            this.setState({subscription: nextProps.subscription})
        }
        if(nextProps.price !== this.props.price){
            this.setState({price: nextProps.price})
        }
        if(nextProps.rate !== this.props.rate){
            this.setState({rate: nextProps.rate})
        }
        if(nextProps.numberOfEmployees !== this.props.numberOfEmployees){
            this.setState({numberOfEmployees: nextProps.numberOfEmployees})
        }
    }

    invoiceID(){
        return Math.floor( Math.random() * Math.floor(Math.random() * Date.now()) );
    }

    invoiceDate(){
        let d = new Date();
        let curr_date = d.getDate();
        let curr_month = d.getMonth() + 1; //Months are zero based
        let curr_year = d.getFullYear();
        return curr_date + "/" + curr_month + "/" + curr_year;
    }

    renderComponent() {
        //extract state
        const { subscription, price, rate, numberOfEmployees, accountInfo } = this.state;

        if (subscription && price && rate && numberOfEmployees) {
            return(
                <div className={'invoiceWrapperBox'}>
                    <div className={'invoiceHeaderBox'}>
                        <div>
                            <h4>Firm 2<span style={{color: '#404040'}}>3</span></h4>
                            <p>+255 22 261 7000</p>
                        </div>

                        <div>
                            <h4>Rainbow, Mbezi Beach</h4>
                            <h4>P.O BOX 23319</h4>
                            <h4>Kinondoni, Dar es Salaam</h4>
                            <h4>Tanzania</h4>
                        </div>
                    </div>

                    <div className={'invoiceLogoBox'}>
                        <div>
                            <img src={twentyThreeLogo} alt={'firm 23 logo'}/>
                        </div>
                    </div>

                    <div className={'invoiceDetailsBox'}>
                        <div className={'invoiceDetailsLeft'}>
                            <div>
                                <h4>Description</h4>
                                <p>Nafasi subscription</p>
                                <p><span style={{color: '#404040', fontSize: '1.5rem', fontWeight: 'bold'}}>{subscription}</span> months</p>
                            </div>

                            <div>
                                <h4>Rate</h4>
                                <p>{rate}</p>
                            </div>

                            <div>
                                <h4># of Employees</h4>
                                <p>{numberOfEmployees}</p>
                            </div>

                            <div>
                                <h4>Total</h4>
                                <p>{price}</p>
                            </div>
                        </div>

                        <div className={'invoiceDetailsRight'}>
                            <div>
                                <h4>Amount Due</h4>
                                <p style={{fontSize: '1.6rem', fontWeight: 'bold'}}>{price}</p>
                            </div>

                            <div>
                                <h4>Billed To</h4>
                                <p>{accountInfo.companyName}</p>
                            </div>

                            <div>
                                <h4>Invoice #</h4>
                                <p>{this.invoiceID()}</p>
                            </div>

                            <div>
                                <h4>Date of Issue</h4>
                                <p>{this.invoiceDate()}</p>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <h2 className={'adminAlert'}>Go back and choose a package</h2>
        }
    }

    render(){
        return(
            <div className={'invoiceContainer'}>
                { this.renderComponent()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    const { accountInfo } = state.settings;
    const {
        numberOfEmployees,
        subscription,
        price,
        rate,
    } = state.subscription;

    return{
        accountInfo,
        numberOfEmployees,
        subscription,
        price,
        rate,
    }
};

export default connect(mapStateToProps, {})(SubscriptionInvoice);