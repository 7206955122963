import React from 'react';
import {Routes, Route, NavLink, useNavigate} from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import firebase from 'firebase';
import moment from "moment";
import { faHome, faFileAlt, faUser,faSearch, faUserCircle, faUserPlus, faPen, faHeart, faCog, faBell, faSignOutAlt, faCreditCard, faRss ,faGavel, faTools, faBook, faDollarSign,faBars, faFolder, faSeedling, faOutdent, faCompress, faCompressAlt, faCompressArrowsAlt} from '@fortawesome/free-solid-svg-icons';
import { AlignLeftIcon } from "hugeicons-react";
import { AccountSetting03Icon, AnalyticsUpIcon, CustomerService02Icon, Folder02Icon, Home01Icon, LicenseDraftIcon, Logout03Icon, NotificationCircleIcon, PackageAddIcon, Search01Icon, SearchVisualIcon, UserSharingIcon,  } from 'hugeicons-react';

//action imports
import {homeFieldChanged, toggleMenu} from '../actions/HomeActions';
import {userLogOut} from "../actions/AuthenticationActions";
import {fetchSystemInfo} from "../actions/SystemActions";

//common imports
import {Spinner} from "./common/Spinner";


//-> file imports
import Logo from '../assets/logos/logo.png';
import taglineLogo from "../assets/logos/taglineLogo.png";


//-> component imports
import Home from './home/Home';
import Posts from "./posts/Posts";
import Shortlist from "./shortlist/Shortlist";
import Settings from "./settings/Settings";
import PostReview from "./posts/subComponents/PostReview";
import IASInterview from "./posts/subComponents/IASInterview";
import Profile from "./profile/Profile";
import SignIn from "./authentication/SignIn";
import SignUp from "./authentication/SignUp";
import Notifications from "./notifications/Notifications";
import Search from "./search/Search";
import Support from "./support/Support";
import SupportTickets from "./support/AllTickets";
import AllTrainings from "./settings/training/AllTrainings";
import AddTermsAndConditions from "./settings/legal/AddTermsAndConditions";
import ViewTermsAndConditions from "./settings/legal/ViewTermsAndConditions";
import SubscriptionInvoice from "./settings/subscriptions/invoice/SubscriptionInvoice";
import PrintInvoice from "./settings/subscriptions/invoice/subComponents/PrintInvoice";
import ChoosePackage from "./settings/subscriptions/subComponents/ChoosePackage";
import ScrollToTop from './common/ScrollToTop';
import EditProfile from "./profile/subComponents/EditProfile";
import Report from "./reports/Reports";
import Subscriptions from "./settings/subscriptions/Subscriptions";
import AddPrintInvoice from "./settings/subscriptions/additionalInvoice/subComponents/AddPrintInvoice";
import Download from "./download/Download";
import SeekerProfile from "./shortlist/subComponents/SeekerProfile";
import {VerifyEmail} from "./common/VerifyEmail";
import {VerifyUser} from "./common/VerifyUser";
import Blog from "./settings/blogs/Blog";
import Reference from "./landing/Reference";
import SearchCopy from './search/SearchCopy';
import Privacy from './legal/Privacy';
import Analytics from './analytics/Analytics';
import Footer from "./common/Footer";

//file imports

class MainBase extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            user: null,
            profile: this.props.profile,
            systemInfo: this.props.systemInfo,
            systemInfoIsEmpty: this.props.systemInfoIsEmpty,
            menuOpen:false,
            isToggled:this.props.isToggled
           
        };

        this.onLogOut = this.onLogOut.bind(this);
        this.ontoggleMenu = this.ontoggleMenu.bind(this);
    }


    componentWillReceiveProps(nextProps, nextContext) {

        if(nextProps.profile !== this.props.profile) {
            this.setState({ profile: nextProps.profile });
        }

        if(nextProps.systemInfoIsEmpty !== this.props.systemInfoIsEmpty) {
            this.setState({ systemInfoIsEmpty: nextProps.systemInfoIsEmpty });
        }

        if(nextProps.systemInfo !== this.props.systemInfo) {
            this.setState({ systemInfo: nextProps.systemInfo });
        }
        if(nextProps.isToggled !== this.props.isToggled) {
            this.setState({ isToggled: nextProps.isToggled });
        }
    }

    componentDidMount() {
        // firebase.auth().signOut();
        //-> check if user is logged in
        firebase.auth().onAuthStateChanged((user) => {
            if(user) {
                this.setState({ user: true })
            } else {
                //-> if user is not logged in
                this.setState({ user: false })
            }
        })
    }

    ontoggleMenu = () => {
        this.setState({ menuOpen: !this.state.menuOpen }, () => {
            console.log('menu', this.state.menuOpen);
            this.props.toggleMenu(this.state.menuOpen);
          
        });
    };
    



    onLogOut() {
        const {navigate} = this.props;
        this.props.userLogOut(navigate);
    }

    

    routeAccessLevel() {
        //-> extract state
        const { profile } = this.state;
        const { user } = this.state;

        //-> check access level
        if(!(_.isEmpty(profile)) || user === true) {
            //-> extract user role
            const { role } = this.state.profile;

            if( role === 'admin' || role === "superAdmin"){
                return(
                    <Routes>
                        <Route exact path={"/"} element={<Home/>} />
                        <Route exact path={"/profile"} element={<Profile/>} />
                        <Route exact path={"/shortlist"} element={<Shortlist/>} />
                        <Route exact path={"/posts"} element={<Posts/>} />
                        <Route exact path={"/download"} element={<Download/>} />
                        <Route exact path={"/settings"} element={<Settings/>} />
                        <Route exact path={"/post-review"} element={<PostReview/>} />
                        <Route exact path={"/perform-interview"} element={<IASInterview/>} />
                        <Route exact path={"/sign-in"} element={<SignIn/>} />
                        <Route exact path={"/sign-up"} element={<SignUp/>} />
                        <Route exact path={"/notifications"} element={<Notifications/>} />
                        <Route exact path={"/search"} element={<Search/>} />
                        <Route exact path={"/seeker-profile"} element={<SeekerProfile/>} />
                        <Route exact path={"/profile-details"} element={<EditProfile/>} />
                        <Route exact path={"/support"} element={<Support/>} />
                        <Route exact path={"/support-tickets"} element={<SupportTickets/>} />
                        <Route exact path={"/all-trainings"} element={<AllTrainings/>} />
                        <Route exact path={"/subscription"} element={<SubscriptionInvoice/>} />
                        <Route exact path={"/legal"} element={<AddTermsAndConditions/>} />
                        <Route exact path={"/_termsAndConditions"} element={<ViewTermsAndConditions/>} />
                        <Route exact path={"/invoice"} element={<SubscriptionInvoice/>} />
                        <Route exact path={"/print-invoice"} element={<PrintInvoice/>} />
                        <Route exact path={"/add-print-invoice"} element={<AddPrintInvoice/>} />
                        <Route exact path={"/change-package"} element={<ChoosePackage/>} />
                        <Route exact path={"/reports"} element={<Report/>} />
                        <Route exact path={"/analytics"} element={<Analytics/>} />
                        <Route exact path={"/blog/:id"} element={<Blog/>} />
                        <Route exact path={"/reference/:id"} element={<Reference/>} />
                        <Route exact path={"/privacy"} element={<Privacy/>} />
                    </Routes>
                );
            }
        } else {
            return <div style={{ marginTop: "5rem"}}><h1>No Profile is Loaded</h1></div>;
        }
    }

    checkAccessLevel() {
        //-> extract state
        const { profile} = this.state;
        const { user } = this.state;
        const { showDropdown ,isToggled} = this.state;

        console.log('dropdown', showDropdown)
        console.log('istoggled', isToggled);

        //-> check access level
        if(!(_.isEmpty(profile)) || user === true) {
            //> extract user role
            const { role } = this.state.profile;

            if(role === 'admin' || role === "superAdmin"){
                return (
                    <div className={`sidebar ${this.state.isToggled ? 'adjustedSidebar' : ''}`}>
                        <div className="siteLogo">
                            <div className={`navLogo2 ${this.state.isToggled ? 'adjustedNavLogo2' : ''}`}>
                                <NavLink to={'/'}><img src={Logo} alt={"nafasi icon logo"} /></NavLink>
                                <div className={"parentLogo2"}>
                                    <img src={taglineLogo} alt={"Firm 23 Company Logo"} style={{ width: "5rem", height: "5rem"}}/>
                                </div>
                            </div>
                        </div>
                        <div className={"harmburgerMenu"}>
                            <AlignLeftIcon size={23} style={{ marginBottom: '1.5rem', marginRight: '1rem' }}  onClick={this.ontoggleMenu} className={`${this.state.isToggled ? '' : ''}`}/>
                        </div>
                        <ul className="no-list sidebar_top">

                            <li><NavLink to={'/'} activeClassName="active-link"> <Home01Icon size={20} /><span style={{ fontFamily: "ramblaBold", marginTop: "0.2rem", color: "#000", fontSize:"0.8rem"}}>Home</span></NavLink> </li>
                            <li><NavLink to={'/search'}><SearchVisualIcon size={20}  /><span style={{ fontFamily: "ramblaBold", marginTop: "0.2rem", color: "#000", fontSize:"0.8rem"}}>Search</span></NavLink></li>
                            <li><NavLink to={'/shortlist'}><PackageAddIcon size={20}  /><span style={{ fontFamily: "ramblaBold", marginTop: "0.2rem", color: "#000", fontSize:"0.8rem"}}>Shortlist</span></NavLink></li>
                            <li><NavLink to={'/posts'}><LicenseDraftIcon size={20}  /> <span style={{ fontFamily: "ramblaBold", marginTop: "0.2rem", color: "#000", fontSize:"0.8rem"}}>Posts</span></NavLink></li>
                            <li><NavLink to={'/reports'}><Folder02Icon size={20}  /> <span style={{ fontFamily: "ramblaBold", marginTop: "0.2rem", color: "#000", fontSize:"0.8rem"}}>Reports</span></NavLink></li>
                            <li><NavLink to={'/analytics'}><AnalyticsUpIcon size={20}  /> <span style={{ fontFamily: "ramblaBold", marginTop: "0.2rem", color: "#000", fontSize:"0.8rem"}}>Analytics</span></NavLink></li>
                            <li><NavLink to={'/support'}><CustomerService02Icon size={20}  /> <span style={{ fontFamily: "ramblaBold", marginTop: "0.2rem", color: "#000", fontSize:"0.8rem"}}>Support</span></NavLink></li>
                            <li style={{ marginTop: '20px', borderTop: '1px solid #ccc' }}><NavLink to={'/notifications'}><NotificationCircleIcon size={20}  /> <span style={{ fontFamily: "ramblaBold", marginTop: "0.2rem", color: "#000", fontSize:"0.8rem"}}>Notifications</span></NavLink></li>
                            <li><NavLink to={'/settings'}><AccountSetting03Icon size={20}  /> <span style={{ fontFamily: "ramblaBold", marginTop: "0.2rem", color: "#000", fontSize:"0.8rem"}}>Settings</span> </NavLink> </li>
                            <li> <NavLink to={'/profile'}> <UserSharingIcon size={20}  /> <span style={{ fontFamily: "ramblaBold", marginTop: "0.2rem", color: "#000", fontSize:"0.8rem"}}>Profile</span></NavLink></li>
                            <li style={{ marginTop: '20px', borderTop: '1px solid #ccc' }}><Logout03Icon color="'#EA3224'" onClick={()=>this.onLogOut()} className={"logoutButton"}/><span style={{ fontFamily: "ramblaRegular", fontSize:"0.8rem"}}>Logout</span></li>
                        </ul>
                    </div>
                )
            } else {
                return (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "40vh" }}>
                        <Spinner/>
                    </div>
                )
            }
        }

    }


    renderDisabledSystem (text) {
        return (
            <div>
                <div className={"nav"}>
                    <div className={"navListContainer"}>
                        <ul>
                            <div className={"navLogo"}>
                                <NavLink to={'/'}><img src={Logo} alt={"nafasi icon logo"} /></NavLink>
                            </div>
                        </ul>
                    </div>

                    <div className={"iconContainer"}>
                        <ul>
                            <li>
                            <span>
                                <FontAwesomeIcon icon="sign-out-alt" size="1x" style={{color: '#EA3224', marginLeft: '1rem'}} onClick={()=>this.onLogOut()} className={"logoutButton"}/>
                            </span>
                            </li>
                        </ul>
                    </div>
                </div>
                <p style={{color: "#fff", textAlign: "center", marginTop: "5rem", fontFamily: "ramblaBold", fontSize: "1.5rem" }}>
                    {text}
                </p>
            </div>
        );
    }

    renderInactiveSystem() {
        return (
            <div>
                <div className={"nav"}>
                    <div className={"navListContainer"}>
                        <ul>
                            <div className={"navLogo"}>
                                <NavLink to={'/'}><img src={Logo} alt={"nafasi icon logo"} /></NavLink>
                            </div>
                        </ul>
                    </div>

                    <div className={"iconContainer"}>
                        <ul>
                            <li>
                            <span>
                                <FontAwesomeIcon icon="sign-out-alt" size="1x" style={{color: '#EA3224', marginLeft: '1rem'}} onClick={()=>this.onLogOut()} className={"logoutButton"}/>
                            </span>
                            </li>
                        </ul>
                    </div>
                </div>

                <Routes className={"bodyContainer"}>
                    <Route exact path={"/"} element={<Subscriptions/>} />
                    <Route exact path={"/subscription"} element={<SubscriptionInvoice/>} />
                    <Route exact path={"/legal"} element={<AddTermsAndConditions/>} />
                    <Route exact path={"/_termsAndConditions"} element={<ViewTermsAndConditions/>} />
                    <Route exact path={"/invoice"} element={<SubscriptionInvoice/>} />
                    <Route exact path={"/print-invoice"} element={<PrintInvoice/>} />
                    <Route exact path={"/add-print-invoice"} element={<AddPrintInvoice/>} />
                    <Route exact path={"/change-package"} element={<ChoosePackage/>} />
                    <Route exact path={"/reference/:id"} element={<Reference/>} />
                </Routes>
            </div>
        )
    }

    renderProfile() {
        //extract state
        const { profile } = this.state;

        //check if profile is not empty
        if (!(_.isEmpty(profile))) {
            const name = `${profile.firstName} ${profile.lastName}`;
            switch (profile.role) {
                case "admin":
                    return <h4><b>{name}</b> | Admin</h4>;
                case "superAdmin":
                    return <h4><b>{name}</b> | Super Admin</h4>;
                default:
                    return <h4>Admin</h4>;
            }
        } else {
            return <Spinner/>
        }
    }

    renderAccountStatus() {
        //extract state
        const {profile} = this.state;
        //check if system info is not empty
        if (!(_.isEmpty(profile))) {
            if ("disabled" in profile) {
                //
                const disabled = profile.disabled;
                if (disabled) {
                    return (
                        <div className={"bodyContainer"}>
                            <div
                                style={{
                                    backgroundColor: '#cd6d6d', height: '10vh', marginTop: "3vh", justifyContent: "center", alignItems: "center",
                                    display: "flex", paddingTop: "0.5rem", }}
                            >
                                <p style={{color: '#ffffff'}}>Dear customer, Sorry your licence has been disabled. Thank you for choosing Nafasi.</p>
                            </div>
                            { this.renderDisabledSystem() }
                        </div>
                    );
                } else {
                    //initialize payment alert
                    if ('active' in profile){
                        //
                        const active = profile.active;
                        //
                        if (active){
                            //render system
                            //it is not disabled
                            return (
                                <div>
                                    <div>
                                        {this.checkAccessLevel()}
                                    </div>
                                    {/* <div className={"bodyContainerTopBar"}>
                                        <div className={"creditManagerBox"}>
                                            { this.renderProfile() }
                                            <div className={'iconContainer'}>
                                                <FontAwesomeIcon icon="check-circle" size="lg" color="green" />
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className={"bodyContainer"}>
                                        <VerifyEmail/>
                                        <VerifyUser/>
                                        {this.routeAccessLevel()}
                                    </div>
                                </div>
                            )
                        } else {
                            //display the alert
                            return (
                                <div className={"bodyContainer"}>
                                    { this.renderInactiveSystem() }
                                </div>
                            );
                        }
                    } else {
                        return (
                            <div>
                                {this.renderDisabledSystem("Profile is not active")}
                            </div>
                        )
                    }
                }
            } else {
                return (
                    <div>
                        {this.renderDisabledSystem("Kindly contact customer support to enable account")}
                    </div>
                )
            }
        } else {
            return(
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "40vh" }}>
                    <Spinner/>
                </div>
            )
        }
    }

    render(){

        const year = moment().format("YYYY");

        return(
            <ScrollToTop>
                {/* <div className={"mainContainer"}> */}
                <div>
                    {
                        this.state.isToggled ? 
                        <div className={'adjustedBodyContainerTopBar'}>
                            <div className={"creditManagerBox"}>
                                { this.renderProfile() }
                                <div className={'iconContainer'}>
                                    <FontAwesomeIcon icon="check-circle" size="lg" color="green" />
                                </div>
                            </div>
                        </div> : 
                        <div className={'bodyContainerTopBar'}>
                            <div className={"bodyContainerTopBarLog"}><img src={taglineLogo} alt={"Firm 23 Company Logo"} style={{ width: "5rem", height: "5rem"}}/></div>
                            <div className={"creditManagerBox"}>
                                { this.renderProfile() }
                                <div className={'iconContainer'}>
                                    <FontAwesomeIcon icon="check-circle" size="lg" color="green" />
                                </div>
                            </div>
                        </div>
                    }

                    <div className={`mainContainer ${this.state.isToggled ? 'adjustedMainContainer' : ''}`}>
                        <div>
                            {this.renderAccountStatus()}
                        </div>
                    </div>
                    <Footer/>
                </div>
            </ScrollToTop>
        )

    }
}

const mapStateToProps = (state) => {

    const { profile } = state.profile;
    const { isToggled } = state.home;
    const { systemInfo , systemInfoIsEmpty } = state.system;

    return {
        profile,
        systemInfo,
        systemInfoIsEmpty,
        isToggled
    }
};

// Wrapper functional component to use hooks
function Main(props) {
    const navigate = useNavigate();

    return <MainBase {...props} navigate={navigate} />;
}

export default connect(mapStateToProps, { userLogOut,
    homeFieldChanged, fetchSystemInfo, toggleMenu })(Main);

    