import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from 'react-router-dom';
import {useGoogleTagManager} from '../common/UseGoogleTagManager';

//-> package image icons imports
import Earth from '../../assets/images/worldwide.png';
import Star from '../../assets/images/star.png';
import Galaxy from '../../assets/images/solar-system.png';
import Universe from '../../assets/images/universe.png';
import Problem from '../../assets/img/the_problem.png';
import Solution from '../../assets/img/the_solution.png';
import {Button} from "antd";

//style imports
import './Pricing.css';

function Pricing () {
    useGoogleTagManager('AW-16459515028');
    const navigate = useNavigate();

    const goToPage = () => {
        // Replace "/your-path" with the path you want to navigate to
        navigate('/register');
    };

    return(
        <div className={'pricingContainer'}>
            <div className={"priceContainerDetails"}>
                <div className={"pricingBox"}>
                    <h1>Find and Hire your next employee</h1>
                    <p>
                        Nafasi allows recruiters to filter and categorize over 50,000 CVs within seconds.
                        Cuts down your recruitment expenses by over 40% each month
                    </p>
                    <div>
                        <Button size={'large'} onClick={goToPage} className={"bold"}>
                            Sign up for free
                        </Button>
                    </div>
                </div>

                <div className={"pricingImagesBox"}>
                    <div>
                        <img src={Problem} alt={"the_problem"}/>
                    </div>
                    <div className={"theSolution"}>
                        <img src={Solution} alt={"the_solution"}/>
                    </div>
                </div>
            </div>
            <div className={'pricingIntroBox'}>
                <h4>Join the Nafasi Recruiting Community.</h4>
                <p>
                    <span style={{color: '#056899', fontSize: '1.3rem'}}>N</span><span style={{color: '#ffb400',fontSize: '1.4rem'}}>afasi</span> offers 3 different monthly subscription packages for Organizations
                </p>
            </div>

            <div className={'pricingInclusionBox'}>
                <h4>Every package includes</h4>
                <ul style={{ listStyleType: 'none', justifyContent: "center" }}>
                    <li>Access to major functionalities <FontAwesomeIcon icon={'check-circle'} size={'1.4rem'} color={'lightgreen'} /> </li>
                    <li>Access to new features updates <FontAwesomeIcon icon={'check-circle'} size={'1.4rem'} color={'lightgreen'} /></li>
                    <li>Technical Support <FontAwesomeIcon icon={'check-circle'} size={'1.4rem'} color={'lightgreen'} /></li>
                </ul>
            </div>

            <div className={'pricingCardsBox'}>
                <div className={'pricingCard'}>
                    <div><h3>Sole</h3></div>
                    <div>
                        <img src={Earth} alt={'Earth illustration icon'}/>
                    </div>
                    <div>
                        <h4>FREE</h4>
                    </div>
                    <div>
                        <h5>/ month </h5>
                    </div>
                    <div className={"subscribeBtnContainer registerButtonContainer"}>
                        <button style={{ backgroundColor: "#8FBC44", width: "10rem" }} onClick={goToPage}>
                            <span style={{ margin: "0.5rem", fontFamily: "ramblaRegular"}}>Sign up</span>
                        </button>
                    </div>
                    <div>
                        <p><FontAwesomeIcon icon={'check-circle'} size={'1.4rem'} color={'lightgreen'} /> Access to a sorted CVs database of over 50k current CV's. </p>
                    </div>
                </div>

                <div className={'pricingCard'}>
                    <div><h3>Startup</h3></div>
                    <div>
                        <img src={Star} alt={'Earth illustration icon'}/>
                    </div>
                    <div>
                        <h4>59</h4>
                        <h6>USD</h6>
                    </div>
                    <div>
                        <h5>/ month </h5>
                    </div>
                    <div className={"subscribeBtnContainer registerButtonContainer"}>
                        <button style={{ backgroundColor: "#8FBC44", width: "10rem" }} onClick={goToPage}>
                            <span style={{ margin: "0.5rem", fontFamily: "ramblaRegular"}}>Sign up</span>
                        </button>
                    </div>
                    <div>
                        <p><FontAwesomeIcon icon={'check-circle'} size={'1.4rem'} color={'lightgreen'} /> Access to a sorted CVs database of over 50k current CV's. </p>
                        <p><FontAwesomeIcon icon={'check-circle'} size={'1.4rem'} color={'lightgreen'} /> Ability to view candidate profiles and contact information. </p>
                        <p><FontAwesomeIcon icon={'check-circle'} size={'1.4rem'} color={'lightgreen'} /> Automated CV filtering based on job requirements. </p>
                        <p><FontAwesomeIcon icon={'check-circle'} size={'1.4rem'} color={'lightgreen'} /> Basic analytics and reporting on candidate engagement. </p>
                        <p><FontAwesomeIcon icon={'check-circle'} size={'1.4rem'} color={'lightgreen'} /> Interview Assessment System </p>
                    </div>
                </div>

                <div className={'pricingCard'}>
                    <div><h3>Business</h3></div>
                    <div>
                        <img src={Universe} alt={'Star illustration icon'}/>
                    </div>
                    <div>
                        <h4>99</h4>
                        <h6>USD</h6>
                    </div>
                    <div>
                        <h5>/ month </h5>
                    </div>
                    <div className={"subscribeBtnContainer registerButtonContainer"}>
                        <button style={{ backgroundColor: "#8FBC44", width: "10rem" }} onClick={goToPage}>
                            <span style={{ margin: "0.5rem", fontFamily: "ramblaRegular"}}>Sign up</span>
                        </button>
                    </div>
                    <div>
                        <p><FontAwesomeIcon icon={'check-circle'} size={'1.4rem'} color={'lightgreen'} /> All benefits of the Startup Plan. </p>
                        <p><FontAwesomeIcon icon={'check-circle'} size={'1.4rem'} color={'lightgreen'} /> Advanced candidate search filters (e.g., experience level, education, skills).  </p>
                        <p><FontAwesomeIcon icon={'check-circle'} size={'1.4rem'} color={'lightgreen'} /> Enhanced analytics and reporting, including applicant demographics. </p>
                        <p><FontAwesomeIcon icon={'check-circle'} size={'1.4rem'} color={'lightgreen'} /> Real-time notifications for candidate activities (e.g., new CV uploads, interview confirmations). </p>
                    </div>
                </div>

                <div className={'pricingCard'}>
                    <div><h3>Enterprise</h3></div>
                    <div>
                        <img src={Galaxy} alt={'Star illustration icon'}/>
                    </div>
                    <div>
                        <h4>269</h4>
                        <h6>USD</h6>
                    </div>
                    <div>
                        <h5>/ month </h5>
                    </div>
                    <div className={"subscribeBtnContainer registerButtonContainer"}>
                        <button style={{ backgroundColor: "#8FBC44", width: "10rem" }} onClick={goToPage}>
                            <span style={{ margin: "0.5rem", fontFamily: "ramblaRegular"}}>Sign up</span>
                        </button>
                    </div>

                    <div>
                        <p><FontAwesomeIcon icon={'check-circle'} size={'1.4rem'} color={'lightgreen'} /> All benefits of the Business Plan. </p>
                        <p><FontAwesomeIcon icon={'check-circle'} size={'1.4rem'} color={'lightgreen'} /> AI-powered candidate matching and ranking algorithms. </p>
                        <p><FontAwesomeIcon icon={'check-circle'} size={'1.4rem'} color={'lightgreen'} /> All features inclusive </p>
                    </div>
                </div>
            </div>

            <div className={'pricingInclusionBox'}>
                <h4 style={{ color: "#ffb400"}}>With Nafasi You Avoid</h4>

                <div>
                    <p>
                        <FontAwesomeIcon icon={'check-circle'} size={'1.4rem'} color={'lightgreen'} /> Missing out on exceptional talent due to slow and outdated methods that take weeks to identify talent</p>
                    <p>
                        <FontAwesomeIcon icon={'check-circle'} size={'1.4rem'} color={'lightgreen'} /> Tedious sorting of  CVs that don’t meet the required education field/industry for a position. (Marketers applying for an Admin job etc.)
                    </p>
                    <p><FontAwesomeIcon icon={'check-circle'} size={'1.4rem'} color={'lightgreen'} /> Assessing candidates from scratch</p>
                    <p><FontAwesomeIcon icon={'check-circle'} size={'1.4rem'} color={'lightgreen'} /> Outdated CV databases</p>
                    <p><FontAwesomeIcon icon={'check-circle'} size={'1.4rem'} color={'lightgreen'} /> Substantial costs due to expensive promotion fees for each vacant position</p>
                </div>
            </div>
        </div>
    )
}

export default Pricing;