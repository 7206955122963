import React from 'react';
import { connect } from 'react-redux';
import { Collapse } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Link, NavLink} from 'react-router-dom';
import {isMobile} from "react-device-detect";
import Logo from "../../assets/logos/logo.png";

//style imports
import './Faq.css';

class Faq extends React.Component {
    constructor(props){
        super(props);

        this.state = {

        }
    }

    componentWillReceiveProps(nextProps, nextContext){

    }

    renderLogo() {
        if(isMobile){
            return  <NavLink to={'/'}><img src={Logo} alt={"nafasi icon logo"}/></NavLink>;
        }
    }

    render(){
        const { Panel } = Collapse;

        return (
            <div className={'faqContainer'}>

                <div className={'landingPageLogo'}>
                    {this.renderLogo()}
                </div>
                <div className={'faqIntroBox'}>
                    <h4>Frequently asked questions</h4>
                </div>

                <div className={'faqQnBox'}>
                    <Collapse
                        accordion
                        bordered={false}
                        defaultActiveKey={['1']}
                        expandIcon={({ isActive }) => isActive ? <FontAwesomeIcon icon={'minus'} size={"2x"} color={'#3294ff'} /> : <FontAwesomeIcon icon={'plus'} size={"2x"} color={'#3294ff'} /> }
                        className="collapseItem"
                    >
                        <Panel header="What is Nafasi ?" key="2" className="faqCollapsePanel">
                            <div className={'faqAnswerBox'}>
                                <p>
                                    Nafasi is a leading job search platform that connects job seekers with employers and helps streamline the hiring process.
                                </p>
                            </div>
                        </Panel>
                        <Panel header="How does Nafasi work?" key="3" className="faqCollapsePanel">
                            <div className={'faqAnswerBox'}>
                                <p>
                                    With just ONE tap, Nafasi allows seekers access thousands of LOCAL & INTERNATIONAL job opportunities tailored to their preferences.
                                    From full-time jobs, and part-time gigs to internships, student fieldwork, and more.Employers are empowered to effortlessly post job openings, examine candidate profiles, and seamlessly oversee the hiring process.
                                </p>
                            </div>
                        </Panel>
                        <Panel header="Is Nafasi free to use?" key="4" className="faqCollapsePanel">
                            <div className={'faqAnswerBox'}>
                                <p>
                                    Absolutely! Nafasi is completely free for job seekers, allowing them to create profiles and apply
                                    for jobs without any charges. On the other hand, employers can access the sorting database by subscribing to a nominal fee. Rest assured, no fees are incurred upon successful recruitment.
                                </p>
                            </div>
                        </Panel>
                        <Panel header="How can I create a profile on Nafasi?" key="5" className="faqCollapsePanel">
                            <div className={'faqAnswerBox'}>
                                <p>
                                    To create a profile on Nafasi, as a seeker simply sign up with your email address through the nafasi iOS or Android app. Employers can sign up through the admin panel.
                                </p>
                            </div>
                        </Panel>
                        <Panel header="Can I search for specific job candidate on Nafasi?" key="6" className="faqCollapsePanel">
                            <div className={'faqAnswerBox'}>
                                <p>
                                    Absolutely! Nafasi offers a comprehensive candidate search feature that allows you to filter job listings based on industry,
                                    location, job type (part-time, full-time, etc.), and more. This helps you find the perfect job candidate that match your preferences.
                                </p>
                            </div>
                        </Panel>
                        <Panel header="How can employers review and manage candidate applications?" key="7" className="faqCollapsePanel">
                            <div className={'faqAnswerBox'}>
                                <p>
                                    Employers can easily review and manage candidate applications through their Nafasi dashboard. They can view applicant profiles,
                                    resumes, cover letters, and other relevant documents, allowing them to make informed hiring decisions.
                                </p>
                            </div>
                        </Panel>

                        <Panel header="Can I receive alerts on Nafasi?" key="8" className="faqCollapsePanel">
                            <div className={'faqAnswerBox'}>
                                <p>
                                    Yes, Nafasi offers job alert notifications based on your specified preferences. You can set up job alerts to receive updates on new job listings
                                    that match your desired criteria, ensuring you never miss out on relevant opportunities.
                                </p>
                            </div>
                        </Panel>

                        <Panel header="Is my personal information secure on Nafasi?" key="9" className="faqCollapsePanel">
                            <div className={'faqAnswerBox'}>
                                <p>
                                    Nafasi takes privacy and data security seriously. Your personal information is protected and will only be shared with potential employers
                                    when you apply for a job. We adhere to strict privacy policies to ensure the confidentiality of your data.
                                </p>
                            </div>
                        </Panel>
                    </Collapse>
                </div>

                <div className={'faqMoreContact'}>
                    <h5 style={{ color: "#fff"}}>Need more help answering your questions ? head over to <Link to={'/contact-us'} style={{ color: "#ffb400"}}>contact page</Link> and send us a message</h5>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {

    return {

    }
};

export default connect(mapStateToProps, { })(Faq);