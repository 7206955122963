import React from 'react';
import { Select } from 'antd';
import { connect } from "react-redux";
import arraySort from "array-sort";


class SelectDepartment extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            departments: this.props.departments
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.departments !== this.props.departments) {
            this.setState({ departments: nextProps.departments });
        }
    }


    render() {
        //extract state
        const {departments} = this.state;
        const { Option } = Select;

        const sortedDepartments = arraySort(departments, "title", { reverse: false });

        return (
            <div className={"inputTypeSelect"}>
                <div>
                    <p>
                        { this.props.label }
                    </p>
                </div>
                <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder={this.props.placeholder}
                    optionFilterProp="children"
                    onChange={this.props.onChange}
                    value={this.props.value}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    <Option value="">All</Option>
                    {
                        sortedDepartments.map(department => {
                            return <Option value={department.departmentID}>{department.title}</Option>
                        })
                    }

                    {/*<Option value="finance">Finance</Option>*/}
                    {/*<Option value="humanResources">Human Resources</Option>*/}
                    {/*<Option value="IT">IT</Option>*/}
                    {/*<Option value="technologyDevelopment">Technology Development</Option>*/}
                    {/*<Option value="marketing">Marketing</Option>*/}
                    {/*<Option value="operations">Operations</Option>*/}
                    {/*<Option value="product">Product</Option>*/}
                    {/*<Option value="sales">Sales</Option>*/}
                    {/*<Option value="design">Design</Option>*/}
                </Select>

            </div>
        );
    }
}

const mapStateToProps = state => {
    const { departments } = state.settings;

    return {
        departments
    };
}

export default connect(mapStateToProps)(SelectDepartment);
