import {
    FETCH_SHORTLIST,
    FETCH_SHORTLIST_FAILED,
    FETCH_SHORTLIST_SUCCESSFUL,
    FETCH_SELECTED_CANDIDATE,
    FETCH_SELECTED_CANDIDATE_SUCCESSFUL,
    FETCH_SELECTED_CANDIDATE_FAILED,
    POSTS_FIELD_CHANGED,
    FETCH_POSTS,
    FETCH_POSTS_SUCCESSFUL,
    FETCH_POSTS_FAILED,
    SAVE_CANDIDATE,
    SAVE_CANDIDATE_SUCCESSFUL,
    SAVE_CANDIDATE_FAILED,
    FETCH_POST_CANDIDATES,
    FETCH_POST_CANDIDATES_SUCCESSFUL,
    FETCH_POST_CANDIDATES_FAILED,
    SELECTED_POST,
    DELETE_JOB_POST,
    DELETE_JOB_POST_SUCCESSFUL,
    DELETE_JOB_POST_FAILED,
    WITHDRAW_CANDIDATE,
    WITHDRAW_CANDIDATE_SUCCESSFUL,
    WITHDRAW_CANDIDATE_FAILED,
    CLEAR_POST_MESSAGES,
    ADD_POST,
    ADD_POST_SUCCESSFUL,
    ADD_POST_FAILED,
    CLEAR_SELECTED_POST,
    SAVE_RATINGS,
    SAVE_RATINGS_FAILED,
    SAVE_RATINGS_SUCCESSFUL,
    FETCH_SEEKER,
    FETCH_SEEKER_FAILED,
    FETCH_SEEKER_SUCCESSFUL,
    FETCH_SEEKER_PATH,
    FETCH_SEEKER_PATH_SUCCESSFUL,
    FETCH_SEEKER_PATH_FAILED,
    PUSHED_SEEKER,
    CANDIDATE_SELECTION,
    CANDIDATE_SELECTION_SUCCESSFUL, 
    CANDIDATE_SELECTION_FAILED, 
    CLEAR_POST, 
    UPDATE_INTERVIEW_CANDIDATE_SUCCESSFUL,
    ADD_EVENT_TO_CALENDAR,
    ADD_EVENT_TO_CALENDAR_SUCCESSFUL,
    FETCH_VETTING_RESULT,
    FETCH_VETTING_RESULT_SUCCESS,
    FETCH_VETTING_RESULT_FAIL,
    FETCH_CANDIDATES_EXPERIENCE_SUCCESS,
    FETCH_CANDIDATES_EXPERIENCE,
    FETCH_CANDIDATES_EXPERIENCE_FAIL,
    FETCHED_UPCOMING_INTERVIEWS_SUCESSFULLY,
    FETCH_UPCOMING_INTERVIEWS,
    FETCH_UPCOMING_INTERVIEWS_FAILED
} from "../actions/Types";


const INITIAL_STATE = {

    candidates: {},
    selectedPost: '',

    //add post
    jobStatus: '',
    employmentType: '',
    minimumExperience: '',
    jobDescription: '',
    compensation: '',
    country: '',
    region: '',
    postalCode: '',
    file: '',
    companyName: '',
    deadline: '',

    phone: '',
    email: '',
    jobTitle: '',
    department: '',


    addPostLoading: false,
    postSaved: false,
    postFailed: false,

    saveCandidateLoading: false,
    candidateSaved: false,
    candidateSaveFailed: false,

    jobPostDeleted: false,
    jobPostDeletedFailed: false,
    candidateWithdrawn: false,
    candidateWithdrawnFailed: false,
    posts: [],
    postsLoading: false,

    shortlist: [],
    loading: false,

    post: "",
    interviewDate: null,
    inviteInterviewLoading: false,
    inviteVisible: false,
    inviteComment: "",

    selectionVisible: false,
    selectionLoading: false,
    selectionComment: "",

    selectedCandidate: {},
    selectedCandidateLoading: false,

    selectedCandidateProfile: {},
    selectedCandidateProfileLoading: false,
    addToCalendar:false,

    certificates: [],
    certificatesLoading: false,
    hobbies: [],
    hobbiesLoading: false,
    referees: [],
    refereesLoading: false,
    education: [],
    educationLoading: false,
    employment: [],
    employmentLoading: false,
    languages: [],
    languagesLoading: false,
    awards: [],
    awardsLoading: false,
    licenses: [],
    licensesLoading: false,
    military: [],
    militaryLoading: false,
    skills: [],
    skillsLoading: false,
    links: [],
    linksLoading: false,
    interviews: [],
    interviewsLoading: false,
    myInterviews: [],
    pushedSeeker: {},

    vettingResult: {},
    vettingLoading: false,

    adaptability: 0,
    analyticalThinking: 0,
    creativity: 0,
    decisionMaking: 0,
    emotionalIntelligence: 0,
    initiative: 0,
    leadership: 0,
    organizationalSkills: 0,
    professionalism: 0,
    teamwork: 0,
    timeManagement: 0,
    verbalCommunication: 0,
    workEthic: 0,
    writtenCommunication: 0,
    ratingsLoading: false,

    candidateExperience: [],
    candidateLevel: [],
    distributionLoad: false,
    upcomingInterviews: [],
    fetchingUpcomingInterviews:false

};

const deepClone = obj => JSON.parse(JSON.stringify(obj));

const PostsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case POSTS_FIELD_CHANGED:
            return { ...state, [action.payload.prop]: action.payload.value};

        case SELECTED_POST:
            return {...state, selectedPost: action.payload };

        case CLEAR_SELECTED_POST:
            return { ...state, selectedPost: "" };
            
            
        case FETCH_POSTS:
            return { ...state, postsLoading: true };
        case FETCH_POSTS_SUCCESSFUL:
            return { ...state, postsLoading: false, posts: deepClone(action.payload) };
        case FETCH_POSTS_FAILED:
            return { ...state, postsLoading: false };

        case SAVE_CANDIDATE:
            return { ...state, inviteInterviewLoading: true };
        case SAVE_CANDIDATE_SUCCESSFUL:
            return { ...state, inviteInterviewLoading: false, interviewDate: null, post: "", inviteVisible: false, selectionVisible: false, selectedPost: "", inviteComment: "" };
        case UPDATE_INTERVIEW_CANDIDATE_SUCCESSFUL:
            return { ...state, inviteInterviewLoading: false, interviewDate: null, post: "", inviteVisible: false, selectionVisible: false, inviteComment: "" };
        case SAVE_CANDIDATE_FAILED:
            return { ...state, inviteInterviewLoading: false, selectedPost: "" };

        case CANDIDATE_SELECTION:
            return { ...state, selectionLoading: true };
        case CANDIDATE_SELECTION_SUCCESSFUL:
            return { ...state, selectionLoading: false, post: "", selectionVisible: false, selectionComment: "" };
        case CANDIDATE_SELECTION_FAILED:
            return { ...state, selectionLoading: false };

            //fetching reducer
        case FETCH_SHORTLIST:
            return { ...state, loading: true };
        case FETCH_SHORTLIST_SUCCESSFUL:
            return { ...state, loading: false, shortlist: deepClone(action.payload), };
        case FETCH_SHORTLIST_FAILED:
            return { ...state, loading: false, shortlist: [] };

        case FETCH_VETTING_RESULT:
            return { ...state, vettingLoading: true };
        case FETCH_VETTING_RESULT_SUCCESS:
            return { ...state, vettingLoading: false, vettingResult: deepClone(action.payload), };
        case FETCH_VETTING_RESULT_FAIL:
            return { ...state, vettingLoading: false};       

        case PUSHED_SEEKER:
            return { ...state, pushedSeeker: action.payload };
        case FETCH_SEEKER:
            return { ...state,
                selectedCandidateLoading: true,
                selectedCandidate: {},
                selectedCandidateProfile: {},
                certificates: [],
                hobbies: [],
                referees: [],
                education: [],
                employment: [],
                languages: [],
                awards: [],
                licenses: [],
                military: [],
                skills: [],
                links: [],
                interviews: [],
                myInterviews: [],
            };
        case FETCH_SEEKER_SUCCESSFUL:
            return { ...state, selectedCandidateLoading: false, selectedCandidate: deepClone(action.payload) };
        case FETCH_SEEKER_FAILED:
            return { ...state, selectedCandidateLoading: false };


        case FETCH_SELECTED_CANDIDATE:
            return { ...state, selectedCandidateProfileLoading: true };
        case FETCH_SELECTED_CANDIDATE_SUCCESSFUL:
            return { ...state, selectedCandidateProfileLoading: false, selectedCandidateProfile: deepClone(action.payload), };
        case FETCH_SELECTED_CANDIDATE_FAILED:
            return { ...state, selectedCandidateProfileLoading: false };
            
        case FETCH_SEEKER_PATH:
            return { ...state, [`${action.payload}Loading`]: true };
        case FETCH_SEEKER_PATH_SUCCESSFUL:
            if (action.payload.path === "interviews") {
                return { ...state, [`${action.payload.path}Loading`]: false, interviews: deepClone(action.payload.interviews), myInterviews: deepClone(action.payload.myInterviews) };
            } else {
                return { ...state, [`${action.payload.path}Loading`]: false, [action.payload.path]: deepClone(action.payload.response) };
            }
        case FETCH_SEEKER_PATH_FAILED:
            return { ...state, [`${action.payload}Loading`]: false };

        case ADD_POST:
            return {...state, addPostLoading: true };
        case ADD_POST_SUCCESSFUL:
            return {...state,  jobTitle: '', jobStatus: '', department: '', employmentType: '', minimumExperience: '', jobDescription: '', address: '',
                compensation: '', country: '', region: '', postalCode: '',
                file: '',
                companyName: '',
                email: '',
                phone: '',
                deadline: '',
                addPostLoading: false, postSaved: true };
        case ADD_EVENT_TO_CALENDAR:

        return {...state, addToCalendar: action.payload};

        case ADD_EVENT_TO_CALENDAR_SUCCESSFUL:

        return {...state, addToCalendar: false };
            
        case ADD_POST_FAILED:
            return {...state, addPostLoading: false, postFailed: true };
            

        case FETCH_POST_CANDIDATES:
            return { ...state, };
        case FETCH_POST_CANDIDATES_SUCCESSFUL:
            return { ...state,  candidates: deepClone(action.payload) };
        case FETCH_POST_CANDIDATES_FAILED:
            return { ...state, };
            

        case DELETE_JOB_POST:
            return {...state, };
        case DELETE_JOB_POST_SUCCESSFUL:
            return {...state, jobPostDeleted: true, };
        case DELETE_JOB_POST_FAILED:
            return {...state, jobPostDeletedFailed: true };
            

        case WITHDRAW_CANDIDATE:
            return {...state, };
        case WITHDRAW_CANDIDATE_SUCCESSFUL:
            return {...state, candidateWithdrawn: true, };
        case WITHDRAW_CANDIDATE_FAILED:
            return {...state, candidateWithdrawnFailed: true }

        case SAVE_RATINGS:
            return { ...state, ratingsLoading: true };
        case SAVE_RATINGS_SUCCESSFUL:
            return {
                ...state, ratingsLoading: false,
                adaptability: 0,
                analyticalThinking: 0,
                creativity: 0,
                decisionMaking: 0,
                emotionalIntelligence: 0,
                initiative: 0,
                leadership: 0,
                organizationalSkills: 0,
                professionalism: 0,
                teamwork: 0,
                timeManagement: 0,
                verbalCommunication: 0,
                workEthic: 0,
                writtenCommunication: 0,
            };
        case SAVE_RATINGS_FAILED:
            return { ...state, ratingsLoading: false };

        case CLEAR_POST_MESSAGES:
            return {...state, addPostLoading: false, postSaved: false, postFailed: false, jobPostDeleted: false,
                jobPostDeletedFailed: false, saveCandidateLoading: false, candidateSaved: false, candidateSaveFailed: false,
                candidateWithdrawn: false, candidateWithdrawnFailed: false };

        case CLEAR_POST:
            return {
                ...state,
                jobTitle: '',
                jobStatus: '',
                department: '',
                employmentType: '',
                minimumExperience: '',
                jobDescription: '',
                address: '',
                compensation: '',
                country: '',
                region: '',
                postalCode: '',
                file: '',
                companyName: '',
                email: '',
                phone: '',
                deadline: '',
                addPostLoading: false };

        case FETCH_CANDIDATES_EXPERIENCE:
            return {...state, distributionLoad: true}
        case FETCH_CANDIDATES_EXPERIENCE_SUCCESS:
            return {...state, distributionLoad: false, candidateExperience: deepClone(action.payload.candidateExperience), candidateLevel: deepClone(action.payload.candidateLevel)}
        case FETCH_CANDIDATES_EXPERIENCE_FAIL:
            return {...state, distributionLoad: false,}

        case FETCHED_UPCOMING_INTERVIEWS_SUCESSFULLY:
            return {...state, upcomingInterviews: action.payload, fetchingUpcomingInterviews:false}
        case FETCH_UPCOMING_INTERVIEWS:
            return {...state, fetchingUpcomingInterviews:true}
        case FETCH_UPCOMING_INTERVIEWS_FAILED: 
        return {...state, fetchingUpcomingInterviews:false,upcomingInterviews:[]}
           

        default:
            return state;
    }
};

export default PostsReducer;