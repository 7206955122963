import React from "react";
import { connect } from "react-redux";
import { DatePicker } from "antd";
import moment from "moment";

//action imports
import { searchFieldChanged } from '../../../actions/SearchActions';

class YearOfCompletion extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            yearOfCompletion: this.props.yearOfCompletion
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props) {
            this.setState({
                yearOfCompletion: nextProps.yearOfCompletion
            });
        }
    }

    handleYearChange = (date, dateString) => {
        if (!dateString) {
            // Dispatch an action to set yearOfCompletion to null
            this.props.searchFieldChanged({ prop: "yearOfCompletion", value: null });
        } else {
            // Otherwise, proceed as usual
            this.props.searchFieldChanged({ prop: "yearOfCompletion", value: dateString });
        }
    };

    render() {
        const { yearOfCompletion } = this.props;

        return (
            <div>
                <p style={{ color: "#000", fontFamily: "myriadBold", marginBottom: 3, textAlign: "left" }}>
                    Completion Year
                </p>
                <DatePicker
                    picker="year"
                    value={yearOfCompletion ? moment(yearOfCompletion, "YYYY") : null}
                    onChange={this.handleYearChange}
                    allowClear
                    placeholder="Select Year"
                    style={{ width: "100%" }}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { yearOfCompletion } = state.search;

    return {
        yearOfCompletion,
    };
};

export default connect(mapStateToProps, {
    searchFieldChanged,
})(YearOfCompletion);
