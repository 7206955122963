import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import {Button, message, Modal, Popover} from "antd";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {ClipLoader} from "react-spinners";
import _ from 'lodash';

//action imports
import { updateSupportTicketStatus, registerReadReport } from "../../../../actions/SupportActions";

//component imports
import {TicketStatusPicker} from "../TicketStatusPicker";

class TicketCard extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            admin: this.props.admin,
            visible: false,
            statusVisible: false,
            ticket: this.props.ticket,
            ticketStatus: this.props.ticket.status,
            supportLoading: this.props.supportLoading,
            profile: this.props.profile,
        };

        this.onTicketStatusSelect = this.onTicketStatusSelect.bind(this);
        this.renderCloseTime = this.renderCloseTime.bind(this);
        this.showModal = this.showModal.bind(this);
        this.onSaveStatus = this.onSaveStatus.bind(this);
        this.renderStatusSaveButton = this.renderStatusSaveButton.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext){

        if(nextProps.supportLoading !== this.props.supportLoading){
            this.setState({ supportLoading: nextProps.supportLoading });
        }

        if(nextProps.profile !== this.props.profile){
            this.setState({ profile: nextProps.profile });
        }

        if(nextProps.admin !== this.props.admin) {
            this.setState({ admin: nextProps.admin });
        }
        if(nextProps.ticket !== this.props.ticket) {
            this.setState({ ticket: nextProps.ticket });
        }
        if(nextProps.ticketStatus !== this.props.ticketStatus) {
            this.setState({ ticketStatus: nextProps.ticketStatus });
        }
    };

    showModal() {
        //extract state
        const { ticket } = this.state;

        this.setState({
            visible: true,
        });

        //register a read report on ticket
        const ticketID = ticket.ticketID;

        //check if ticket already has readReport
        if ("readReport" in ticket) {
            //ticket has a read report registered already
        } else {
            this.props.registerReadReport(ticketID);
        }
    };

    showEmployeeMessageModal() {
        this.setState({
            visible: true,
        });
    };

    renderAccess(){
        //-> extract state
        const { profile } = this.state;

        if(!(_.isEmpty(profile))){
            const { role } = this.state.profile;
            //check access level
            if((role === 'admin') || (role === 'HOD') ){
                return this.showModal();
            } else {
                return this.showEmployeeMessageModal();
            }
        }
    }

    handleOk = () => {
        this.setState({
            visible: false,
        });

    };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };

    statusHide = () => {
        this.setState({
            statusVisible: false,
        });
    };

    handleStatusVisibleChange = visible => {
        this.setState({ statusVisible: visible });
    };

    onTicketStatusSelect(value) {
        this.setState({ ticketStatus: value });
    }

    onSaveStatus() {
        //extract state
        const { ticket, ticketStatus } = this.state;

        if (ticket && ticketStatus) {
            this.props.updateSupportTicketStatus({ ticketID: ticket.ticketID, status: ticketStatus })
        } else {
            message.warning("Kindly fill in required fields");
        }
        this.setState({ statusVisible: false });
    }

    renderStatusSaveButton(){
        const {supportLoading}  = this.state;

        if (supportLoading) {
            return (
                <div>
                    <ClipLoader
                        size={20}
                    />
                </div>
            );
        } else {
            return  (
                <div className={"buttonBox"}>
                    <Button type="primary" onClick={() => this.onSaveStatus()}>
                        <span style={{ marginTop: "0.05rem", fontFamily: "ramblaRegular"}}>Save</span>
                    </Button>
                </div>
            );
        }
    }

    renderManageStatus() {
        //extract state
         const { admin } = this.state;

            if (admin) {
                const SelectType = <div className={"statusManage"}>
                    <div className={"closeStatus"}>
                        <FontAwesomeIcon icon={'times'} size="1x" color="#ff0000" onClick={this.statusHide} className={"notificationClearButton"}/>
                    </div>
                    <div>
                        <TicketStatusPicker
                            label={"Status"}
                            onChange={this.onTicketStatusSelect}
                            value={this.state.ticketStatus}
                        />
                    </div>

                    { this.renderStatusSaveButton() }

                </div>

                return(
                    <Popover
                        content={SelectType}
                        title="Title"
                        trigger="click"
                        visible={this.state.statusVisible}
                        onVisibleChange={this.handleStatusVisibleChange}
                    >
                        <div className={"buttonBox popOver"}>
                            <Button type="primary"><span style={{ marginTop: "0.05rem", fontFamily: "ramblaRegular"}}>Manage Status</span></Button>
                        </div>
                    </Popover>
                );
            }

    }


    renderCloseTime() {
        //extract state
        const { ticket } = this.state;
        if("closeTime" in ticket) {
            const closeTime = ticket.closeTime;
            //extract open time
            let seconds;
            closeTime.seconds ? seconds = closeTime.seconds : seconds = closeTime._seconds;
            const loggedCloseTime = moment.unix(seconds);

            return(
                <p>{loggedCloseTime.format("ddd, HH:mm | Do MMM YYYY")}</p>
            );
        } else {
            return <p>-</p>
        }
    }

    renderPriority(){
        const { priority } = this.state.ticket;

        if(priority === 'high'){
            return(
                <p className={"ticketPriorityHigh"}>{priority}</p>
            )
        } else if (priority === 'medium') {
            return(
                <p className={"ticketPriorityMedium"}>{priority}</p>
            )
        } else if(priority === 'low') {
            return(
                <p className={"ticketPriorityLow"}>{priority}</p>
            )
        }
    }

    renderReadStatus() {
        const { ticket } = this.state;

        //check if ticket message has been seen
        if ("readReport" in ticket) {
            let seconds;
            ticket.readTime.seconds ? seconds = ticket.readTime.seconds : seconds = ticket.readTime._seconds;
            const readTime = moment.unix(seconds);
            return(
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <h3 style={{fontFamily: 'avenirRoman, sans-serif'}}>Read <FontAwesomeIcon icon="long-arrow-alt-right" style={{fontSize: '1rem', paddingRight: '0.2rem'}} color="green" /> </h3>
                    <h3 style={{color: '#787de1', fontFamily: 'avenirRoman, sans-serif'}}>{readTime.format("ddd, HH:mm | Do MMM YYYY")}</h3>
                </div>
            );
        }
    }

    renderStatus(){
        const { status } = this.state.ticket;

        if(status === 'open'){
            return(
                <p style={{textTransform: 'capitalize'}}><FontAwesomeIcon icon={"lock-open"} color={'#8b91ff'} size={"1x"}/> {status}</p>
            )
        } else if (status === 'closed'){
            return(
                <p style={{textTransform: 'capitalize'}}><FontAwesomeIcon icon={"lock"} color={'#8b91ff'} size={"1x"}/> {status}</p>
            )
        }
    }

    render() {
        //extract state
        const { ticketSubject, relatedService, ticketMessage, employeeName, openTime, employeeDepartment } = this.state.ticket;
        const { profile } = this.state;
        //extract open time
        let seconds;
        openTime.seconds ? seconds = openTime.seconds : seconds = openTime._seconds;
        const loggedOpenTime = moment.unix(seconds);

        return(
            <div className={"ticketInfoBox"}>
                <div className={"ticketHeaderName"}>
                    <p><FontAwesomeIcon icon={"user-circle"} style={{color: '#8b91ff', fontSize: '1.1rem'}} /> {employeeName}</p>
                    <p style={{textTransform: 'uppercase', fontSize: '0.9rem'}}>Department: {employeeDepartment}</p>
                </div>

                <div className={"ticketServicePriority"}>
                    <p><FontAwesomeIcon icon={"exclamation-triangle"} style={{color: '#cc3300', fontSize: '1rem'}} /> {relatedService}</p>
                    {this.renderPriority()}
                </div>
                <div className={"ticketOpenStatus"}>
                    <p>{loggedOpenTime.format("ddd, HH:mm | Do MMM YYYY")}</p>
                    {this.renderStatus()}
                </div>

                <div className={"ticketCloseTime"}>
                    { this.renderCloseTime() }
                </div>

                <div className={"ticketViewManage"}>
                    <div className={"ticketView"}>
                        <div>
                            <FontAwesomeIcon icon="eye" color="#3294ff" size="1x" onClick={() => this.renderAccess()} style={{cursor: 'pointer'}} />
                        </div>

                        <Modal
                            title={ticketSubject}
                            open={this.state.visible}
                            onOk={this.handleOk}
                            onCancel={this.handleCancel}
                            footer={null}
                            width={900}
                        >
                            <div className={"ticketModalDetails"}>
                                <h2>{ ticketMessage }</h2>
                            </div>
                        </Modal>
                    </div>

                    <div>
                        { this.renderReadStatus() }
                    </div>

                    <div>
                        { this.renderManageStatus() }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { ticketStatus, supportLoading } = state.support;
    const { profile } = state.profile;

    return {
        ticketStatus,
        supportLoading,
        profile,
    }
};

export default connect(mapStateToProps, { updateSupportTicketStatus, registerReadReport })(TicketCard);