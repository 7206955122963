import React from 'react';
import { Input, AutoComplete } from 'antd';
import { connect } from 'react-redux';
import { searchFieldChanged, fetchCertificates } from '../../../actions/SearchActions';

class CertificatesFilter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            certificatesSearchQuery: this.props.certificatesSearchQuery,
            certificates: this.props.certificates
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props) {
            this.setState({
                certificatesSearchQuery: nextProps.certificatesSearchQuery,
                certificates: nextProps.certificates
            });
        }
    }

    handleSearch = (value) => {
        this.props.searchFieldChanged({ prop: "certificatesSearchQuery", value });
        this.props.fetchCertificates({ searchQuery: value });
    };

    handleSelect = (value, option) => {
        const { objectID } = option.data;
        // Proceed as usual when an option is selected
        this.props.searchFieldChanged({ prop: "certificatesSearchQuery", value });
        this.props.searchFieldChanged({ prop: "certificateID", value: objectID });
    };

    handleClear = () => {
        // Handle the case when the clear icon is clicked
        this.props.searchFieldChanged({ prop: "certificatesSearchQuery", value: "" });
        this.props.searchFieldChanged({ prop: "certificateID", value: null });
	}

    render() {
        const { certificatesSearchQuery, certificates } = this.state;

        return (
            <div>
                <p style={{ color: "#000", fontFamily: "myriadBold", marginBottom: 3, textAlign: "left" }}>Professional Certificates</p>
                <AutoComplete
                    value={certificatesSearchQuery}
                    onChange={this.handleSearch}
                    onSelect={this.handleSelect}
                    onClear={this.handleClear}
                    allowClear
                    options={certificates.map((result) => ({
                        value: result.title,
                        label: result.title,
                        data: {
                            title: result.title,
                            objectID: result.objectID,
                        },
                    }))} // Convert search results to options format
                    style={{ width: "100%", borderColor: '#000' }}
                >
                    <Input.Search placeholder="Search Certificates" />
                </AutoComplete>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { certificatesSearchQuery, certificates } = state.search;
    return {
        certificatesSearchQuery, certificates
    };
};

const mapDispatchToProps = {
    searchFieldChanged, fetchCertificates,
};

export default connect(mapStateToProps, mapDispatchToProps)(CertificatesFilter);
