import React from 'react';
import { Routes, Route, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { isMobile } from 'react-device-detect';
import { stack as Menu } from "react-burger-menu";

//action imports

//-> file imports
import Logo from '../assets/logos/logo.png';

//-> component imports
import SignIn from "./authentication/SignIn";
import SignUp from "./authentication/SignUp";
import LandingPage from "./landing/SplitLanding";
import Faq from "./landing/Faq";
import ContactUs from "./landing/ContactUs";
import Footer from "./common/Footer";
import Pricing from './landing/Pricing';
import Download from "./download/Download";
import Blog from "./settings/blogs/Blog";
import Reference from "./landing/Reference";
import Privacy from './legal/Privacy';
import TermsAndConditions from './legal/TermsAndConditions';
import Developers from './landing/Developers'
import Disclosure from './legal/Disclosure';
import ChangeDevice from './landing/ChangeDevice';

class LandingBase extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            user: null,
            menuOpen: false,
        }
    }

    //handle state change for the mobile side menu
    handleStateChange (state) {
        this.setState({menuOpen: state.isOpen})
    }

    //close the mobile side menu
    closeMenu (){
        this.setState({menuOpen: false})
    }

    renderSystem() {
        console.log('Is mobile',isMobile )
       
        if(isMobile) {
            return (
                <Routes className={"bodyContainer"}>
                    <Route exact path={"/"} element={<LandingPage/>} />
                    <Route exact path={"/faq"} element={<Faq/>} />
                    <Route exact path={"/contact-us"} element={<ContactUs/>} />

                    <Route exact path={"/register"} element={<SignUp/>}/>
                    <Route exact path={"/pricing"} element={<Pricing/>} />
                    <Route exact path={"/download"} element={<Download/>} />
                    <Route exact path={"/blog/:id"} element={<Blog/>} />
                    <Route exact path={"/reference/:id"} element={<Reference/>} />
                    <Route exact path={"/privacy"} element={<Privacy/>} />
                    <Route exact path={"/disclosure"} element={<Disclosure/>} />
                    <Route exact path={"/terms"} element={<TermsAndConditions/>} />
                    <Route exact path={"/developers"} element={<Developers/>} />
                </Routes>
            )
        } else {
            return (
                <Routes>
                    <Route exact path={"/"} element={<LandingPage/>}/>
                    <Route exact path={"/faq"} element={<Faq/>}/>
                    <Route exact path={"/contact-us"} element={<ContactUs/>}/>
                    <Route exact path={"/login"} element={<SignIn/>}/>
                    <Route exact path={"/register"} element={<SignUp/>}/>
                    <Route exact path={"/pricing"} element={<Pricing/>}/>
                    <Route exact path={"/download"} element={<Download/>}/>
                    <Route exact path={"/blog/:id"} element={<Blog/>} />
                    <Route exact path={"/reference/:id"} element={<Reference/>} />
                    <Route exact path={"/privacy"} element={<Privacy/>} />
                    <Route exact path={"/disclosure"} element={<Disclosure/>} />
                    <Route exact path={"/terms"} element={<TermsAndConditions/>} />
                    <Route exact path={"/developers"} element={<Developers/>} />
                    <Route exact path={"/change-device"} element={<ChangeDevice/>} />
                </Routes>
            )
        }
    }

    renderNavigation(){
       
        if(isMobile){
            return (
                <div>
                    <Menu
                        pageWrapId={ "page-wrap" }
                        outerContainerId={ "outer-container" }
                        right
                        isOpen={this.state.menuOpen}
                        onStateChange={(state) => this.handleStateChange(state)}
                    >
                        <NavLink to={'/'} className={'menu-item'} onClick={() =>this.closeMenu()}>Home</NavLink>
                        <NavLink to={'/faq'} className={'menu-item'} onClick={() =>this.closeMenu()}>FAQ</NavLink>
                        <NavLink to={'/contact-us'} className={'menu-item'} onClick={() =>this.closeMenu()}>Contact us</NavLink>
                        <NavLink to={'/pricing'} className={'menu-item'} onClick={() =>this.closeMenu()}>Pricing</NavLink>
                        <NavLink to={'/developers'} className={'menu-item'} onClick={() =>this.closeMenu()}>Developers</NavLink>
                        <NavLink to={'/change-device'} className={'menu-item'} onClick={() =>this.closeMenu()}>Sign Up</NavLink>
                      
                    </Menu>
                </div>
            )
        } else {
            return (
                <div className={"nav2"}>
                    <div className={"navListContainer"}>
                        <ul>
                            <div className={"navLogo"}>
                                <NavLink to={'/'}><img src={Logo} alt={"nafasi icon logo"}/></NavLink>
                            </div>
                            <li><NavLink to={'/faq'}>FAQ</NavLink></li>
                            <li><NavLink to={'/contact-us'}>Contact us</NavLink></li>
                            <li><NavLink to={'/pricing'}>Pricing</NavLink></li>
                            <li><NavLink to={'/developers'}>Developers</NavLink></li>
                            <li><NavLink to={'/login'}>Console</NavLink></li>

                        </ul>
                    </div>
                </div>
            )
        }
    };

    render(){
        return(
            <div className={"landingContainer"} id={'page-wrap'}>
                <div className={'navigation'}>
                    { this.renderNavigation()}
                </div>

                <div className={"bodyContainer"}>
                    { this.renderSystem() }
                </div>

                <div>
                    <Footer/>
                </div>
            </div>
        )
    }
}

// Wrapper functional component to use hooks
function Landing(props) {
    const navigate = useNavigate();

    return <LandingBase {...props} navigate={navigate} />;
}

export default  connect(null)(Landing);