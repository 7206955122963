import React from 'react';
import { Input, AutoComplete } from 'antd';
import { connect } from 'react-redux';
import { searchFieldChanged, fetchEducationIndustry } from '../../../actions/SearchActions';

class IndustryFilter extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			experienceSecIndustriesSearchQuery: this.props.experienceSecIndustriesSearchQuery,
			educationIndustries: this.props.educationIndustries
		};
	}

	componentWillReceiveProps(nextProps, nextContext) {
		if (nextProps !== this.props) {
			this.setState({
				experienceSecIndustriesSearchQuery: nextProps.experienceSecIndustriesSearchQuery,
				educationIndustries: nextProps.educationIndustries
			});
		}
	}

	handleSearch = (value) => {
		this.props.searchFieldChanged({ prop: "experienceSecIndustriesSearchQuery", value });
		this.props.fetchEducationIndustry({ searchQuery: value });
	};

	handleSelect = (value, option) => {
		const { objectID } = option.data;
		// Proceed as usual when an option is selected
		this.props.searchFieldChanged({ prop: "experienceSecIndustriesSearchQuery", value });
		this.props.searchFieldChanged({ prop: "industrySec", value: objectID });
	};

	handleClear = () => {
		console.log('x clicked');
		// Handle the case when the clear icon is clicked
		this.props.searchFieldChanged({ prop: "experienceSecIndustriesSearchQuery", value: "" });
		this.props.searchFieldChanged({ prop: "industrySec", value: null });
	}

	render() {
		const { experienceSecIndustriesSearchQuery, educationIndustries } = this.state;

		return (
			<div>
				<p style={{ color: "#000", fontFamily: "myriadBold", marginBottom: 3, textAlign: "left" }}>Experience Industry II</p>
				<AutoComplete
					value={experienceSecIndustriesSearchQuery}
					onChange={this.handleSearch}
					onSelect={this.handleSelect}
					onClear={this.handleClear}
					allowClear
					options={educationIndustries.map((result) => ({
						value: result.title,
						label: result.title,
						data: {
							title: result.title,
							objectID: result.objectID,
						},
					}))} // Convert search results to options format
					style={{ width: "100%", borderColor: '#000' }}
				>
					<Input.Search placeholder="Search Employment Industry" />
				</AutoComplete>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const { experienceSecIndustriesSearchQuery, educationIndustries } = state.search;
	return {
		experienceSecIndustriesSearchQuery, educationIndustries
	};
};

const mapDispatchToProps = {
	searchFieldChanged, fetchEducationIndustry,
};

export default connect(mapStateToProps, mapDispatchToProps)(IndustryFilter);
