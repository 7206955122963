import React, { Component } from 'react';
import {connect} from 'react-redux';

//action imports
import { settingsFieldChanged, savePromoCode } from "../../../actions/SettingsActions";

//common imports
import {Input} from "../../common/Input";
import {Button} from "../../common/Button";
import _ from "lodash";
import {Spinner} from "../../common/Spinner";

class AffiliateForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			profile: this.props.profile,
			email: this.props.email,
			affiliateCode: this.props.affiliateCode,
			bankName: this.props.bankName,
			bankCode: this.props.bankCode,
			accountNumber: this.props.accountNumber,
			accountName: this.props.accountName,
			commissionPercent: this.props.commissionPercent,
			promoCodeLoading: this.props.promoCodeLoading,
		};
	}

	componentWillReceiveProps(nextProps, nextContext) {

		if (nextProps !== this.props) {
			this.setState({
				profile: nextProps.profile,
				email: nextProps.email,
				affiliateCode: nextProps.affiliateCode,
				bankName: nextProps.bankName,

				bankCode: nextProps.bankCode,
				accountNumber: nextProps.accountNumber,

				accountName: nextProps.accountName,
				commissionPercent: nextProps.commissionPercent,
				promoCodeLoading: nextProps.promoCodeLoading,
			})
		}
	}

	handleSave = () => {
		const {
			email,
			affiliateCode,
			bankName,
			bankCode,
			accountNumber,
			accountName,
			commissionPercent,
		} = this.state;

		// Check if all fields are filled
		if (
			email &&
			affiliateCode &&
			bankName &&
			bankCode &&
			accountNumber &&
			accountName &&
			commissionPercent
		) {
			// Log the information
			this.props.savePromoCode({
				affiliateCode,
				accountName,
				accountNumber,
				bankCode,
				bankName,
				commissionPercent: parseFloat(commissionPercent),
				email
			});
			// console.log({
			// 	affiliateCode,
			// 	accountName,
			// 	accountNumber,
			// 	bankCode,
			// 	bankName,
			// 	commissionPercent: parseFloat(commissionPercent),
			// 	email
			// });
		} else {
			console.log('Please fill in all fields.');
		}
	};

	renderButton() {
		if (this.state.promoCodeLoading) {
			return <Spinner/>
		} else {
			return (
				<Button
					onPress={this.handleSave}
					text={"Save"}
				/>
			)
		}
	}

	checkUser() {
		//extract state
		const {profile} = this.state;

		if (!(_.isEmpty(profile))) {
			if (profile.role === "superAdmin") {
				return (
					<div style={{ backgroundColor: "white", padding: "0 2rem"}}>
						<div>
							<Input
								label={"Email"}
								type={'text'}
								onChange={e => this.props.settingsFieldChanged({prop: 'email', value: e.target.value})}
								value={this.state.email}
							/>
						</div>
						<div>
							<Input
								label={"affiliateCode"}
								type={'text'}
								onChange={e => this.props.settingsFieldChanged({
									prop: 'affiliateCode',
									value: e.target.value
								})}
								value={this.state.affiliateCode}
							/>
						</div>
						<div>
							<Input
								label={"bankName"}
								type={'text'}
								onChange={e => this.props.settingsFieldChanged({
									prop: 'bankName',
									value: e.target.value
								})}
								value={this.state.bankName}
							/>
						</div>
						<div>
							<Input
								label={"bankCode"}
								type={'text'}
								onChange={e => this.props.settingsFieldChanged({
									prop: 'bankCode',
									value: e.target.value
								})}
								value={this.state.bankCode}
							/>
						</div>
						<div>
							<Input
								label={"accountNumber"}
								type={'text'}
								onChange={e => this.props.settingsFieldChanged({
									prop: 'accountNumber',
									value: e.target.value
								})}
								value={this.state.accountNumber}
							/>
						</div>
						<div>
							<Input
								label={"accountName"}
								type={'text'}
								onChange={e => this.props.settingsFieldChanged({
									prop: 'accountName',
									value: e.target.value
								})}
								value={this.state.accountName}
							/>
						</div>
						<div>
							<Input
								label={"commissionPercent"}
								type={'text'}
								onChange={e => this.props.settingsFieldChanged({
									prop: 'commissionPercent',
									value: e.target.value
								})}
								value={this.state.commissionPercent}
							/>
						</div>

						{this.renderButton()}
					</div>
				);
			}
		}
	}

	render() {

		return (
			<div>
				{ this.checkUser() }
			</div>
		);
	}
}

const mapStateToProps = state => {
	const { profile } = state.profile;
	const { email, affiliateCode, bankName, bankCode, accountNumber, accountName, commissionPercent, promoCodeLoading } = state.settings;

	return {
		profile,
		email,
		affiliateCode,
		bankName,
		bankCode,
		accountNumber,
		accountName,
		commissionPercent,
		promoCodeLoading,
	}
}

	export default connect(mapStateToProps, { settingsFieldChanged, savePromoCode })(AffiliateForm);