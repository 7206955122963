import firebase from "firebase";
import {message} from "antd";

//-> Message Alert Icons
import { sendIcon} from '../components/common/MessageAlertIcons';

import {
    CONTACT_US_DETAILS_CHANGED,
    SAVE_CONTACT_DETAILS,
    SAVE_CONTACT_DETAILS_SUCCESSFUL,
    SAVE_CONTACT_DETAILS_FAILED,
    DATA_FOUND
} from "./Types";


export const contactUsDetailsChanged = ({prop, value}) => {
    return {
        type: CONTACT_US_DETAILS_CHANGED,
        payload: {prop, value}
    }
};


export const saveContactDetails = ({contactName, contactEmail, contactPhone, contactNote }) => {

    //create contact ID
    const contactID = Math.random().toString(36).substring(6).toUpperCase();
    const contactTime = firebase.firestore.FieldValue.serverTimestamp();

    return(dispatch) => {
        dispatch({
            type: SAVE_CONTACT_DETAILS,
        })
        firebase.firestore().collection("contactMessages").doc(contactID)
            .set({contactID, contactName, contactEmail, contactPhone, contactNote, contactTime})
            .then(() => {
                console.log("contact details saved successfully");
                dispatch({
                    type: SAVE_CONTACT_DETAILS_SUCCESSFUL,
                });
                message.success({
                    content:"We'll get back to you within ASAP!",
                    duration: 5,
                    icon: sendIcon(),
                });
            })
            .catch((error) => {
                console.log(error);
                console.log("Error saving contact details");
                message.warn("Kindly try again");
                dispatch({
                    type: SAVE_CONTACT_DETAILS_FAILED,
                })
            })
    }
};


export const addDevelopers = (fullName, email) => {
    const devID = Math.random().toString(36).substring(6).toUpperCase();
    const apiKeyID = Math.random().toString(36).substring(6).toUpperCase();
   

    return (dispatch) => {
        
        // Check if a document with the same email exists
        firebase.firestore().collection("developers")
            .where("email", "==", email)
            .get()
            .then((querySnapshot) => {
                if (!querySnapshot.empty) {
                    console.log("Document with the same email already exists");
                   message.error("Email already exists")
                    return;
                }

                // If no document with the same email exists, add the new developer
                // dispatch({
                //     type: SAVE_CONTACT_DETAILS,
                // });

                firebase.firestore().collection("developers").doc(devID)
                    .set({ fullName, email, devID })
                    .then(() => {
                    
                        generateApiKey(email, devID, apiKeyID, fullName);
                    
                        // Dispatch any success action if needed
                    })
                    .catch((error) => {
                        console.log("Error saving developer:", error);
                        // Dispatch any error action if needed
                    });
                    
            })
            .catch((error) => {
                console.log("Error checking for existing document:", error);
                // Handle the error
            });
    };
};


export const signInDev = (apiToken, email) => {
  
    return (dispatch) => {
       
        firebase.firestore().collection("developers")
            .where("email", "==", email )
            .where("apiKey", "==", apiToken )
            .get()
            .then((querySnapshot) => {
                if (!querySnapshot.empty) {
                    querySnapshot.forEach((doc) => {
                        const data = doc.data();
                        // Do something with the data
                        console.log(data); 
                        dispatch({ type: DATA_FOUND, payload: data });
                    });
                    return;    
                }

                console.log("Wrong credentials");
                message.error("Wrong Credentials")

            })
            .catch((error) => {
                console.error("Error getting documents: ", error);
            });
           
    };
};




function generateRandomApiKey(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let apiKey = '';

    for (let i = 0; i < length; i++) {
        apiKey += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    return apiKey;
}

function generateApiKey (email,devID, apiKeyID, fullName){
    
    const apiKey = generateRandomApiKey(32); // Generate a 32-character API key
    console.log(apiKey);
    firebase.firestore().collection("developers").doc(devID)
    .set({ apiKey }, { merge: true })
    .then(() => {
        console.log("api key saved");
        firebase.firestore().collection("apiKeys").doc(apiKeyID)
            .set({apiKey, owner:email})
           
    })
    .catch((error) => {
        console.log(error);
        console.log("Error occured saving api key");

    })
    .then(() => {
        sendEmail({
            email,
            apiKey,
            fullName,
            templateId: "d-8e84cada95f94a7a8838f26dc7e90251"
        }).catch(e => console.log('Error occured sending email',e));
        
    })
    .catch((error) => {
        console.log(error);
        console.log("Error occured on emailing", error);

    })
    
}


const sendEmail = async ({ email, apiKey, fullName,templateId }) => {

    console.log('Sending email ....')
  

    try {
        const response = await fetch("https://us-central1-nafasiio.cloudfunctions.net/sendApiKeyEmail", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email, apiKey, fullName,templateId
            }),
        });

        if (response.ok) {
            message.success("API Key sent to your email");
        } else {
            message.error("Something went wrong while sending your API Key");
        }
    } catch (error) {
        console.error("Error calling function:", error);
    } 
};