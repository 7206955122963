import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


//-> file imports
import logo from "../../../src/assets/images/logo.png"
import GoogleBadge from "../../../src/assets/img/google-play-badge.svg"
import AppleBadge from "../../../src/assets/img/app-store-badge.svg"

class ChangeDevice extends React.Component {
    render() {
        return (
            <div className={"mobileUnavailableContainer"}>
                <div>
                    <div className={"mobileUnavailableLogoArea"}>
                        <img src={logo} alt="Nafasi Logo" />
                    </div>

                    <div style={{marginBottom:"20px"}}>
                        <h2>Recruiter</h2>
                        <h4>Sign Up  using your personal computer or desktop</h4>
                    </div>

        
                    <div>
                        <h2>Job Applicant</h2>
                        <h4>Download our App and Sign Up</h4>
                        <div style={{display:'flex', alignItems:'center'}}>
                       
                       <a className="me-lg-3 mb-lg-0" href={"https://play.google.com/store/apps/details?id=com.nafasi.io"} target={"_blank"}>
                           <img 
                                src={GoogleBadge}
                                alt="..." height={140} width={160}/>
                       </a>

                       <a href={"https://apps.apple.com/tz/app/nafasi/id6450264936"} target={"_blank"}>
                           <img 
                                src={AppleBadge}
                                alt="..." height={140} width={160}/>
                                
                       </a>
                   </div>
                    </div>

                    <div>
                       
                    </div>
                </div>
            </div>
        );
    }
}

export default ChangeDevice;