import React from "react";
import moment from "moment";
import { Table, Popconfirm, Modal, message } from 'antd';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import CustomHeaderCard from "../../../search/cards/CustomHeaderCard";
import DescriptionModal from './DescriptionModal';

//-> action imports

//-> component import

class PaymentCard extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            payments: this.props.payments,
        };
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps !== this.props) {
            this.setState({
                payments: nextProps.payments,
            });
        }
    }

    showModal() {
        this.setState({
            visible: true,
        });
    };

    handleOk = () => {
        this.setState({
            visible: false,
        });
    };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };

    renderAward(){
        const { payments } = this.state;

        let processedResults = payments.map(result => {
            let seconds;
            result.paymentDate.seconds ? seconds = result.paymentDate.seconds : seconds = result.paymentDate._seconds;
            const paymentMoment = moment.unix(seconds);
    
            //compute the number of days
            const paymentString = paymentMoment.format('HH:mm | DD MMM YYYY');
            return { ...result, paymentString};
        })

        const columns = [
            {
                title: <CustomHeaderCard>Package</CustomHeaderCard>,
                dataIndex: 'paymentPackage.title',
                key: 'paymentPackage.title',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: record.color }}>{record.paymentPackage.title}</div>
                ),
            },
            {
                title: <CustomHeaderCard>Details</CustomHeaderCard>,
                dataIndex: 'details',
                key: 'details',
                render: (_, record) => (
					<div style={{fontSize: '0.9rem',height: '23px',overflow: 'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap'}}>
						<DescriptionModal record={record}/>
					</div>
                ),
            },
            {
                title: <CustomHeaderCard>Date</CustomHeaderCard>,
                dataIndex: 'paymentString',
                key: 'paymentString',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: record.color }}>{record.paymentString}</div>
                ),
            },
        ];


        return (
            <Table dataSource={processedResults} columns={columns} rowKey="id" />
        )
    }

    render() {
        return(
            <div>
                {this.renderAward()}
            </div>
        );
    }
}

export default PaymentCard;