import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Select } from 'antd';

//action imports
import { searchFieldChanged } from '../../../actions/SearchActions';

const { Option } = Select;

class ExperienceSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            experienceID: null,
            experienceYears: null,
            isExperienceYearsEnabled: false,
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props) {
            this.setState({
                experienceID: nextProps.experienceID,
                experienceYears: nextProps.experienceYears,
            });
        }

        if (nextProps.experienceID === null) {
            this.setState({
                isExperienceYearsEnabled: false,
            });
        }
    }

    handleExperienceChange = (value) => {
        if (value) {
            this.setState({isExperienceYearsEnabled: true});

            this.props.searchFieldChanged({ prop: "experienceID", value });
        } else {
            this.setState({isExperienceYearsEnabled: false});
            this.props.searchFieldChanged({ prop: "experienceID", value: null });
            this.props.searchFieldChanged({ prop: "experienceYears", value: null });
        }
    };

    handleExperienceYearsChange = (value) => {
        if (value) {
            this.props.searchFieldChanged({ prop: "experienceYears", value });
        } else {
            this.props.searchFieldChanged({ prop: "experienceYears", value: null });
        }
    };

    render() {
        const { experienceID, experienceYears, isExperienceYearsEnabled } = this.state;

        return (
            <div>
                <p style={{ color: "#000", fontFamily: "myriadBold", marginBottom: 3, textAlign: "left" }}>Minimum Experience</p>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <Select
                        placeholder={"Position"}
                        value={experienceID}
                        allowClear
                        style={{ width: "100%"}}
                        onChange={this.handleExperienceChange}
                    >
                        <Option value="managerial">Managerial</Option>
                        <Option value="senior">Senior</Option>
                        <Option value="lead">Lead</Option>
                        <Option value="junior">Junior</Option>
                    </Select>

                    <Select
                        placeholder={"Minimum Years"}
                        value={experienceYears}
                        allowClear
                        style={{ width: "100%", borderColor: '#000' }}
                        onChange={this.handleExperienceYearsChange}
                        disabled={!isExperienceYearsEnabled}
                    >
                        <Option value={1}>1 year</Option>
                        <Option value={2}>2 years</Option>
                        <Option value={5}>5 years</Option>
                        <Option value={10}>10 years</Option>
                    </Select>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { experienceID, experienceYears } = state.search;
    return {
        experienceID, experienceYears
    };
};

export default connect(mapStateToProps, { searchFieldChanged })(ExperienceSelector);
