import React from "react";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "../Employment.css"
import { Table, Button, Modal, message } from 'antd';


//-> action imports

//-> component import
import CustomHeaderCard from "../../../search/cards/CustomHeaderCard";
import DescriptionModal from './DescriptionModal';


class EmploymentCard extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            employment: this.props.employment,
        };
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps !== this.props) {
            this.setState({
                employment: nextProps.employment,
            });
        }
    }


    renderEducation(){
        const { employment } = this.state;

        let processedResults = employment.map(result => {

            let seconds;
            result.timeOfWorkFrom.seconds ? seconds = result.timeOfWorkFrom.seconds : seconds = result.timeOfWorkFrom._seconds;
            const employedFrom = moment.unix(seconds);
    
            let toSeconds;
            result.timeOfWorkTo.seconds ? toSeconds = result.timeOfWorkTo.seconds : toSeconds = result.timeOfWorkTo._seconds;
            const employedTo = moment.unix(toSeconds);

            const employedFromString = employedFrom.format('DD MMM YYYY');
            const employedToString = employedTo.format('DD MMM YYYY');

            const formattedLocation = `${result.location.country}, ${result.location.region}`;


            return { ...result, employedToString, employedFromString, formattedLocation };
        });


        const columns = [
            {
                title: <CustomHeaderCard>Current</CustomHeaderCard>,
                dataIndex: 'currentWork',
                key: 'currentWork',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.currentWork ? "Yes" : "No"}</div>
                ),
            },
            {
                title: <CustomHeaderCard>Company</CustomHeaderCard>,
                dataIndex: 'jobCompany',
                key: 'jobCompany',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.jobCompany}</div>
                ),
            },
            {
                title: <CustomHeaderCard>Position</CustomHeaderCard>,
                dataIndex: 'jobTitle.title',
                key: 'jobTitle.title',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.jobTitle.title}</div>
                ),
            },
            {
                title: <CustomHeaderCard>Description</CustomHeaderCard>,
                dataIndex: 'description',
                key: 'description',
                render: (_, record) => (
                    <div style={{fontSize: '0.9rem',height: '23px',overflow: 'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap'}}>
                        <DescriptionModal record={record}/>
                    </div>
                ),
            },
            {
                title: <CustomHeaderCard>Location</CustomHeaderCard>,
                dataIndex: 'formattedLocation',
                key: 'formattedLocation',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.formattedLocation}</div>
                ),
            },
            {
                title: <CustomHeaderCard>From</CustomHeaderCard>,
                dataIndex: 'employedFromString',
                key: 'employedFromString',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.employedFromString}</div>
                ),
            },
            {
                title: <CustomHeaderCard>To</CustomHeaderCard>,
                key: 'employedToString',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: '#000' }}>{record.currentWork ? "Currently Hired" : record.employedToString}</div>
                ),
            },
        ];

        return (
            <Table dataSource={processedResults} columns={columns} rowKey="id" />
        )
    }

    render() {
        return(
            <div>
                {this.renderEducation()}
            </div>
        );
    }
}

export default EmploymentCard;