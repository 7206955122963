

import React, { Component } from 'react'

class TermsAndConditions extends Component {
  render() {
    return (
      <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0 }}>
        <h2 style={{ color:"#000", marginBottom: 50 }}>Terms And Conditions</h2>
        <iframe 
          src="https://firebasestorage.googleapis.com/v0/b/nafasiio.appspot.com/o/termsAndConditions%2FGXX4JVJ?alt=media&token=e506bcbb-f063-40a3-886d-3b5fbd4fb76c
          " 
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            border: 'none',
            overflow: 'hidden'
          }}
        />
      </div>
    );
  }
}

export default TermsAndConditions;
