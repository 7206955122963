import React, { Component } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Form, Input, Button, Select, notification} from 'antd';

import {Spinner} from "../common/Spinner";

const { Option } = Select;

class ReferenceBase extends Component {
	formRef = React.createRef();

	state = {
		candidateName: '',
		company: '',
		email: '',
		firstName: '',
		lastName: '',
		phone: '',
		refereeID: '',
		title: '',
		loading: true,
		saveLoading: false,
	};

	componentDidMount() {
		const { id } = this.props;
		this.fetchReference(id);
	}

	fetchReference = (id) => {
		fetchData(id).then(data => {
			this.setState({ ...data, loading: false });
		}).catch(error => {
			console.error('Failed to fetch data:', error);
		});
	};

	onFinish = (data) => {
		const { userID, refereeID } = this.state;
		console.log('Received values from form: ', data);
		this.setState({ saveLoading: true });
		saveVet(userID, refereeID, data)
			.then(response => {
				this.formRef.current.resetFields();
				console.log('Function executed successfully:', response);
				this.setState({ saveLoading: true });
				//message.success(response);
				// Success notification
				notification.success({
					message: 'Submission Successful',
					description: 'Your information has been saved.',
				});
			})
			.catch(error => {
				console.error('Failed to execute function:', error);
				this.setState({ saveLoading: true });
				notification.error({
					message: 'Submission Failed',
					description: 'Your information has not been saved.',
				});
			});
	};

	renderButton() {
		const { saveLoading } = this.state;

		if (saveLoading) {
			return <Spinner/>
		} else {
			return (
				<Form.Item>
					<Button type="primary" htmlType="submit">
						Submit
					</Button>
				</Form.Item>
			)
		}
	}

	render() {
		const {candidateName, loading} = this.state;

		if (loading) {
			return (
				<div className={'reference'} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
					<Spinner/>
				</div>
			);
		} else {
			return (
				<div className={"reference"}>
					<h2 style={{ marginBottom: '2rem' }}>{candidateName}'s Resume</h2>
					<Form layout="vertical" onFinish={this.onFinish} ref={this.formRef}>
						<Form.Item
							name="relationship"
							label="Your Relationship with the Candidate"
							rules={[{required: true, message: 'Please select your relationship with the candidate!'}]}
						>
							<Select placeholder="Select a relationship">
								<Option value="supervisor">Supervisor/Manager</Option>
								<Option value="professor">Professor</Option>
								<Option value="colleague">Colleague/Peer</Option>
							</Select>
						</Form.Item>

						<Form.Item
							name="duration"
							label="Duration of Acquaintance"
							rules={[{required: true, message: 'Please select the duration of your acquaintance!'}]}
						>
							<Select placeholder="Select duration">
								<Option value="months">Months</Option>
								<Option value="years">Years</Option>
							</Select>
						</Form.Item>

						<Form.Item
							name="communication"
							label="Communication Skills"
							rules={[{required: true, message: 'Please rate the candidate!'}]}
						>
							<Select placeholder="Select rating">
								<Option value="5">Exceptional</Option>
								<Option value="4">Good</Option>
								<Option value="3">Average</Option>
								<Option value="2">Below Average</Option>
								<Option value="1">No Opportunity to Observe</Option>
							</Select>
						</Form.Item>

						<Form.Item
							name="attitude"
							label="Attitude towards work (Discipline/Initiative)"
							rules={[{required: true, message: 'Please rate the candidate!'}]}
						>
							<Select placeholder="Select rating">
								<Option value="5">Exceptional</Option>
								<Option value="4">Good</Option>
								<Option value="3">Average</Option>
								<Option value="2">Below Average</Option>
								<Option value="1">No Opportunity to Observe</Option>
							</Select>
						</Form.Item>

						<Form.Item
							name="leadership"
							label="Leadership (can lead a team, is a great listener, empowers others)"
							rules={[{required: true, message: 'Please rate the candidate!'}]}
						>
							<Select placeholder="Select rating">
								<Option value="5">Exceptional</Option>
								<Option value="4">Good</Option>
								<Option value="3">Average</Option>
								<Option value="2">Below Average</Option>
								<Option value="1">No Opportunity to Observe</Option>
							</Select>
						</Form.Item>

						<Form.Item
							name="reliability"
							label="Reliability"
							rules={[{required: true, message: 'Please rate the candidate!'}]}
						>
							<Select placeholder="Select rating">
								<Option value="5">Exceptional</Option>
								<Option value="4">Good</Option>
								<Option value="3">Average</Option>
								<Option value="2">Below Average</Option>
								<Option value="1">No Opportunity to Observe</Option>
							</Select>
						</Form.Item>

						<Form.Item
							name="creativity"
							label="Creativity & Problem Solving"
							rules={[{required: true, message: 'Please rate the candidate!'}]}
						>
							<Select placeholder="Select rating">
								<Option value="5">Exceptional</Option>
								<Option value="4">Good</Option>
								<Option value="3">Average</Option>
								<Option value="2">Below Average</Option>
								<Option value="1">No Opportunity to Observe</Option>
							</Select>
						</Form.Item>

						<Form.Item
							name="eq"
							label="Emotional Intelligence (reaction to stress, poise, control, inspires confidence)"
							rules={[{required: true, message: 'Please rate the candidate!'}]}
						>
							<Select placeholder="Select rating">
								<Option value="5">Exceptional</Option>
								<Option value="4">Good</Option>
								<Option value="3">Average</Option>
								<Option value="2">Below Average</Option>
								<Option value="1">No Opportunity to Observe</Option>
							</Select>
						</Form.Item>

						<Form.Item
							name="overallImpression"
							label="Your Overall Impression and Support of the Candidate for any Future Role"
							rules={[{required: true, message: 'Please select your overall impression!'}]}
						>
							<Select placeholder="Select your impression">
								<Option value="high">High</Option>
								<Option value="acceptable">Acceptable</Option>
								<Option value="unacceptable">Unacceptable</Option>
							</Select>
						</Form.Item>

						<Form.Item
							name="comments"
							label="In your words, please give us your overall impression of the candidate"
							rules={[{required: true, message: 'Please add some comments about the candidate!'}]}
						>
							<Input.TextArea rows={4} placeholder="Enter your comments"/>
						</Form.Item>

						{this.renderButton()}
					</Form>
				</div>
			);
		}
	}
}

async function fetchData(refereeID) {
	// Replace 'YOUR_FUNCTIONS_ENDPOINT_URL' with the actual URL of your Firebase function
	const endpoint = `https://us-central1-nafasiio.cloudfunctions.net/fetchReferee`;

	const requestBody = {
		refereeID,
	};

	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(requestBody)
	};

	try {
		// Make a GET request to the Firebase HTTP function
		const response = await fetch(endpoint, requestOptions);

		if (!response.ok) {
			// If the server returns a non-2xx HTTP status, throw an error
			const errorText = await response.text();
			throw new Error(`Server returned error: ${errorText}`);
		}

		// Parse the JSON response
		const data = await response.json();
		return data;
	} catch (error) {
		console.error('Error fetching data:', error);
		throw error; // Re-throw the error to handle it further up the call stack if necessary
	}
}

async function saveVet(userID, refereeID, data) {
	// Replace 'YOUR_FIREBASE_FUNCTION_URL' with the actual URL of your Firebase function
	const url = 'https://us-central1-nafasiio.cloudfunctions.net/saveVet';

	const requestBody = {
		userID,
		refereeID,
		data
	};

	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(requestBody)
	};

	try {
		const response = await fetch(url, requestOptions);

		if (!response.ok) {
			// If the server returns an error, throw an error with the status
			throw new Error(`HTTP error! Status: ${response.status}`);
		}

		const responseText = await response.text();
		return responseText;
	} catch (error) {
		console.error('Error sending data:', error);
		throw error; // Rethrow the error for further handling if needed
	}
}

// Wrapper functional component to use hooks
function Reference(props) {
	const navigate = useNavigate();
	const { id } = useParams();

	return <ReferenceBase {...props} navigate={navigate} id={id} />;
}

export default Reference;