import React from 'react';
import  { connect } from 'react-redux';
import {message, DatePicker, Modal} from 'antd';
import _ from 'lodash';
import {ClipLoader} from "react-spinners";

//actions import
import { postsFieldChanged, addPost, clearPost } from "../../../actions/PostsActions";

//components import
import {Input} from "../../common/Input";
import SelectJobStatus from "../../common/SelectJobStatus";
import SelectEmploymentType from "../../common/SelectEmploymentType";
import {TextArea} from "../../common/TextArea";
import {Button} from "../../common/Button";
import SelectDepartment from "../../common/SelectDepartment";
import CountryRegionPicker from "../../common/CountryRegionPicker";

class AddJobPost extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

            visible: false,
            jobTitle: this.props.jobTitle,
            jobStatus: this.props.jobStatus,
            department: this.props.department,
            employmentType: this.props.employmentType,
            minimumExperience: this.props.minimumExperience,
            jobDescription: this.props.jobDescription,
            address: this.props.address,
            postalCode: this.props.postalCode,
            compensation: this.props.compensation,
            country: this.props.country,
            region: this.props.region,
            file: this.props.file,
            companyName: this.props.companyName,
            email: this.props.email,
            phone: this.props.phone,
            deadline: this.props.deadline,


            addPostLoading: this.props.addPostLoading,
            postSaved: this.props.postSaved,
            postFailed: this.props.postFailed,

            profile: this.props.profile,
        };

        this.onSelectDepartment = this.onSelectDepartment.bind(this);
        this.onSelectEmploymentType = this.onSelectEmploymentType.bind(this);
        this.onSelectJobStatus = this.onSelectJobStatus.bind(this);
        this.handleFile = this.handleFile.bind(this);
        this.onDeadlineChange = this.onDeadlineChange.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps !== this.props) {
            this.setState({
                profile: nextProps.profile,
                jobTitle: nextProps.jobTitle,
                jobStatus: nextProps.jobStatus,
                department: nextProps.department,
                employmentType: nextProps.employmentType,
                minimumExperience: nextProps.minimumExperience,
                jobDescription: nextProps.jobDescription,
                address: nextProps.address,
                postalCode: nextProps.postalCode,
                compensation: nextProps.compensation,
                addPostLoading: nextProps.addPostLoading,
                postSaved: nextProps.postSaved,
                postFailed: nextProps.postFailed,
                country: nextProps.country,
                region: nextProps.region,
                file: nextProps.file,
                companyName: nextProps.companyName,
                email: nextProps.email,
                phone: nextProps.phone,
                deadline: nextProps.deadline,
            });
        }
    }

    onSelectJobStatus(value) {
        this.props.postsFieldChanged({prop: 'jobStatus', value})
    }

    onSelectDepartment(value) {
        this.props.postsFieldChanged({prop: 'department', value})
    }

    onSelectEmploymentType(value) {
        this.props.postsFieldChanged({prop: 'employmentType', value})
    }

    onDeadlineChange(date) {
        this.props.postsFieldChanged({prop: 'deadline', value: date });
    };

    renderButton() {
        const {addPostLoading} = this.state;

        if (addPostLoading) {
            return (
                <div>
                    <ClipLoader
                        size={20}
                    />
                </div>
            );
        } else {
            return (
                <div>
                    <Button
                        text={'Save'}
                        onPress={() => this.onSave()}
                    />
                </div>
            );
        }
    }

    renderSavedMessage() {
        const {postSaved} = this.state;

        if (postSaved) {
            return <p>{message.success("Post saved successfully")}</p>
        } else {
            return null;
        }
    }

    renderFailedMessage() {
        const {postFailed} = this.state;

        if (postFailed) {
            return <p>{message.error("Post not saved ")}</p>
        } else {
            return null;
        }
    }

    onSave() {
        //extract state
        const { jobTitle, jobStatus, department, employmentType, minimumExperience, jobDescription, country, region, address, postalCode, compensation,
            companyName, email, phone, file, deadline, profile } = this.state;

        if(!(_.isEmpty(profile))){
            if (jobTitle && jobStatus && employmentType && minimumExperience && jobDescription && country && region &&
                address && compensation && companyName && email && phone && file && deadline ) {
                const companyLocation = {
                    country: country.countryName,
                    countryCode: country.countryShortCode,
                    region: region.name,
                    regionCode: region.shortCode,
                    streetAddress: address,
                    postalCode
                }

                this.props.addPost({ jobTitle, status: jobStatus, department, jobType: employmentType, minimumExperience: parseInt(minimumExperience), jobDescription, compensation: parseInt(compensation), companyLocation, companyName, email, phone, deadline: deadline.toDate(), file });
            } else {
                message.warning("Kindly fill all fields");
            }
        }
    }

    async handleFile(event) {
        //files is an array, so pick first element
        const file = event.target.files[0];
        const maxSize = 10 * 1024 * 1024; // 2MB maximum size (in bytes)

        if (file && file.size > maxSize) {
            message.info('File size exceeds the maximum limit of 10MB.');
            // Optionally, you can reset the file input value
            event.target.value = null;
        } else {
            // File size is within the limit, you can proceed with the upload
            // ...
            const fileToBase64 = (file) => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = (error) => reject(error);
                });
            };
            const fileData = await fileToBase64(file);
            const { name, type, size } = file;
            this.props.postsFieldChanged({ prop: 'file', value: { name, type, size, data: fileData } });
        }
    }

    showModal() {
        this.props.clearPost();
        this.setState({
            visible: true,
        });
    };

    handleOk = () => {
        this.setState({
            visible: false,
        });
    };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };

    checkAccess(){
        //-> extract state
        const { profile } = this.state;

        //- check access level
        if(!(_.isEmpty(profile))){
            //-> extract user role
            const { role } = this.state.profile;

            if( role === 'superAdmin' || role === "admin"){
                return (
                    <div className={"addJobOpeningBox"}>
                        <div className={"addJobOpeningInfoBox"}>
                            <div className={"jobPostTitle"}>
                                <Input
                                    label={'Posting Title'}
                                    placeholder={"Finance Officer"}
                                    type={'text'}
                                    onChange={ e => this.props.postsFieldChanged({prop: 'jobTitle', value: e.target.value})}
                                    value={this.state.jobTitle}
                                />
                            </div>
                            <div className={"container1"}>
                                <SelectJobStatus
                                    label={'Job Status'}
                                    placeholder={'Select Job Status'}
                                    onChange={this.onSelectJobStatus}
                                    value={this.state.jobStatus}
                                />
                                 <SelectEmploymentType
                                    label={'Employment Type'}
                                    placeholder={'Employment Type'}
                                    onChange={this.onSelectEmploymentType}
                                    value={this.state.employmentType}
                                />
                            </div>
                            <div className={"container2"}>
                                <SelectDepartment
                                    label={'Department'}
                                    placeholder={'Select Department'}
                                    onChange={this.onSelectDepartment}
                                    value={this.state.department}
                                />
                                 <Input
                                    label={'Minimum Experience (Years)'}
                                    type={'number'}
                                    onChange={ e => this.props.postsFieldChanged({prop: 'minimumExperience', value: e.target.value})}
                                    value={this.state.minimumExperience}
                                />
                            </div>
                            {/* <div className={"jobPostEmpType"}>
                                <SelectEmploymentType
                                    label={'Employment Type'}
                                    placeholder={'Employment Type'}
                                    onChange={this.onSelectEmploymentType}
                                    value={this.state.employmentType}
                                />
                            </div> */}
                            {/* <div className={"jobPostExperience"}>
                                <Input
                                    label={'Minimum Experience (Years)'}
                                    type={'number'}
                                    onChange={ e => this.props.postsFieldChanged({prop: 'minimumExperience', value: e.target.value})}
                                    value={this.state.minimumExperience}
                                />
                            </div> */}
                            <div className={"container3"}>
                                <p>Deadline to accept interview</p>
                                <DatePicker
                                    showTime={{ format: 'HH:mm:ss' }}
                                    format="DD-MM-YYYY | HH:mm:ss"
                                    onChange={this.onDeadlineChange}
                                    style={{ width: '400px' }} 
                                />
                            </div>
                            <div className={"jobPostDescription"}>
                                <div className={"container4"}>
                                    <label style={{ margin: "1rem 1rem 1rem 0"}}>Job Post Description File</label>
                                    <input
                                        type={'file'}
                                        onChange={(e) => this.handleFile(e)}
                                        accept={"application/pdf"}
                                    />
                                </div>
                                <TextArea
                                    label={'Job Summary'}
                                    onChange={ e => this.props.postsFieldChanged({prop: 'jobDescription', value: e.target.value})}
                                    value={this.state.jobDescription}
                                    maxLength={500}
                                />
                            </div>
                            <div>
                                <Input
                                    label={"Email"}
                                    type={'text'}
                                    placeholder={'Email'}
                                    onChange={e => this.props.postsFieldChanged({prop: 'email', value: e.target.value}) }
                                    value={this.state.email}
                                    onKeyDown={this.keyPressed}
                                />
                            </div>
                            <div>
                                <Input
                                    label={"Company Name"}
                                    type={'text'}
                                    placeholder={'Company Name'}
                                    onChange={e => this.props.postsFieldChanged({prop: 'companyName', value: e.target.value}) }
                                    value={this.state.companyName}
                                    onKeyDown={this.keyPressed}
                                />
                            </div>

                            <div>
                                <Input
                                    label={"Phone"}
                                    type={'text'}
                                    placeholder={'Company Phone'}
                                    onChange={e => this.props.postsFieldChanged({prop: 'phone', value: e.target.value}) }
                                    value={this.state.phone}
                                    maxLength={10}
                                />
                            </div>

                            <div className={"jobPostAddress"}>
                                <CountryRegionPicker/>
                                <Input
                                    label={'Address'}
                                    onChange={ e => this.props.postsFieldChanged({prop: 'address', value: e.target.value})}
                                    value={this.state.address}
                                />
                                <Input
                                    label={'Postal Code'}
                                    onChange={ e => this.props.postsFieldChanged({prop: 'postalCode', value: e.target.value})}
                                    value={this.state.postalCode}
                                />
                            </div>
                            <div className={"jobPostCompensation"}>
                                <Input
                                    label={'Compensation'}
                                    type={'number'}
                                    onChange={ e => this.props.postsFieldChanged({prop: 'compensation', value: e.target.value})}
                                    value={this.state.compensation}
                                />
                            </div>
                        </div>

                        <div style={{ margin: "2rem 0 1rem 0"}}>
                            {this.renderButton()}
                        </div>
                        {this.renderSavedMessage()}
                        {this.renderFailedMessage()}
                    </div>
                )
            }
        }
    };


    render() {
        return (
            <div>
                <div className={"addJobOpeningBox"}>
                    <div style={{ margin: "1rem"}}>
                        <Button
                            text={"Add Job Post"}
                            onPress={() => this.showModal()}
                        />
                    </div>
                </div>
                <Modal
                    title={"Add Job Post"}
                    open={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}

                    footer={null}
                    width={900}
                >
                    {this.checkAccess()}
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    const { profile } = state.profile;

    const {jobTitle, jobStatus, department, employmentType, minimumExperience, jobDescription, address, postalCode, compensation,
        addPostLoading, postSaved, postFailed, country, region, file, companyName, email, phone, deadline } = state.posts;

    return {
        jobTitle,
        jobStatus,
        department,
        employmentType,
        minimumExperience,
        jobDescription,
        postalCode,
        address,
        compensation,

        country, region, file, companyName, email, phone, deadline,

        addPostLoading,
        postSaved,
        postFailed,

        profile,
    }

};

export default connect(mapStateToProps,{ postsFieldChanged, addPost, clearPost })(AddJobPost);