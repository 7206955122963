import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import {ClipLoader} from "react-spinners";

import './SearchPage.css'

import {Input, message, Select, Table, notification} from 'antd';
import { SearchVisualIcon } from 'hugeicons-react';

//-> action import
import { searchDB, clearSearch } from "../../actions/SearchActions";
import { fetchAdminHighlights } from "../../actions/SearchActions";

//common imports
import { Button } from '../common/Button';
import { Spinner } from '../common/Spinner';


//components import
import ResultCard from "./cards/ResultCard";
// import PackageIndexFilter from "./announcements/PackageIndexFilter";
import RelocationFilter from "./subComponents/RelocationFilter";
import CountryFilter from "./subComponents/CountryFilter";
import JobTypeFilter from "./subComponents/JobTypeFilter";
import WorkScheduleFilter from "./subComponents/WorkScheduleFilter";
import GenderFilter from "./subComponents/GenderFilter";
import MaritalStatus from "./subComponents/MaritalStatus";
import BirthdateFilter from "./subComponents/BirthdateFilter";
import JobTitleFilter from "./subComponents/JobTitleFilter";
import EducationLevelFilter from "./subComponents/EducationLevelFilter";
import EducationIndustryFilter from "./subComponents/IndustryFilter";
import SkillsFilter from "./subComponents/SkillsFilter";
import CertificatesFilter from "./subComponents/CertificatesFilter";
import LanguagesFilter from "./subComponents/LanguagesFilter";
import LicensesFilter from "./subComponents/LicensesFilter";
import LinksFilter from "./subComponents/LinksFilter";
import YearOfCompletion from "./subComponents/YearOfCompletion";
import CurrentEducationEnrollment from "./subComponents/CurrentEducationEnrollment";
import ExperienceFilter from "./subComponents/ExperienceFilter";
import InternationalExperience from "./subComponents/InternationalExperience";
import StudiedAbroad from "./subComponents/StudiedAbroad";
import ExperienceIndustryPrime from "./subComponents/ExperienceIndustryPrime";
import ExperienceIndustrySec from "./subComponents/ExperienceIndustrySec";
import PositionExperiencePrime from "./subComponents/PositionExperiencePrime";
import PositionExperienceSec from "./subComponents/PositionExperienceSec";
import RecentSearches from "./RecentSearches";
import {StickyNote01Icon} from "hugeicons-react";

class Search extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            packageIndex: this.props.packageIndex,
            openForRelocation: this.props.openForRelocation,
            country: this.props.country,
            region: this.props.region,
            jobTypeArray: this.props.jobTypeArray,
            workScheduleArray: this.props.workScheduleArray,
            gender: this.props.gender,
            maritalStatus: this.props.maritalStatus,
            startBirthDate: this.props.startBirthDate,
            endBirthDate: this.props.endBirthDate,
            certificateID: this.props.certificateID,
            jobTitle: this.props.jobTitle,
            educationLevel: this.props.educationLevel,
            educationIndustry: this.props.educationIndustry,
            currentEnrollment: this.props.currentEnrollment,
            studiedAbroad: this.props.studiedAbroad,
            yearOfCompletion: this.props.yearOfCompletion,
            industryID: this.props.industryID,
            currentAtWork: this.props.currentAtWork,
            experienceID: this.props.experienceID,
            experienceYears: this.props.experienceYears,
            foreignJob: this.props.foreignJob,
            language: this.props.language,
            license: this.props.license,
            militaryCountry: this.props.militaryCountry,
            industryPrime: this.props.industryPrime,
            industrySec: this.props.industrySec,
            positionPrime: this.props.positionPrime,
            positionSec: this.props.positionSec,
            skill: this.props.skill,
            link: this.props.link,
            results: this.props.results,
            loading: this.props.loading,
            profile: this.props.profile,
            resultsSearchLimit: this.props.resultsSearchLimit,
            search: true,
            moreFilters: false,
            seekerHighlights: this.props.seekerHighlights
        };

        this.renderResults = this.renderResults.bind(this);
        this.renderSearchButton = this.renderSearchButton.bind(this);
        this.renderViewMore = this.renderViewMore.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }

    componentDidMount() {
        this.props.fetchAdminHighlights();
    }

    componentWillReceiveProps(nextProps){
        if (nextProps !== this.props) {
            this.setState({
                leaveRequests: nextProps.leaveRequests,
                packageIndex: nextProps.packageIndex,
                openForRelocation: nextProps.openForRelocation,
                country: nextProps.country,
                region: nextProps.region,
                jobTypeArray: nextProps.jobTypeArray,
                workScheduleArray: nextProps.workScheduleArray,
                gender: nextProps.gender,
                maritalStatus: nextProps.maritalStatus,
                startBirthDate: nextProps.startBirthDate,
                endBirthDate: nextProps.endBirthDate,
                certificateID: nextProps.certificateID,
                jobTitle: nextProps.jobTitle,
                educationLevel: nextProps.educationLevel,
                educationIndustry: nextProps.educationIndustry,
                currentEnrollment: nextProps.currentEnrollment,
                studiedAbroad: nextProps.studiedAbroad,
                yearOfCompletion: nextProps.yearOfCompletion,
                industryID: nextProps.industryID,
                currentAtWork: nextProps.currentAtWork,
                experienceID: nextProps.experienceID,
                experienceYears: nextProps.experienceYears,
                foreignJob: nextProps.foreignJob,
                language: nextProps.language,
                license: nextProps.license,
                militaryCountry: nextProps.militaryCountry,
                industryPrime: nextProps.industryPrime,
                industrySec: nextProps.industrySec,
                positionPrime: nextProps.positionPrime,
                positionSec: nextProps.positionSec,
                skill: nextProps.skill,
                link: nextProps.link,
                results: nextProps.results,
                loading: nextProps.loading,
                profile: nextProps.profile,
                resultsSearchLimit: nextProps.resultsSearchLimit,
                seekerHighlights: nextProps.seekerHighlights
            });
        }
    }


    onSearch(more) {
        let { profile, packageIndex, openForRelocation, country, region,
            jobTypeArray, workScheduleArray, certificateID, jobTitle, gender,
            maritalStatus, startBirthDate,
            endBirthDate, educationLevel, educationIndustry,
            currentEnrollment, studiedAbroad, yearOfCompletion, industryID,
            currentAtWork, experienceID, experienceYears, foreignJob, language,
            license, militaryCountry,
            industryPrime,
            industrySec,
            positionPrime,
            positionSec,
            skill, link, resultsSearchLimit } = this.state;

        if (more) {
            resultsSearchLimit = resultsSearchLimit + 50;
        }

        if (country !== null) {
            country = country.countryShortCode;
        }

        if (region !== null) {
            region = region.shortCode;
        }

        let experience = null;
        if (experienceID !== null || experienceYears !== null) {
            experience = { ID: experienceID, years: experienceYears }
        }

        if(!(_.isEmpty(profile))) {
            this.props.searchDB({
                packageIndex, openForRelocation, country, region,
                jobTypeArray, workScheduleArray, gender,
                maritalStatus,
                startBirthDate: startBirthDate ? startBirthDate.toDate() : null,
                endBirthDate: endBirthDate ? endBirthDate.toDate(): null, certificateID,
                jobTitle,
                educationLevel, educationIndustry,
                currentEnrollment, studiedAbroad, experience, foreignJob, yearOfCompletion, industryID,
                currentAtWork, language, license, militaryCountry,
                industryPrime,
                industrySec,
                positionPrime,
                positionSec,
                skill, link, resultsSearchLimit
            });

            // console.log({
            //     packageIndex, openForRelocation, country, region,
            //     jobTypeArray, workScheduleArray, gender,
            //     maritalStatus,
            //     startBirthDate: startBirthDate ? startBirthDate.toDate() : null,
            //     endBirthDate: endBirthDate ? endBirthDate.toDate(): null, certificateID,
            //     jobTitle,
            //     educationLevel, educationIndustry,
            //     currentEnrollment, studiedAbroad, experience, foreignJob, yearOfCompletion, industryID,
            //     currentAtWork, language, license, militaryCountry,
            //     industryPrime,
            //     industrySec,
            //     positionPrime,
            //     positionSec,
            //     skill, link, resultsSearchLimit
            // })

            if (startBirthDate && endBirthDate) {
                console.log({ endBirthDate: endBirthDate.format("DD MM YYYY"), startBirthDate: startBirthDate.format("DD MM YYYY") });
                const startBD = moment(startBirthDate);
                const endBD = moment(endBirthDate);
                if (endBirthDate.isSameOrBefore(startBirthDate, "days")) {
                    notification.warning({
                        message: 'Invalid Birthdate Range',
                        description:
                            'Ending birthdate should not be the same or before the starting birthdate',
                        duration: 5,
                    });
                }
            }
        }
    }

    renderSearchButton(){
        const {loading} = this.state;

        if (loading) {
            return (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "10vh" }}>
                    <Spinner />
                </div>
            );
        } else {
            return (
                <div className={"allLeaveRequestsButtonBox"} style={{ display: "grid", gridTemplate: "1fr / 2fr 1fr", gridGap: "1rem", marginBottom: "1rem" }}>
                    <Button
                        text={"Search"}
                        onPress={() => this.onSearch(false)}
                    />
                    <Button
                        text={"Clear Search"}
                        onPress={() => this.props.clearSearch()}
                    />
                </div>
            );
        }
    }

    renderViewMore(){
        const {loading, results, resultsSearchLimit} = this.state;

        if (results.length >= resultsSearchLimit) {
            if (loading) {
                return (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "10vh" }}>
                        <ClipLoader
                            size={20}
                            color={"#fff"}
                        />
                    </div>
                );
            } else {
                return (
                    <div className={"allLeaveRequestsButtonBox"}>
                        <Button
                            text={"View More"}
                            onPress={() => this.onSearch(true)}
                        />
                    </div>
                );
            }
        }
    }

    renderResults() {
        const { results, profile, seekerHighlights } = this.state;
        console.log(results)
        if(results.length !== 0) {
            return (
                <div>
                    <ResultCard results={results} profile={profile} seekerHighlights={seekerHighlights}/>
                </div>
            );
        } else {
                return <h5 className={'adminAlert'}>No search results to display</h5>;
        }
    }

    renderSearchResults() {
        const { results, profile } = this.state;

    }

    handleSearch() {
        this.setState({search: !this.state.search});
    };

    onMoreFilters() {
        this.setState({moreFilters: !this.state.moreFilters});
    }

    moreFilter() {
        return (
            <div className={"allLeaveRequestsButtonBox"}>
                {
                    this.state.moreFilters ?
                    <Button
                        text={"Hide Filters"}
                        onPress={() => this.onMoreFilters()}
                    /> :
                    <Button
                        text={"More Filters"}
                        onPress={() => this.onMoreFilters()}
                    />
                }
            </div>
        );
    }


    render() {
        return (
            <div className={"searchContainer"}>
                <div className={"topBarBox"}>
                    <div className={"childHeaderBarBox"}>
                        <SearchVisualIcon size={30} style={{ color: "#000", marginRight: "0.3rem"}} />
                        <h3 style={{ marginTop: '1.4rem', }}>Search Here</h3>
                    </div>
                </div>
                <div  className="searchBar">
                    <Input.Search
                        placeholder="Click here to search"
                        onClick={this.handleSearch}
                        readOnly
                        style={{
                            width: '80vw', paddingTop: '2px', height: '40px'
                        }}
                    />
                </div>
                {this.state.search && (
                <div className="searchBox">
                    <div className="infoSection">
                        <CountryFilter/>
                        <GenderFilter/>
                        <JobTypeFilter/>
                        <WorkScheduleFilter/>

                    </div>
                    <div className="infoSection">
                        <RelocationFilter/>
                        <MaritalStatus/>
                        <BirthdateFilter/>
                        <JobTitleFilter/>
                        <LanguagesFilter/>
                        <LinksFilter/>
                    </div>

                    {this.moreFilter()}
                    {this.state.moreFilters && (
                        <div>
                            <div className="infoSection">
                                <EducationLevelFilter/>
                                <EducationIndustryFilter/>
                                <CurrentEducationEnrollment/>
                                <YearOfCompletion/>
                                <SkillsFilter/>
                                <LicensesFilter/>
                                <CertificatesFilter/>
                            </div>
                            <div className="infoSection">
                                <PositionExperiencePrime/>
                                <PositionExperienceSec/>
                                <ExperienceFilter/>
                                <InternationalExperience/>
                                <StudiedAbroad/>
                                <ExperienceIndustryPrime/>
                                <ExperienceIndustrySec/>
                            </div>
                        </div>
                    )}
                    {this.renderSearchButton()}
                </div>
                )}

                {/* {!this.state.search && (
                    <div className="recentsSearches">
                        <RecentSearches/>
                    </div>
                )} */}

                {/* <div className={"SearchResults"}>
                    <div><h5>Rank</h5></div>
                    <div><h5>Name</h5></div>
                    <div><h5>Gender</h5></div>
                    <div><h5>Age</h5></div>
                    <div><h5>Location</h5></div>
                    <div><h5>Experience</h5></div>
                    <div><h5>Industries</h5></div>
                    <div><h5>Shortlist</h5></div>
                    <div><h5>Review</h5></div>
                </div> */}

                <div className="searchResultContainer">
                    { this.renderResults() }
                </div>

                <div>
                    { this.renderViewMore() }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { packageIndex, openForRelocation, country, region,
        jobTypeArray, workScheduleArray, gender, maritalStatus, startBirthDate,
            endBirthDate,
        certificateID, jobTitle, educationLevel, educationIndustry,
        currentEnrollment, studiedAbroad, yearOfCompletion, industryID,
        currentAtWork, experienceID, experienceYears, foreignJob, language, license, militaryCountry,
        industryPrime,
        industrySec,
        positionPrime,
        positionSec,
        skill, link, results,
        loading, resultsSearchLimit, seekerHighlights } = state.search;
    const { profile } = state.profile;
    
    return{
        packageIndex, openForRelocation, country, region,
        jobTypeArray, workScheduleArray, gender,
        maritalStatus, startBirthDate,
        endBirthDate, certificateID, jobTitle, educationLevel,
        educationIndustry,
        currentEnrollment, studiedAbroad, yearOfCompletion, industryID,
        currentAtWork, experienceID, experienceYears, foreignJob, language, license, militaryCountry,
        industryPrime,
        industrySec,
        positionPrime,
        positionSec,
        skill, link, results,
        loading,
        resultsSearchLimit, seekerHighlights,
        profile,
    }
};

export default connect(mapStateToProps, {searchDB, clearSearch, fetchAdminHighlights})(Search);