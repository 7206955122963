import React from "react";

//-> component imports
import ChoosePackage from "./subComponents/ChoosePackage";

//component imports


class Subscriptions extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }


    render() {
        return(
            <div className={'settingsSubscriptionBox'}>
                <div className={'chooseSubscriptionBox'}>
                    <div>
                        <ChoosePackage/>
                    </div>
                </div>

                <div>
                    {/*<SubscriptionPayments/>*/}
                </div>
            </div>
        );
    }
}

export default Subscriptions;