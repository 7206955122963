import React from 'react';
import {connect} from 'react-redux';
import _ from "lodash";

//actions import

//common imports
import {Spinner} from "../../common/Spinner";

//components import
import PaymentCard from "./cards/PaymentCard";

class Payments extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            payments: this.props.payments,
            paymentsLoading: this.props.paymentsLoading,
            profile: this.props.profile,
        }

        this.renderPayments = this.renderPayments.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props) {
            this.setState({
                payments: nextProps.payments,
                paymentsLoading: nextProps.paymentsLoading,
                profile: nextProps.profile,
            })
        }
    }

    renderPayments() {
        const {payments, paymentsLoading} = this.state;

        if (paymentsLoading) {
            return <Spinner/>
        } else {
            return (
                <div>
                    <PaymentCard payments={payments}/>
                </div>
            );
        }
    }


    checkUser() {
        //extract state
        const { profile } = this.state;

        if(!(_.isEmpty(profile))) {
            if((profile.role === "admin") || profile.role === "superAdmin") {
                return (
                    <div>
                        <div className={"settingsAssetsBox"}>
							<div className={"addAssetsSettingsBox"}>
								<h4 style={{ fontFamily: "ramblaBold", color: "black" }}>Payments</h4>
							</div>
						</div>
						<br/>
                        <div className={"infoBox"}>
                            <div className={"assetsDetailsBox"}>
                                <div>
                                    {this.renderPayments()}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        } else {
            return <h2 style={{ color: "gray" }}>Sign in to view</h2>;
        }
    }

    render() {
        return (
            <div className={'employeeToolContainer'}>
                { this.checkUser() }
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    const { payments, paymentsLoading } = state.subscription;
    const { profile } = state.profile;

    return{
        payments, paymentsLoading,
        profile
    }
}

export default connect(mapStateToProps, {})(Payments);