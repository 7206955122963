import React from 'react';
import { DatePicker } from 'antd';


export class PickDate extends React.Component {

    render() {

        return(
            <div className={"inputTypePickDate"}>
                <div>
                    <p style={{ color: "#000", fontFamily: "ramblaBold", textAlign: "left"}}>
                        { this.props.label }
                    </p>
                </div>
                <div>
                    <DatePicker
                        showTime={{ format: 'HH:mm:ss' }}
                        format="DD-MM-YYYY | HH:mm:ss"
                        onChange={this.props.onChange}
                        value={this.props.value}
                    />
                </div>
            </div>
        );
    }
}