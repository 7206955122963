// import React, {useMemo, useState} from "react";
// import moment from "moment";
// import { connect } from "react-redux";
// import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// import {Button, message, Modal} from "antd";
// import { SecurityScanFilled } from "@ant-design/icons";
// import {useNavigate} from "react-router-dom";


// //-> action imports
// import {deleteFromShortlist} from "../../../actions/SearchActions";
// import { pushSeeker } from "../../../actions/PostsActions";

// //common imports
// import {Spinner} from "../../common/Spinner";

// //-> component import
// import TruncatedText from "../../search/modals/TruncatedText";
// import TruncatedExperience from "../../search/modals/TruncatedExperience";
// import { CheckmarkBadge03Icon, PropertyDeleteIcon } from "hugeicons-react";

// const ShortlistCard = ({ seeker, shortlistLoading, deleteFromShortlist, pushSeeker, profile,isToggled }) => {

    
//     const [visible, setVisible] = useState(false);
//     const [showLoading, setShowLoading] = useState(false);
//     const navigate = useNavigate();

//     console.log('is toggled 2', isToggled)

//     const showModal = () => {
//         setVisible(true);
//     };

//     const handleOk = () => {
//         setVisible(false);
//     };

//     const handleCancel = () => {
//         setVisible(false);
//     };

//     const renderSeeker = useMemo(() => {
//         //extract state
//         const { birthdate, firstName, lastName, gender, location, education, packageIndex } = seeker;
//         let seconds;
//         birthdate.seconds ? seconds = birthdate.seconds : seconds = birthdate._seconds;
//         const birthday = moment.unix(seconds);
//         const today = moment();
//         const age = today.diff(birthday, "years");

//         const onSelectCandidate = (seeker) => {
//             if ("paid" in profile) {
//                 if (profile.paid) {
//                     setShowLoading(true);
//                     pushSeeker({ pushedSeeker: seeker });
//                     navigate("/seeker-profile");
//                 } else {
//                     message.info("Upgrade your package to view candidate").then(() => null);
//                 }
//             } else {
//                 message.info("Upgrade your package to view candidate").then(() => null);
//             }
//         }

//         //compute the number of days
//         //const birthdayString = birthday.format('DD MMM YYYY');

//         let color = "gray"; //not payed
//         switch (packageIndex) {
//             case 1:
//                 color = "gold";
//                 break;
//             case 2:
//                 color = "#0793d9"; //mid package
//                 break;
//             default:
//                 break;
//         }

//         let indSentence = "";
//         if (education.length !== 0) {
//             const edIndustry = education.map(ind => `${ind},`);
//             indSentence = edIndustry.slice(0, -1).join(", ") + " " + edIndustry[edIndustry.length - 1];
//         }

//         return (
//             <div className={"manageLeaveRequestsValuesBox"}>
//                 <div>
//                     <p><CheckmarkBadge03Icon color={color} size={20}/></p>
//                 </div>
//                 <div><p style={{ fontFamily: "ramblaBold", color }}>{`${firstName} ${lastName}`}</p></div>
//                 <div><p style={{ color }}>{gender}</p></div>
//                 <div><p><span style={{ color }}>{age}</span></p></div>
//                 <div><p style={{ color }}>{`${location.country}, ${location.region}`}</p></div>

//                 <div className={"noteView"}>
//                     <TruncatedExperience seeker={seeker} maxLength={45} />
//                 </div>

//                 <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
//                     <TruncatedText text={indSentence} maxLength={25}/>
//                 </div>

//                 <div className={"dayOffRequestBox"}>
//                     <div>
//                         {
//                             showLoading ?
//                                 <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "1rem" }}>
//                                     <Spinner/>
//                                 </div>
//                                 : <p onClick={ () => onSelectCandidate(seeker) } style={{cursor: 'pointer', color: "#0793d9"}}>Go to Profile</p>
//                         }
//                     </div>
//                 </div>

//                 <div className={"noteView"}>
//                     <div>
//                         <p><PropertyDeleteIcon size={20} color="red"  onClick={() => showModal()} style={{cursor: 'pointer'}} /></p>
//                     </div>

//                     <Modal
//                         title={"Remove this candidate from your shortlist?"}
//                         open={visible}
//                         onOk={handleOk}
//                         onCancel={handleCancel}

//                         footer={null}
//                         width={900}
//                     >
//                         <div className={"ticketModalDetails"}>
//                             {
//                                 shortlistLoading ?
//                                     <Spinner/>
//                                     : <Button onClick={() => {
//                                         deleteFromShortlist({ seeker });
//                                         handleOk();
//                                     }}>Remove {firstName}</Button>
//                             }
//                         </div>
//                     </Modal>
//                 </div>
//             </div>
//         )
//     }, [seeker, visible, shortlistLoading, deleteFromShortlist, navigate, showLoading, pushSeeker]);

//     return(
//         <div>
//             {renderSeeker}
//         </div>
//     );
// }

// const mapStateToProps = ( state ) => {
//     const { shortlistLoading } = state.search;
//     const { isToggled } = state.home;

//     return {
//         shortlistLoading,
//         isToggled

//     }
// };

// export default connect(mapStateToProps,{ deleteFromShortlist, pushSeeker } )(ShortlistCard);

import React, {useEffect, useState} from 'react';
import { Table, Button, Modal, message } from 'antd';
import { connect, useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SecurityScanFilled } from '@ant-design/icons';
import { Spinner } from '../../common/Spinner';
import TruncatedText from '../../search/modals/TruncatedText';
import TruncatedExperience from '../../search/modals/TruncatedExperience';
import {useNavigate} from "react-router-dom";
import moment from 'moment';
import { CheckmarkBadge03Icon, PropertyDeleteIcon } from "hugeicons-react";

import { deleteFromShortlist, requestCoverLetter } from "../../../actions/SearchActions";
import { postsFieldChanged, pushSeeker } from "../../../actions/PostsActions";
import CustomHeaderCard from '../../search/cards/CustomHeaderCard';
import SelectPost from '../subComponents/SelectPost';


const ShortlistCard = ({ shortlist, shortlistLoading, deleteFromShortlist, pushSeeker, profile, isToggled }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedSeeker, setSelectedSeeker] = useState(null); 
    const [isCoverLetterModalVisible, setIsCoverLetterModalVisible] = useState(false);


    
    const [state, setState] = useState({
        post: "",
        profile:{}
        
    });

   //extract app state
   const postState = useSelector((state) => state.posts);
   const profileState = useSelector((state) => state.profile);

   console.log('Post', state.post)


   useEffect(() => {
       try {
           // Set initial state when the component mounts
           // Update state whenever dataFromRedux changes
           setState(prevState => ({
               ...prevState,
               post: postState.post,
               profile:profileState.profile
              
           }));

       } catch (e) {
           console.error("error in updating make payment");
       }
   }, [postState, profileState]);


//    console.log('Profile', state.profile)
const onSelectPost =(value)=> {
    dispatch(postsFieldChanged({prop: 'post', value }))
}


    const handleDelete = (seeker) => {
        deleteFromShortlist({ seeker });
        message.success('Added to shortlist');
        setIsModalVisible(false); // Close the modal after shortlisting
    };

    const onSelectCandidate = (seeker) => {
        if ("paid" in profile) {
            if (profile.paid) {
                pushSeeker({ pushedSeeker: seeker });
                navigate("/seeker-profile");
            } else {
                message.info("Upgrade your package to view candidate").then(() => null);
            }
        } else {
            message.info("Upgrade your package to view candidate").then(() => null);
        }
    };


    const handleShortlist = (seeker, shortlistInfo) => {

        
        dispatch(requestCoverLetter( seeker, shortlistInfo ))
        message.info('Sending Request ..');
        setIsCoverLetterModalVisible(false); // Close the modal after shortlisting
    };

    
    const onRequestCoverLetter = (record) => {
        setSelectedSeeker(record);
         
        const shortlistInfo = {}
        shortlistInfo.companyName = state.profile.companyName;
        shortlistInfo.adminName = state.profile.firstName;
        shortlistInfo.post = state.post.label;
        shortlistInfo.isCoverLetterSent = false;
        shortlistInfo.adminID = state.profile.userID;

        console.log('the post', shortlistInfo)
        
        handleShortlist(selectedSeeker, shortlistInfo)
       
    };

    const showModal = (record) => {
        setSelectedSeeker(record);
        setIsModalVisible(true);
    };

    const showCoverLetterModal = (record) => {
        setSelectedSeeker(record);
        setIsCoverLetterModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleAccept = () => {
        setIsCoverLetterModalVisible(false);
    };

    const handleClose = () => {
        setIsCoverLetterModalVisible(false);
    };

    let processedResults = shortlist.map(result => {
        let seconds;
        result.birthdate.seconds? (seconds = result.birthdate.seconds) : (seconds = result.birthdate._seconds);
        
        const birthday = moment.unix(seconds);
        const today = moment();
        const age = today.diff(birthday, "years");
        
        const name = (result.firstName + " " + result.lastName).toUpperCase();

        
        const formattedLocation = `${result.location.country}, ${result.location.region}`;

        let color = "#000"; //not payed
        switch (result.packageIndex) {
            case 1:
                color = "#228b22";
                break;
            case 2:
                color = "#0793d9"; //mid package
                break;
            default:
                break;
        }
        
        let indSentence = "";
        if (result.education.length!== 0) {
            const edIndustry = result.education.map((ind) => `${ind},`);
            indSentence = edIndustry.slice(0, -1).join(", ") + " " + edIndustry[edIndustry.length - 1];
        }

        
        const coverLetterRequested = result.coverLetterRequested !== undefined;
        
        return { ...result, indSentence, formattedLocation, name, age, color, coverLetterRequested };
    });

    const columns = [
        {
            title: <CustomHeaderCard>Rank</CustomHeaderCard>,
            dataIndex: 'rank',
            key: 'rank',
            render: (_, record) => (
                <div style={{ fontSize: '1rem', color: record.color }}>
                    <CheckmarkBadge03Icon color={record.color} size={20}/>
                    {/* <SecurityScanFilled style={{ fontSize: '1rem', color: record.color }} /> */}
                </div>
            ),
        },
        {
            title: <CustomHeaderCard>Name</CustomHeaderCard>,
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => (
                <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: record.color }}>{record.name}</div>
            ),
        },
        {
            title: <CustomHeaderCard>Gender</CustomHeaderCard>,
            dataIndex: 'gender',
            key: 'gender',
            render: (_, record) => (
                <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: record.color }}>{record.gender}</div>
            ),
        },
        {
            title: <CustomHeaderCard>Age</CustomHeaderCard>,
            dataIndex: 'age',
            key: 'age',
            render: (_, record) => (
                <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: record.color }}>{record.age}</div>
            ),
        },
        {
            title: <CustomHeaderCard>Location</CustomHeaderCard>,
            dataIndex: 'formattedLocation',
            key: 'formattedLocation',
            render: (_, record) => (
                <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: record.color }}>{record.formattedLocation}</div>
            ),
        },
        {
            title: <CustomHeaderCard>Experience</CustomHeaderCard>,
            dataIndex: 'experience',
            key: 'experience',
            render: (_, record) => (
                <div style={{
                    fontSize: '0.9rem',
                    height: '23px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                }}>
                    <TruncatedExperience seeker={record} maxLength={60}/>
                </div>
            ),
        },
        {
            title: <CustomHeaderCard>Industries</CustomHeaderCard>,
            dataIndex: 'indSentence',
            key: 'indSentence',
            render: (_, record) => (
                <div style={{
                    fontSize: '0.9rem',
                    height: '23px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                }}>
                    <TruncatedText text={`${record.indSentence}`} maxLength={25}/>
                </div>
            ),
        },
        {
            title: <CustomHeaderCard>Review</CustomHeaderCard>,
            dataIndex: 'review',
            key: 'review',
            render: (_, record) => (
                <FontAwesomeIcon icon="user" color="#3294ff" size="1x" onClick={() => onSelectCandidate(record)} style={{ cursor: "pointer" }} />
            ),
        },
        {
            title: <CustomHeaderCard>Remove</CustomHeaderCard>,
            dataIndex: 'remove',
            key: 'remove',
            render: (_, record) => (
                <PropertyDeleteIcon color="red" size={20}  onClick={() => showModal(record)} style={{cursor: 'pointer'}} />
            ),
        },

        {
            title: <CustomHeaderCard>Request Cover Letter</CustomHeaderCard>,
            dataIndex: 'coverLetterRequest',
            key: 'coverLetterRequest',
            width: 180,
            render: (_, record) => (
                !record.coverLetterRequested && (
                    <Button 
                        type="primary" 
                        onClick={() => showCoverLetterModal(record)}
                    >
                        Request
                    </Button>
                )
            ),
        },

        

        
    ];

    return (
        <>
            <Table dataSource={processedResults} columns={columns} rowKey="id"  scroll={{ y: 540,}} />
     
           

            <Modal
                title={"Remove this candidate from your shortlist?"}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
            >
                <div className={"ticketModalDetails"}>
                    {shortlistLoading? <Spinner /> : 
                        selectedSeeker && <Button type="primary" onClick={() => handleDelete(selectedSeeker)}>
                            Remove {selectedSeeker.firstName}
                        </Button>
                    }
                </div>
            </Modal>
            {selectedSeeker && (
            <Modal
                title={` Let ${selectedSeeker.firstName} know you're requesting a cover letter for which job post`}
                visible={isCoverLetterModalVisible}
                onOk={handleAccept}
                onCancel={handleClose}
                footer={null}
            >
                 <div className={"ticketModalDetails"}>
                        <SelectPost
                            placeholder={'Select post to interview for'}
                            label={"Pick Post"}
                            value={state.post}
                            onChange={onSelectPost}
                        />
                    </div>
    

                <div className={"ticketModalDetails"}>
                    {shortlistLoading? <Spinner /> : 
                        state.post && <Button type="primary" onClick={() => onRequestCoverLetter(selectedSeeker)} disabled={!state.post}>
                            Request
                        </Button>
                    }
                </div>
            </Modal>
             )}
        </>
    );
};

export default connect(null, { deleteFromShortlist, pushSeeker })(ShortlistCard);
