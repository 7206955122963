import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment";
import arraySort from "array-sort";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//-> action imports

//-> component imports
import NotificationCard from "../cards/NotificationCard";

class ViewNotifications extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            notifications: this.props.notifications,
        };

        this.renderNotifications = this.renderNotifications.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.notifications !== this.props.notifications) {
            this.setState({notifications: nextProps.notifications});
        }
    }

    renderNotifications() {
        //extract state
        const { notifications } = this.state;

        //check that notifications is not empty
        if (!(_.isEmpty(notifications))) {
            //sort notifications by time
            const notificationsArray = _.map(notifications, notification => notification);
            const sortedNotifications = arraySort(notificationsArray, "notificationTime", {reverse: true});

            return(
                <div className={'viewNotificationCard'}>
                    {
                        sortedNotifications.map(notification => {
                            return (
                                <div key={notification.notificationID}><NotificationCard notification={notification}/></div>
                            );
                        })
                    }
                </div>
            );
        } else {
            return <h3 className={"adminAlert"}>There are currently no notifications</h3>;
        }
    }

    render() {
        return(
            <div>
                { this.renderNotifications() }
            </div>
        );
    }

}

const mapStateToProps = state => {

    const { notifications } = state.notifications;

    return {
        notifications
    }
};

export default connect(mapStateToProps, { })(ViewNotifications);