import React, { Component } from 'react'

class Privacy extends Component {
  render() {
    return (
      <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0 }}>
        <iframe 
          src="https://firebasestorage.googleapis.com/v0/b/nafasiio.appspot.com/o/termsAndConditions%2FDZY2ING?alt=media&token=6f7f23c9-c9c4-401e-8716-298e9105c84b" 
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            border: 'none',
            overflow: 'hidden'
          }}
        />
      </div>
    );
  }
}

export default Privacy;

