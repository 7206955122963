import React from 'react';
import {connect} from 'react-redux';
import _ from "lodash";

//actions import

//components import
import CertificatesCard from "./cards/CertificatesCard";
import {Spinner} from "../../common/Spinner";

class Certificates extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedCandidate: this.props.selectedCandidate,
            certificates: this.props.certificates,
            certificatesLoading: this.props.certificatesLoading,
            profile: this.props.profile,
        }

        this.renderCertificates = this.renderCertificates.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props) {
            this.setState({
                selectedCandidate: nextProps.selectedCandidate,
                certificates: nextProps.certificates,
                certificatesLoading: nextProps.certificatesLoading,
                profile: nextProps.profile,
            })
        }
    }

    renderCertificates() {
        const {certificates, certificatesLoading, selectedCandidate} = this.state;

        if (certificatesLoading) {
            return <Spinner/>
        } else {
            if (!(_.isEmpty(selectedCandidate))) {
                return (
                    <div>
                        <CertificatesCard certificates={certificates}/>
                    </div>
                );
            } else {
                return <h2 style={{ color: "#000", margin: "2rem 0"  }}>Select a candidate to view</h2>;
            }
        }
    }


    checkUser() {
        //extract state
        const { profile } = this.state;

        if(!(_.isEmpty(profile))) {
            if((profile.role === "admin") || profile.role === "superAdmin") {
                return (
                    <div>
                        <div className={"settingsAssetsBox"}>
                            <div className={"addAssetsSettingsBox"}>
                                <h4 style={{ fontFamily: "ramblaBold", color: "black" }}>Certificates</h4>
                            </div>
                        </div>
                        <br/>
                        <div className={"infoBox"}>
                            <div className={"assetsDetailsBox"}>
                                <div>
                                    {this.renderCertificates()}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        } else {
            return <h2 style={{ color: "#000" }}>Sign in to view</h2>;
        }
    }

    render() {
        return (
            <div className={'employeeToolContainer'}>
                { this.checkUser() }
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    const { certificates, certificatesLoading, selectedCandidate } = state.posts;
    const { profile } = state.profile;

    return{
        selectedCandidate,
        certificates, certificatesLoading,
        profile
    }
}

export default connect(mapStateToProps, {})(Certificates);