import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { connect } from "react-redux";
import _ from "lodash";

//action imports

//components import
import PDFViewer from "../../common/PDFViewer";
import {Spinner} from "../../common/Spinner";


class SeekerDocuments extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedCandidate: this.props.selectedCandidate,
            selectedCandidateProfile: this.props.selectedCandidateProfile,
            selectedCandidateProfileLoading: this.props.selectedCandidateProfileLoading,
            profile: this.props.profile,
            pushedSeeker: this.props.pushedSeeker,
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props) {
            this.setState({
                selectedCandidate: nextProps.selectedCandidate,
                selectedCandidateProfile: nextProps.selectedCandidateProfile,
                selectedCandidateProfileLoading: nextProps.selectedCandidateProfileLoading,
                profile: nextProps.profile,
                pushedSeeker: nextProps.pushedSeeker,
            });
        }
    }

    checkUser() {
        //extract state
        const { profile, selectedCandidate, selectedCandidateProfile, selectedCandidateProfileLoading,pushedSeeker } = this.state;

        if (!(_.isEmpty(profile))) {
            if (!(_.isEmpty(selectedCandidate))) {
                if (profile.role === "admin" || profile.role === "superAdmin") {
                    if (selectedCandidateProfileLoading) {
                        return <Spinner/>
                    } else {
                        return (
                            <div className={"filesContainer"}>
                                <div className={"topBarBox"}>
                                    <div className={"childHeaderBarBox"}>
                                        <FontAwesomeIcon icon="file" style={{fontSize: '1rem'}} />
                                        <h2 style={{color:"black"}}>Files & Documents</h2>
                                    </div>
                                </div>
                                {selectedCandidateProfile.cv && (
                                <div style={{ height: "120vh"}}>
                                <p style={{color:"black"}}>Curriculum Vitae</p>
                                    <PDFViewer pdfUrl={selectedCandidateProfile.cv} />
                                </div>
                                 )}

                                <div>
                                    {pushedSeeker.coverLetterURL !== undefined && (
                                        <div style={{ height: "120vh", marginTop: "80px" }}>
                                            <p style={{ color: "black" }}>Cover Letter</p>
                                            <PDFViewer pdfUrl={pushedSeeker.coverLetterURL} />
                                        </div>
                                    )}
                                </div>

                            </div>
                        );
                    }
                } else {
                    return <h2 style={{ color: "#fff" }}>Un-Authorized Access</h2>;
                }
            } else {
                return <h2 style={{ color: "#fff" }}>Select a candidate to view</h2>;
            }
        }
    }

    render() {
        return (
            <div className={'employeeToolContainer'}>
                { this.checkUser() }
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { selectedCandidateProfile, selectedCandidateProfileLoading, selectedCandidate,pushedSeeker} = state.posts;
    const { profile } = state.profile;

    return {
        selectedCandidate,
        selectedCandidateProfile,
        selectedCandidateProfileLoading,
        profile,
        pushedSeeker
    }
}

export default connect(mapStateToProps, { })(SeekerDocuments);