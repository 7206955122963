import React from 'react';
import { connect } from 'react-redux';
import { Collapse } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Link, NavLink} from 'react-router-dom';
import {isMobile} from "react-device-detect";
import Logo from "../../assets/logos/logo.png";

class Disclosure extends React.Component {
    constructor(props){
        super(props);

        this.state = {

        }
    }

    componentWillReceiveProps(nextProps, nextContext){

    }

    renderLogo() {
        if(isMobile){
            return  <NavLink to={'/'}><img src={Logo} alt={"nafasi icon logo"}/></NavLink>;
        }
    }

    render(){
        const { Panel } = Collapse;

        return (
            <div className={'faqContainer'}>
                <div className={'faqIntroContainer'}>

                    <div className={'landingPageLogo'}>
                        {this.renderLogo()}
                    </div>
                    <div className={'faqIntroBox'}>
                        <h4>Google User Data Policy Compliance</h4>
                    </div>
                    <div className={'faqMoreContact'}>
                        <h5 style={{ color: "#fff"}}>Our site is committed to complying with the Google API Services User Data Policy, which includes adherence to Limited Use requirements. This ensures that your data is handled responsibly and in accordance with Google's guidelines.
                        To learn more about this policy and how it impacts your data, please review the full  <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" target="_blank" color='blue'> Google API Services User Data Policy</a> </h5>
                    </div>
                </div>
            </div>
        )
    }
};

const mapStateToProps = state => {

    return {

    }
};

export default connect(mapStateToProps, { })(Disclosure);