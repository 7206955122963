import React from "react";
import { Table } from 'antd';
class InterviewsCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            general: this.props.general,
            interview: this.props.interview,
            visible: false
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props) {
            this.setState({
                general: nextProps.general,
                interview: nextProps.interview,
            });
        }
    }


    renderInfoBox() {
        const { interview } = this.state;
        const { infoBox } = interview;

        if (infoBox.general) {
            return(
                <div style={{ padding: "0.5rem 0 0 0" }}>
                    <h5 style={{ fontSize: "0.9rem", color: '#000' }}>Average Interview Assessment System Ratings</h5>
                </div>
            );
        } else {
            return(
                <div style={{ padding: "0.5rem 0 0 0" }}>
                    <h5 style={{ fontSize: "0.9rem", color: '#000' }}>{infoBox.postTitle} <span style={{ color: "#6092DD"}}>@{infoBox.companyName}</span></h5>
                </div>
            );
        }
    }

    renderInterview() {
        const { interview } = this.state;

        const interviewObjectKeys = ['adaptability', 'analyticalThinking', 'creativity', 'decisionMaking', 'emotionalIntelligence', 'initiative', 'leadership', 'organizationalSkills', 'professionalism', 'teamwork', 'timeManagement', 'verbalCommunication', 'workEthic', 'writtenCommunication'];
        let interviewArray = interviewObjectKeys.map(key => {
            return {
                adaptability: interview['adaptability'], analyticalThinking: interview['analyticalThinking'], creativity: interview['creativity'], decisionMaking: interview['decisionMaking'], emotionalIntelligence: interview['emotionalIntelligence'],
                initiative: interview['initiative'], leadership: interview['leadership'], organizationalSkills: interview['organizationalSkills'], professionalism: interview['professionalism'], teamwork: interview['teamwork'], timeManagement: interview['timeManagement'],
                verbalCommunication: interview['verbalCommunication'], workEthic: interview['workEthic'], writtenCommunication: interview['writtenCommunication']
            }
        });

        interviewArray = interviewArray.splice(0,1);

        const columns = [
            {
                title: 'Adaptability',
                dataIndex: 'adaptability',
                key: 'adaptability',
            },
            {
                title: 'Analytical Thinking',
                dataIndex: 'analyticalThinking',
                key: 'analyticalThinking',
            },
            {
                title: 'Creativity',
                dataIndex: 'creativity',
                key: 'creativity',
            },
            {
                title: 'Decision Making',
                dataIndex: 'decisionMaking',
                key: 'decisionMaking',
            },
            {
                title: 'Emotional Intelligence',
                dataIndex: 'emotionalIntelligence',
                key: 'emotionalIntelligence',
            },
            {
                title: 'Initiative',
                dataIndex: 'initiative',
                key: 'initiative',
            },
            {
                title: 'Leadership',
                dataIndex: 'leadership',
                key: 'leadership',
            },
            {
                title: 'Organization',
                dataIndex: 'organizationalSkills',
                key: 'organizationalSkills',
            },
            {
                title: 'Professionalism',
                dataIndex: 'professionalism',
                key: 'professionalism',
            },
            {
                title: 'Teamwork',
                dataIndex: 'teamwork',
                key: 'teamwork',
            },
            {
                title: 'Time Management',
                dataIndex: 'timeManagement',
                key: 'timeManagement',
            },
            {
                title: 'Verbal Communication',
                dataIndex: 'verbalCommunication',
                key: 'verbalCommunication',
            },
            {
                title: 'Written Communication',
                dataIndex: 'writtenCommunication',
                key: 'writtenCommunication',
            },
            {
                title: 'Work Ethic',
                dataIndex: 'workEthic',
                key: 'workEthic',
            }
        ];

        return (
            <div className="interviewsValuesBox">
                {/* <div style={{textAlign: "center",fontSize: "0.9rem",marginBottom: "1rem"}}>
                    {interview.infoBox.general ? 'General Interview Assessment System Ratings' : `${interview.infoBox.postTitle} @${interview.infoBox.companyName}`}
                </div> */}
                <div style={{textAlign: "center", fontSize: "0.9rem", marginBottom: "1rem"}}>
                    <p style={{color: '#000'}}>
                        {interview.infoBox.general 
                        ? 'Average Interview Assessment System Ratings'
                        :   <>
                                <span>{interview.infoBox.postTitle}</span>
                                <span style={{color: "#6092DD", fontWeight: 'bold'}}>@{interview.infoBox.companyName}</span>
                            </>}
                    </p>
                </div>

                <div className={"iasRatingsBox"}>
                    <Table columns={columns} dataSource={interviewArray} pagination={false}/>
                </div>
            </div>
        );
    }

    render() {
        return <div>{this.renderInterview()}</div>;
    }
}

export default InterviewsCard;